import React from 'react';
import { MenuLateral } from '../MenuLateral/MenuLateral'
import { useNavigate } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import './GasesToxicos.css';

export function GasesToxicos() {

    const navigate = useNavigate();

    const irASistemasCentralizados = () => {
        navigate(`/sistemasCentralizados`);
    };

    const irADetectores4_20ma = () => {
        navigate(`/detectores4_20ma`);
    };

    const irADetectoresAutonomos = () => {
        navigate(`/detectoresAutonomos`);
    };

    return (
        <>
            <div className="mainContainer">
                <MenuLateral />
                <div className='contenedor'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='tituloGasesToxicos'>
                                <h1>Gases tóxicos</h1>
                            </div>

                        </div>

                    </div>

                    <div className='row'>
                        <div className='contenedorCardsToxicos'>
                            <div className='row'>
                                <div className='col-xl-4 col-lg-4 col-md-12'>
                                    <Card>
                                        <Card.Img variant="centralizados" src={require('../Img/sistemasCentralizados.webp')} onClick={irASistemasCentralizados} style={{ cursor: 'pointer' }} alt='Sistemas centralizados' fetchpriority='high'/>
                                        <Card.Body>
                                            <Card.Title className='tituloCentralizados'>Sistemas centralizados</Card.Title>

                                        </Card.Body>

                                    </Card>
                                </div>
                                <div className='col-xl-4 col-lg-4 col-md-12'>
                                    <Card>
                                        <Card.Img variant="4_20" src={require('../Img/4_20ma.webp')} onClick={irADetectores4_20ma} style={{ cursor: 'pointer' }} alt='Detectores 4_20mA' fetchpriority='high'/>
                                        <Card.Body>
                                            <Card.Title className='titulo4_20'>Detectores 4_20mA</Card.Title>

                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className='col-xl-4 col-lg-4 col-md-12'>
                                    <Card>
                                        <Card.Img variant="autonomos" src={require('../Img/autonomos.webp')} onClick={irADetectoresAutonomos} style={{ cursor: 'pointer' }} alt='Detectores autónomos' fetchpriority='high'/>
                                        <Card.Body>
                                            <Card.Title className='tituloAutonomos'>Detectores autónomos</Card.Title>

                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

