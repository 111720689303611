import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Row from 'react-bootstrap/Row'; import { MenuLateral } from '../MenuLateral/MenuLateral'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'; // Importa el icono faBars
import Nav from 'react-bootstrap/Nav';
import { fichaCI825tn, fichaCI225tn, certificadoCI } from '../../Archivos/columnas';
import './Columnas.css';

export function Columnas() {

    const [fotoSeleccionada, setFotoSeleccionada] = useState(null);
    const [subtituloModal, setSubtituloModal] = useState("");

    const modalFoto = (foto, subtitulo) => {
        setFotoSeleccionada(foto);
        setSubtituloModal(subtitulo)
    };

    const logoPdf = <FontAwesomeIcon icon={faFilePdf} size="xl" style={{ color: "#006c87", }} />

    const abrirPdf = (archivo) => {
        window.open(archivo, '_blank');
    };

    const archivosPDF = {
        ficha825: fichaCI825tn,
        ficha225: fichaCI225tn,
        certificado: certificadoCI
    };

    const clicEnlacePdf = (tipoArchivo) => {
        abrirPdf(archivosPDF[tipoArchivo]);
    };

    return (
        <>
            <div className="mainContainer">
                <MenuLateral />

                <div className='contenedorProducto'>
                    <div className='row'>
                        <div className='col-xl-3 col-lg-3 col-md-12 col-sm-12'>
                            <div className='imagenesColumnas'>

                                <Row>
                                    <div className='contenedorFoto' >
                                        <img className="imagenProductoColumnas"
                                            src={require("../Img/vcc64.webp")}
                                            
                                            onClick={() => modalFoto(require("../Img/vcc64.webp"), 'VCC-64')}
                                            alt='VCC-64' />
                                    </div>
                                </Row>
                                <Row>
                                    <div className='contenedorFoto' >
                                        <img className="imagenProductoColumnas"
                                            src={require("../Img/vcc64PSK.webp")}
                                            
                                            onClick={() => modalFoto(require("../Img/vcc64PSK.webp"), 'VCC-64PSK')}
                                            alt='VCC-64PSK' />
                                    </div>
                                </Row>

                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                            <div className='descripcionColumnas'>
                                <h2 className='nombreProductoColumnas'>Columnas acústicas</h2>
                                <h5 className='tituloProductoColumnas'>Columna CI-825TN

                                </h5>
                                <p className='textoProductoColumnas'>
                                    El LDA CI-825TN es una columna acústica de 40W certificada EN 54-24. Ofrece una amplia respuesta en
                                    frecuencia, baja distorsión y una alta presión sonora adecuada para emitir mensajes de evacuación
                                    de forma inteligible así como música.
                                    <br /><br />
                                    Es válida tanto para interior como para exterior gracias a su protección IP 66, sirviendo para todo
                                    tipo de entornos.
                                </p>
                                <h5 className='tituloProductoColumnas'>Columna CI-225TN

                                </h5>
                                <p className='textoProductoColumnas'>
                                    Columna acústica de 20W certificada EN 54-24. Ofrece una una amplia respuesta en frecuencia, baja 
                                    distorsión y una alta presión sonora adecuada para emitir mensajes de evacuación de forma inteligible, 
                                    además de música.
                                    <br />
                                    Es válida tanto para interior como para exterior gracias a su protección IP 66, sirviendo para todo 
                                    tipo de entornos.
                                    
                                </p>

                            </div>

                        </div>
                        <div className='col-lg-3 col-md-12'>

                            <div className='contenedorNavColumnas'>
                                <Nav as="ul" className="flex-column">
                                    <Nav.Item className='linksArchivosColumnas' as="li" onClick={() => clicEnlacePdf('ficha825')}>
                                        {logoPdf}
                                        Ficha CI-825TN
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosColumnas' as="li" onClick={() => clicEnlacePdf('ficha225')}>
                                        {logoPdf}
                                        Ficha CI-225TN
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosColumnas' as="li" onClick={() => clicEnlacePdf('certificado')}>
                                        {logoPdf}
                                        Certificado CI-825TN y CI-225TN
                                    </Nav.Item>

                                </Nav>
                            </div>
                        </div>
                    </div>

                </div>

            </div >

            <Modal className='imgModalContainer' show={fotoSeleccionada !== null} onHide={() => setFotoSeleccionada(null)}>
                <Modal.Header closeButton>
                    {subtituloModal && <Modal.Title>{subtituloModal}</Modal.Title>}
                </Modal.Header>
                <Modal.Body>
                    {fotoSeleccionada && (<img src={fotoSeleccionada} alt="Foto Columnas acústicas" className="imgModal" />)}
                </Modal.Body>
            </Modal>

        </>
    );

}

