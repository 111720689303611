import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Row from 'react-bootstrap/Row'; import { MenuLateral } from '../MenuLateral/MenuLateral'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'; // Importa el icono faBars
import Nav from 'react-bootstrap/Nav';
import { fichaLuminancimetro } from '../../Archivos/luminancimetro';
import './Luminancimetro.css';

export function Luminancimetro() {

    const [fotoSeleccionada, setFotoSeleccionada] = useState(null);
    const [subtituloModal, setSubtituloModal] = useState("");

    const modalFoto = (foto, subtitulo) => {
        setFotoSeleccionada(foto);
        setSubtituloModal(subtitulo)
    };

    const logoPdf = <FontAwesomeIcon icon={faFilePdf} size="xl" style={{ color: "#006c87", }} />

    const abrirPdf = (archivo) => {
        window.open(archivo, '_blank');
    };

    const archivosPDF = {
        ficha: fichaLuminancimetro,
    };

    const clicEnlacePdf = (tipoArchivo) => {
        abrirPdf(archivosPDF[tipoArchivo]);
    };

    return (
        <>
            <div className="mainContainer">
                <MenuLateral />

                <div className='contenedorProducto'>
                    <div className='row'>
                        <div className='col-xl-3 col-lg-3 col-md-12 col-sm-12'>
                            <div className='imagenesLuminancimetro'>

                                <Row>
                                    <div className='contenedorFoto' >
                                        <img className="imagenProductoLuminancimetro"
                                            src={require("../Img/luminancimetro.webp")}
                                            
                                            onClick={() => modalFoto(require("../Img/luminancimetro.webp"), 'Luminancímetro')}
                                            alt='Luminancímetro' />
                                    </div>
                                </Row>

                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                            <div className='descripcionLuminancimetro'>
                                <h2 className='nombreProductoLuminancimetro'>Luminancímetro</h2>
                                <h5 className='tituloProductoLuminancimetro'>El sensor analógico DURLUM420 mide la luminancia (cd/m2) de una
                                    superficie, y la convierte en una señal en lazo de corriente.</h5>
                                <p className='textoProductoLuminancimetro'>
                                    La señal de salida de 4-20 mA es proporcionada por el mismo cable de alimentación, ofreciendo un cableado fácil
                                    en la instalación.
                                    <br />
                                    Está basado en un foto-sensor de Si, que ofrece una sensibilidad próxima a la luz visible, y un comportamiento
                                    estable.
                                    <br />
                                    Alto grado de protección para exteriores y construcción sólida en acero inoxidable.
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12'>

                            <div className='contenedorNavLuminancimetro'>
                                <Nav as="ul" className="flex-column">
                                    <Nav.Item className='linksArchivosLuminancimetro' as="li" onClick={() => clicEnlacePdf('ficha')}>
                                        {logoPdf}
                                        Ficha Luminancimetro
                                    </Nav.Item>

                                </Nav>
                            </div>
                        </div>
                    </div>

                </div>

            </div >

            <Modal className='imgModalContainer' show={fotoSeleccionada !== null} onHide={() => setFotoSeleccionada(null)}>
                <Modal.Header closeButton>
                    {subtituloModal && <Modal.Title>{subtituloModal}</Modal.Title>}
                </Modal.Header>
                <Modal.Body>
                    {fotoSeleccionada && (<img src={fotoSeleccionada} alt="Foto Luminancimetro" className="imgModal" />)}
                </Modal.Body>
            </Modal>

        </>
    );

}

