import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Row from 'react-bootstrap/Row'; import { MenuLateral } from '../MenuLateral/MenuLateral'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'; // Importa el icono faBars
import Nav from 'react-bootstrap/Nav';
import { ficha8250, manual8250, certificado } from '../../Archivos/neo8250';
import './Neo8250.css';

export function Neo8250() {

    const [fotoSeleccionada, setFotoSeleccionada] = useState(null);
    const [subtituloModal, setSubtituloModal] = useState("");

    const modalFoto = (foto, subtitulo) => {
        setFotoSeleccionada(foto);
        setSubtituloModal(subtitulo)
    };

    const logoPdf = <FontAwesomeIcon icon={faFilePdf} size="xl" style={{ color: "#006c87", }} />

    const abrirPdf = (archivo) => {
        window.open(archivo, '_blank');
    };

    const archivosPDF = {
        ficha: ficha8250,
        manual: manual8250,
        certificado: certificado

    };

    const clicEnlacePdf = (tipoArchivo) => {
        abrirPdf(archivosPDF[tipoArchivo]);
    };

    return (
        <>
            <div className="mainContainer">
                <MenuLateral />

                <div className='contenedorProducto'>
                    <div className='row'>
                        <div className='col-xl-3 col-lg-3 col-md-12 col-sm-12'>
                            <div className='imagenesNeo8250'>

                                <Row>
                                    <div className='contenedorFoto' >
                                        <img className="imagenProductoNeo8250"
                                            src={require("../Img/neoExtension8250.webp")}
                                            
                                            onClick={() => modalFoto(require("../Img/neoExtension8250.webp"), 'NEO Extensión 8250')}
                                            alt='NEO Extensión 8250' />
                                    </div>
                                </Row>

                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                            <div className='descripcionNeo8250'>
                                <h2 className='nombreProductoNeo8250'>NEO Extensión 8250</h2>
                                <h5 className='tituloProductoNeo8250'>LDA NEO-Extension 8250 es un controlador de extensión y un amplificador de 8 canales
                                    de 250W @100V/70V para el sistema compacto NEO EN 54-16 de megafonia y evacuación por voz.

                                </h5>
                                <p className='textoProductoNeo8250'>
                                    El audio y el control se transmite desde la unidad principal hasta esta unidad de extensión con cable UTP, a
                                    través de una conexión en cascada mediante protocolo FlexNet, garantizando la máxima calidad e integridad de la señal.
                                    <br />
                                    La extensión es configurable con el software NEO-Configurator(incluido en NEO). El software, permite configurar el
                                    equipo de forma remota y crear eventos para realizar acciones en el sistema archivados por condiciones de: nivel de
                                    entrada, fecha y hora, comando UDP, GPIO o botón del micrófono MPS.
                                    <br /><br />
                                    Características Generales:
                                    <br />
                                    - 8 amplificadores clase D de 250W en lineas de 100V/70V.
                                    <br />
                                    - 8 salidas de audio preamplificadas para la conexión de amplificadores de megafonia externos.
                                    <br />
                                    - DSP: Ecualizador paramétrico de 7 bandas entrada/salida, loudness, potenciador de sonido LDA, controles de volumen 
                                    independientes por canal E/S y filtros de audio.
                                    <br />
                                    - Triple puerto Ethernet: para Audio Digital Cobranet, control remoto y supervisión, y para escalar el sistema con
                                    NEO-Extension en modo daisy-chain con tecnología FlexNet (hasta1024 zonas).
                                    <br />
                                    - Control para hasta 32 atenuadores LDA AT6.
                                    <br />
                                    - 12 cierres de contacto supervisados con el panel central de alarma de incendios.

                                </p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12'>

                            <div className='contenedorNavNeo8250'>
                                <Nav as="ul" className="flex-column">
                                    <Nav.Item className='linksArchivosNeo8250' as="li" onClick={() => clicEnlacePdf('ficha')}>
                                        {logoPdf}
                                        Ficha NEO Extensión 8250
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosNeo8250' as="li" onClick={() => clicEnlacePdf('manual')}>
                                        {logoPdf}
                                        Manual Neo Extensión 8250
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosNeo8250' as="li" onClick={() => clicEnlacePdf('certificado')}>
                                        {logoPdf}
                                        Certificado en 54-16
                                    </Nav.Item>

                                </Nav>
                            </div>
                        </div>
                    </div>

                </div>

            </div >

            <Modal className='imgModalContainer' show={fotoSeleccionada !== null} onHide={() => setFotoSeleccionada(null)}>
                <Modal.Header closeButton>
                    {subtituloModal && <Modal.Title>{subtituloModal}</Modal.Title>}
                </Modal.Header>
                <Modal.Body>
                    {fotoSeleccionada && (<img src={fotoSeleccionada} alt="Foto NEO Extensión 8250" className="imgModal" />)}
                </Modal.Body>
            </Modal>

        </>
    );

}

