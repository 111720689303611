import React from 'react';
import { MenuLateral } from '../MenuLateral/MenuLateral'
import { useNavigate } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import './NeoExtension.css';

export function NeoExtension() {

    const navigate = useNavigate();

    const irA4250 = () => {
        navigate(`/neo4250`);
    };

    const irA4500 = () => {
        navigate(`/neo4500`);
    };

    const irA4500L = () => {
        navigate(`/neo4500L`);
    };

    const irA8250 = () => {
        navigate(`/neo8250`);
    };

    return (
        <>
            <div className="mainContainer">
                <MenuLateral />
                <div className='contenedor'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='tituloNeoExtension'>
                                <h1>NEO Extensión</h1>
                            </div>

                        </div>

                    </div>

                    <div className='row'>
                        <div className='contenedorCardsNeoExtension'>
                            <div className='row'>
                                <div className='col-xl-6 col-lg-6 col-md-12'>
                                    <Card>
                                        <Card.Img variant="neo4250" src={require('../Img/neoExtension4250.webp')} onClick={irA4250} style={{ cursor: 'pointer' }} alt='NEO Extensión 4250' fetchpriority='high' />
                                        <Card.Body>
                                            <Card.Title>NEO Extensión 4250</Card.Title>

                                        </Card.Body>

                                    </Card>
                                </div>
                                <div className='col-xl-6 col-lg-6 col-md-12'>
                                    <Card>
                                        <Card.Img variant="neo4500" src={require('../Img/neoExtension4500.webp')} onClick={irA4500} style={{ cursor: 'pointer' }} alt='NEO Extensión 4500' fetchpriority='high' />
                                        <Card.Body>
                                            <Card.Title>NEO Extensión 4500</Card.Title>

                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-xl-6 col-lg-6 col-md-12'>
                                    <Card>
                                        <Card.Img variant="neo4500L" src={require('../Img/neoExtension4500L.webp')} onClick={irA4500L} style={{ cursor: 'pointer' }} alt='NEO Extensión 4500L' fetchpriority='high' />
                                        <Card.Body>
                                            <Card.Title>NEO Extensión 4500L</Card.Title>

                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className='col-xl-6 col-lg-6 col-md-12'>
                                    <Card>
                                        <Card.Img variant="neo8250" src={require('../Img/neoExtension8250.webp')} onClick={irA8250} style={{ cursor: 'pointer' }} alt='NEO Extensión 8250' fetchpriority='high' />
                                        <Card.Body>
                                            <Card.Title>NEO Extensión 8250</Card.Title>

                                        </Card.Body>
                                    </Card>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

