import React from 'react';
import { MenuLateral } from '../MenuLateral/MenuLateral'
import { useNavigate } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import './DetectoresAutonomosE.css';

export function DetectoresAutonomosE() {

    const navigate = useNavigate();

    const irAStandgasHc = () => {
        navigate(`/standgasHc`);
    };

    const irAStandgasHcPro = () => {
        navigate(`/standgasHcPro`);
    };

    const irAStandgasProLcdExp = () => {
        navigate(`/standgasProLcdExp`);
    };

    return (
        <>
            <div className="mainContainer">
                <MenuLateral />

                <div className='contenedor'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='tituloDetAutonomosE'>
                                <h1>Detectores autónomos</h1>
                            </div>

                        </div>

                    </div>

                    <div className='row'>

                        <div className='contenedorCardsAutonomosE'>
                            <div className='row'>
                                <div className='col-xl-4 col-lg-4 col-md-12'>
                                    <Card>
                                        <Card.Img variant="standgasHc" src={require('../Img/standgasHc.webp')} onClick={irAStandgasHc} style={{ cursor: 'pointer' }} alt='Standgas HC' fetchpriority='high' />
                                        <Card.Body>
                                            <Card.Title className='tituloStandgasHc'>Standgas HC</Card.Title>

                                        </Card.Body>

                                    </Card>
                                </div>
                                <div className='col-xl-4 col-lg-4 col-md-12'>
                                    <Card>
                                        <Card.Img variant="standgasHcPro" src={require('../Img/standgasHcPro.webp')} onClick={irAStandgasHcPro} style={{ cursor: 'pointer' }} alt='Standgas HC PRO' fetchpriority='high' />
                                        <Card.Body>
                                            <Card.Title className='tituloStandgasHcPro'>Standgas HC PRO</Card.Title>

                                        </Card.Body>

                                    </Card>
                                </div>
                                <div className='col-xl-4 col-lg-4 col-md-12'>
                                    <Card>
                                        <Card.Img variant="standgasProLcdExp" src={require('../Img/standgasProLcdExp.webp')} onClick={irAStandgasProLcdExp} style={{ cursor: 'pointer' }} alt='Standgas Pro LCD EXP' fetchpriority='high' />
                                        <Card.Body>
                                            <Card.Title className='tituloStandgasProLcdExp'>Standgas Pro LCD EXP</Card.Title>

                                        </Card.Body>
                                    </Card>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

