import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Row from 'react-bootstrap/Row'; import { MenuLateral } from '../MenuLateral/MenuLateral'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'; // Importa el icono faBars
import Nav from 'react-bootstrap/Nav';
import { fichaSW100t, fichaSF25t, fichaBS40t } from '../../Archivos/otrosNoCert';
import './OtrosNoCert.css';

export function OtrosNoCert() {

    const [fotoSeleccionada, setFotoSeleccionada] = useState(null);
    const [subtituloModal, setSubtituloModal] = useState("");

    const modalFoto = (foto, subtitulo) => {
        setFotoSeleccionada(foto);
        setSubtituloModal(subtitulo)
    };

    const logoPdf = <FontAwesomeIcon icon={faFilePdf} size="xl" style={{ color: "#006c87", }} />

    const abrirPdf = (archivo) => {
        window.open(archivo, '_blank');
    };

    const archivosPDF = {
        fichaSW100t: fichaSW100t,
        fichaSF25t: fichaSF25t,
        fichaBS40t: fichaBS40t

    };

    const clicEnlacePdf = (tipoArchivo) => {
        abrirPdf(archivosPDF[tipoArchivo]);
    };

    return (
        <>
            <div className="mainContainer">
                <MenuLateral />

                <div className='contenedorProducto'>
                    <div className='row'>
                        <div className='col-xl-3 col-lg-3 col-md-12 col-sm-12'>
                            <div className='imagenesOtrosNoCert'>

                                <Row>
                                    <div className='contenedorFoto' >
                                        <img className="imagenProductoOtrosNoCert"
                                            src={require("../Img/sw100t.webp")}
                                            
                                            onClick={() => modalFoto(require("../Img/sw100t.webp"), 'SW-100T')}
                                            alt='SW-100T' />
                                    </div>
                                </Row>
                                <Row>
                                    <div className='contenedorFoto' >
                                        <img className="imagenProductoOtrosNoCert"
                                            src={require("../Img/sf-25t.webp")}
                                            
                                            onClick={() => modalFoto(require("../Img/sf-25t.webp"), 'SF-25T')}
                                            alt='SF-25T' />
                                    </div>
                                </Row>
                                <Row>
                                    <div className='contenedorFoto' >
                                        <img className="imagenProductoOtrosNoCert"
                                            src={require("../Img/bs-40t.webp")}
                                            
                                            onClick={() => modalFoto(require("../Img/bs-40t.webp"), 'BS-40T')}
                                            alt='BS-40T' />
                                    </div>
                                </Row>

                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                            <div className='descripcionOtrosNoCert'>
                                <h2 className='nombreProductoOtrosNoCert'>Otros</h2>
                                <h5 className='tituloProductoOtrosNoCert'>SW-100T
                                </h5>
                                <p className='textoProductoOtrosNoCert'>
                                    Es un subwoofer pasivo de altas prestaciones para instalaciones profesionales. Aporta una mayor profundidad
                                    de sonido a las instalaciones convencionales con altavoces full-range. Gracias a su diseño compacto el SW-100T
                                    permite ser instalado directamente en el techo.
                                    <br /><br />
                                    La incorporación del SW-100T a cualquier instalación de megafonía consigue una completa fidelidad en la
                                    reproducción del espectro de audio, cubriendo la banda de 35 Hz hasta los 200 Hz gracias a su driver de 8” y un
                                    recinto resonante especialmente diseñado para conseguir excelentes prestaciones en su banda de trabajo.

                                </p>

                                <h5 className='tituloProductoOtrosNoCert'> SF-25T
                                </h5>
                                <p className='textoProductoOtrosNoCert'>
                                    Altavoz esférico 5″ full−range acústicamente diseñado para lograr distribuciones acústicas de amplia cobertura
                                    y sonido uniforme.
                                    <br /><br />
                                    Su potencia y excelente rendimiento, permiten asegurar una alta calidad de sonido en cualquier dirección. Elegante
                                    a la vez que robusto, fabricado en ABS.
                                </p>

                                <h5 className='tituloProductoOtrosNoCert'> BS-40T
                                </h5>
                                <p className='textoProductoOtrosNoCert'>
                                    Es un altavoz invisible con transformador 100/70V para integrar en paredes o techos. Gracias su driver especial y 
                                    un innovador diseño exterior, el altavoz BS-40T ofrece altas prestaciones acústicas para música cuando se instala 
                                    sobre un techo o pared.
                                    <br /><br />
                                    Una vez adecuado con la pintura correspondiente, el altavoz BS-40T queda totalmente integrado con la superficie en 
                                    la que está instalado.
                                
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12'>

                            <div className='contenedorNavOtrosNoCert'>
                                <Nav as="ul" className="flex-column">
                                    <Nav.Item className='linksArchivosOtrosNoCert' as="li" onClick={() => clicEnlacePdf('fichaSW100t')}>
                                        {logoPdf}
                                        Ficha SW-100T
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosOtrosNoCert' as="li" onClick={() => clicEnlacePdf('fichaSF25t')}>
                                        {logoPdf}
                                        Ficha SF-25T
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosOtrosNoCert' as="li" onClick={() => clicEnlacePdf('fichaBS40t')}>
                                        {logoPdf}
                                        Ficha BS-40T
                                    </Nav.Item>

                                </Nav>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <Modal className='imgModalContainer' show={fotoSeleccionada !== null} onHide={() => setFotoSeleccionada(null)}>
                <Modal.Header closeButton>
                    {subtituloModal && <Modal.Title>{subtituloModal}</Modal.Title>}
                </Modal.Header>
                <Modal.Body>
                    {fotoSeleccionada && (<img src={fotoSeleccionada} alt="Foto otros no certificados" className="imgModal" />)}
                </Modal.Body>
            </Modal>

        </>
    );

}

