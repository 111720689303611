import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Row from 'react-bootstrap/Row'; import { MenuLateral } from '../MenuLateral/MenuLateral'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'; // Importa el icono faBars
import Nav from 'react-bootstrap/Nav';
import { fichaStandgasPro, manualStandgasPro, presentacionStandgasPro, folletoStandgasPro } from '../../Archivos/standgasPro';
import './StandgasPro.css';

export function StandgasPro() {

    const [fotoSeleccionada, setFotoSeleccionada] = useState(null);
    const [subtituloModal, setSubtituloModal] = useState("");

    const modalFoto = (foto, subtitulo) => {
        setFotoSeleccionada(foto);
        setSubtituloModal(subtitulo)
    };

    const logoPdf = <FontAwesomeIcon icon={faFilePdf} size="xl" style={{ color: "#006c87", }} />

    const abrirPdf = (archivo) => {
        window.open(archivo, '_blank');
    };

    const archivosPDF = {
        ficha: fichaStandgasPro,
        manual: manualStandgasPro,
        presentacion: presentacionStandgasPro,
        folleto: folletoStandgasPro
    };

    const clicEnlacePdf = (tipoArchivo) => {
        abrirPdf(archivosPDF[tipoArchivo]);
    };

    return (
        <>
            <div className="mainContainer">
                <MenuLateral />

                <div className='contenedorProducto'>
                    <div className='row'>
                        <div className='col-xl-3 col-lg-3 col-md-12 col-sm-12'>
                            <div className='imagenesStandgasPro'>

                                <Row>
                                    <div className='contenedorFoto' >
                                        <img className="imagenProductoStandgasPro"
                                            src={require("../Img/standgasPro.webp")}
                                            
                                            onClick={() => modalFoto(require("../Img/standgasPro.webp"), 'Standgas PRO')}
                                            alt='Standgas PRO' />
                                    </div>
                                </Row>

                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                            <div className='descripcionStandgasPro'>
                                <h2 className='nombreProductoStandgasPro'>Standgas PRO</h2>
                                <h5 className='tituloProductoStandgasPro'>STANDGAS PRO es un detector autónomo por tecnología electroquímica o
                                    infrarroja para la detección de gases tóxicos y O2 provisto de 3 relés. Funcionamiento autónomo: sin conexión
                                    a ninguna central.</h5>
                                <p className='textoProductoStandgasPro'>
                                    Gases disponibles: CO, NO2, NH3, H2S, Cl2, SO2, CO2 y O2.
                                    <br />
                                    – Control digital sobre el estado del sensor.
                                    <br />
                                    – Testeo automático de hardware.
                                    <br />
                                    – Filtro digital basado en muestreos variables de los promedios de los valores del sensor.
                                    <br />
                                    – Compensación térmica automática que proporciona una respuesta correcta ante variaciones
                                    de temperatura (STANDGAS O2 no precisa esta característica).
                                    <br />
                                    – Ajuste automático exacto del cero. Monitoriza el cero en relación con la respuesta del sensor y la electrónica.
                                    STANDGAS realiza automáticamente un test cada 30 minutos: si la deriva –drift- es mayor o menor del 2% del valor
                                    total de la escala, se reajustara a cero de lo contrario el dato se mostrara como una lectura.
                                    <br />
                                    – Hasta tres niveles de salida o dos más una salida de avería.
                                    <br />
                                    – Programación de niveles de alarma de oxígeno ascendentes o descendentes.

                                </p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12'>

                            <div className='contenedorNavStandgasPro'>
                                <Nav as="ul" className="flex-column">
                                    <Nav.Item className='linksArchivosStandgasPro' as="li" onClick={() => clicEnlacePdf('ficha')}>
                                        {logoPdf}
                                        Ficha Standgas Pro
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosStandgasPro' as="li" onClick={() => clicEnlacePdf('manual')}>
                                        {logoPdf}
                                        Manual Standgas Pro
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosStandgasPro' as="li" onClick={() => clicEnlacePdf('presentacion')}>
                                        {logoPdf}
                                        Presentación Standgas Pro
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosStandgasPro' as="li" onClick={() => clicEnlacePdf('folleto')}>
                                        {logoPdf}
                                        Folleto Standgas Pro
                                    </Nav.Item>

                                </Nav>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <Modal className='imgModalContainer' show={fotoSeleccionada !== null} onHide={() => setFotoSeleccionada(null)}>
                <Modal.Header closeButton>
                    {subtituloModal && <Modal.Title>{subtituloModal}</Modal.Title>}
                </Modal.Header>
                <Modal.Body>
                    {fotoSeleccionada && (<img src={fotoSeleccionada} alt="Foto Standgas Pro" className="imgModal" />)}
                </Modal.Body>
            </Modal>

        </>
    );

}

