import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Row from 'react-bootstrap/Row'; import { MenuLateral } from '../MenuLateral/MenuLateral'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'; // Importa el icono faBars
import Nav from 'react-bootstrap/Nav';
import { fichaSondeltox, fichaInterfaceSondeltox, presentacionSondeltox } from '../../Archivos/sondeltox';
import './Sondeltox.css';

export function Sondeltox() {

    const [fotoSeleccionada, setFotoSeleccionada] = useState(null);
    const [subtituloModal, setSubtituloModal] = useState("");

    const modalFoto = (foto, subtitulo) => {
        setFotoSeleccionada(foto);
        setSubtituloModal(subtitulo)
    };

    const logoPdf = <FontAwesomeIcon icon={faFilePdf} size="xl" style={{ color: "#006c87", }} />

    const abrirPdf = (archivo) => {
        window.open(archivo, '_blank');
    };

    const archivosPDF = {
        ficha: fichaSondeltox,
        fichaInterface: fichaInterfaceSondeltox,
        presentacion: presentacionSondeltox
    };

    const clicEnlacePdf = (tipoArchivo) => {
        abrirPdf(archivosPDF[tipoArchivo]);
    };

    return (
        <>
            <div className="mainContainer">
                <MenuLateral />

                <div className='contenedorProducto'>
                    <div className='row'>
                        <div className='col-xl-3 col-lg-3 col-md-12 col-sm-12'>
                            <div className='imagenesSondeltox'>

                                <Row>
                                    <div className='contenedorFoto' >
                                        <img className="imagenProductoSondeltox"
                                            src={require("../Img/sondeltox.webp")}
                                            
                                            onClick={() => modalFoto(require("../Img/sondeltox.webp"), 'Sondeltox')}
                                            alt='Sondeltox' />
                                    </div>
                                </Row>

                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                            <div className='descripcionSondeltox'>
                                <h2 className='nombreProductoSondeltox'>Sondeltox</h2>
                                <h5 className='tituloProductoSondeltox'>Detector 4-20mA con conexión a 2 hilos provisto de sensor electroquímico para la detección
                                    de gases tóxicos y oxígeno compatible con cualquier sistema con entradas estándar 4-20mA.</h5>
                                <p className='textoProductoSondeltox'>
                                    Compatible con DURGAS y EUROSONDELCO (mediante interface).
                                    <br />
                                    Gases disponibles: Monóxido de carbono (CO), Oxígeno (O2), Dióxido de nitrógeno (NO2), Ácido sulfhídrico (H2S),
                                    Dióxido de azufre (SO2), Monóxido de nitrógeno (NO), Cloro (Cl2) e Hidrógeno (H2). Para otros gases, consultar.
                                    <br />
                                    Diferentes rangos de medida disponibles.
                                    <br />
                                    Para versión antideflagrante visite DURTOX-X.
                                    <br />
                                    Tensión de alimentación: 10V a 35V DC.
                                    <br />
                                    Consumo: 21mA max.
                                    <br />
                                    Grado de protección: IP65.
                                    <br />
                                    Temperatura de trabajo: –20ºC a +50ºC.
                                    <br />
                                    100% Lineal en todo el rango.
                                    <br />
                                    Vida útil: 2 años en aire (3 años en aire para CO).
                                    <br />
                                    Dimensiones (mm): 120 x 150 x 63.
                                    <br />
                                    Peso (gr): 325 aprox.
                                    <br />
                                    Todos los detectores fabricados por DURAN ELECTRÓNICA han sido calibrados en nuestros laboratorios y no
                                    necesitan ser calibrados en obra.

                                </p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12'>

                            <div className='contenedorNavSondeltox'>
                                <Nav as="ul" className="flex-column">
                                    <Nav.Item className='linksArchivosSondeltox' as="li" onClick={() => clicEnlacePdf('ficha')}>
                                        {logoPdf}
                                        Ficha Sondeltox
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosSondeltox' as="li" onClick={() => clicEnlacePdf('fichaInterface')}>
                                        {logoPdf}
                                        Ficha Interface Sondeltox
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosSondeltox' as="li" onClick={() => clicEnlacePdf('presentacion')}>
                                        {logoPdf}
                                        Presentación
                                    </Nav.Item>

                                </Nav>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <Modal className='imgModalContainer' show={fotoSeleccionada !== null} onHide={() => setFotoSeleccionada(null)}>
                <Modal.Header closeButton>
                    {subtituloModal && <Modal.Title>{subtituloModal}</Modal.Title>}
                </Modal.Header>
                <Modal.Body>
                    {fotoSeleccionada && (<img src={fotoSeleccionada} alt="Foto Sondeltox" className="imgModal" />)}
                </Modal.Body>
            </Modal>

        </>
    );

}

