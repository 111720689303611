import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Row from 'react-bootstrap/Row'; import { MenuLateral } from '../MenuLateral/MenuLateral'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'; // Importa el icono faBars
import Nav from 'react-bootstrap/Nav';
import { guiaPiranometro } from '../../Archivos/piranometro';
import './Piranometro.css';

export function Piranometro() {

    const [fotoSeleccionada, setFotoSeleccionada] = useState(null);
    const [subtituloModal, setSubtituloModal] = useState("");

    const modalFoto = (foto, subtitulo) => {
        setFotoSeleccionada(foto);
        setSubtituloModal(subtitulo)
    };

    const logoPdf = <FontAwesomeIcon icon={faFilePdf} size="xl" style={{ color: "#006c87", }} />

    const abrirPdf = (archivo) => {
        window.open(archivo, '_blank');
    };

    const archivosPDF = {
        guia: guiaPiranometro
    };

    const clicEnlacePdf = (tipoArchivo) => {
        abrirPdf(archivosPDF[tipoArchivo]);
    };

    return (
        <>
            <div className="mainContainer">
                <MenuLateral />

                <div className='contenedorProducto'>
                    <div className='row'>
                        <div className='col-xl-3 col-lg-3 col-md-12 col-sm-12'>
                            <div className='imagenesPiranometro'>

                                <Row>
                                    <div className='contenedorFoto' >
                                        <img className="imagenProductoPiranometro"
                                            src={require("../Img/piranometro.webp")}
                                            
                                            onClick={() => modalFoto(require("../Img/piranometro.webp"), 'Piranómetro')}
                                            alt='Piranómetro' />
                                    </div>
                                </Row>

                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                            <div className='descripcionPiranometro'>
                                <h2 className='nombreProductoPiranometro'>Piranómetro</h2>
                                <h5 className='tituloProductoPiranometro'>Un piranómetro (también llamado solarímetro y actinómetro) es un
                                    instrumento meteorológico utilizado para medir de manera muy precisa la radiación solar incidente sobre la
                                    superficie de la tierra.</h5>
                                <p className='textoProductoPiranometro'>
                                    Se trata de un sensor diseñado para medir la densidad del flujo de radiación solar (kilovatios por metro
                                    cuadrado) en un campo de 180 grados.
                                    <br />
                                    Generalmente se utilizan tres medidas de radiación: semiesférica total, difusa y directa. Para las medidas
                                    de radiación difusa y semiesférica la radiación directa se suprime utilizando un disco parasol.
                                    <br />
                                    El principio físico utilizado generalmente en la medida es un termopar sobre el que incide la radiación a 
                                    través de dos cúpulas semiesféricas de vidrio. Las medidas se expresan en kW/m².

                                </p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12'>

                            <div className='contenedorNavPiranometro'>
                                <Nav as="ul" className="flex-column">
                                    <Nav.Item className='linksArchivosPiranometro' as="li" onClick={() => clicEnlacePdf('guia')}>
                                        {logoPdf}
                                        Guía de Selección
                                    </Nav.Item>

                                </Nav>
                            </div>
                        </div>
                    </div>

                </div>

            </div >

            <Modal className='imgModalContainer' show={fotoSeleccionada !== null} onHide={() => setFotoSeleccionada(null)}>
                <Modal.Header closeButton>
                    {subtituloModal && <Modal.Title>{subtituloModal}</Modal.Title>}
                </Modal.Header>
                <Modal.Body>
                    {fotoSeleccionada && (<img src={fotoSeleccionada} alt="Foto Piranometro" className="imgModal" />)}
                </Modal.Body>
            </Modal>

        </>
    );

}

