import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Row from 'react-bootstrap/Row'; import { MenuLateral } from '../MenuLateral/MenuLateral'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'; // Importa el icono faBars
import Nav from 'react-bootstrap/Nav';
import { fichaVCC64, manualVCC64 } from '../../Archivos/selector';
import './Selector.css';

export function Selector() {

    const [fotoSeleccionada, setFotoSeleccionada] = useState(null);
    const [subtituloModal, setSubtituloModal] = useState("");

    const modalFoto = (foto, subtitulo) => {
        setFotoSeleccionada(foto);
        setSubtituloModal(subtitulo)
    };

    const logoPdf = <FontAwesomeIcon icon={faFilePdf} size="xl" style={{ color: "#006c87", }} />

    const abrirPdf = (archivo) => {
        window.open(archivo, '_blank');
    };

    const archivosPDF = {
        ficha: fichaVCC64,
        manual: manualVCC64,
    };

    const clicEnlacePdf = (tipoArchivo) => {
        abrirPdf(archivosPDF[tipoArchivo]);
    };

    return (
        <>
            <div className="mainContainer">
                <MenuLateral />

                <div className='contenedorProducto'>
                    <div className='row'>
                        <div className='col-xl-3 col-lg-3 col-md-12 col-sm-12'>
                            <div className='imagenesSelector'>

                                <Row>
                                    <div className='contenedorFoto' >
                                        <img className="imagenProductoSelector"
                                            src={require("../Img/vcc64.webp")}
                                            
                                            onClick={() => modalFoto(require("../Img/vcc64.webp"), 'VCC-64')}
                                            alt='VCC-64' />
                                    </div>
                                </Row>
                                <Row>
                                    <div className='contenedorFoto' >
                                        <img className="imagenProductoSelector"
                                            src={require("../Img/vcc64PSK.webp")}
                                            
                                            onClick={() => modalFoto(require("../Img/vcc64PSK.webp"), 'VCC-64PSK')}
                                            alt='VCC-64PSK' />
                                    </div>
                                </Row>

                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                            <div className='descripcionSelector'>
                                <h2 className='nombreProductoSelector'>Selector de canal y volúmen VCC-64</h2>
                                <h5 className='tituloProductoSelector'>El VCC-64 permite la selección de hasta 99 canales y control de volumen (0-9).

                                </h5>
                                <p className='textoProductoSelector'>
                                    Instalado en la zona local y conectado al sistema por el cable de bus, provee a la zona de selección de fuente, control de 
                                    volumen, y auto-bloqueo.
                                    <br /><br />
                                    Características Generales:
                                    <br /><br />
                                    - Instalación en zona local y conectado al sistema por cable de bus.
                                    <br />
                                    - 99 canales selecionables.
                                    <br />
                                    - Control de volumen (0-9).
                                    <br />
                                    - Bus RS-485.
                                    <br />
                                    - Conector de 4 polos (terminal block).
                                    <br />
                                    - Selector de canal DIP.
                                    <br />
                                    - Display LCD de 2×8 caracteres.
                                    <br /><br />
                                    El adaptador de comunicaciones VCC-64PSK permite conectar hasta 10 dispositivos VCC-64 para 100m de distancia usando cableado 
                                    estándar UTP.
                                </p>
                                
                            </div>

                        </div>
                        <div className='col-lg-3 col-md-12'>

                            <div className='contenedorNavSelector'>
                                <Nav as="ul" className="flex-column">
                                    <Nav.Item className='linksArchivosSelector' as="li" onClick={() => clicEnlacePdf('ficha')}>
                                        {logoPdf}
                                        Ficha VCC-64
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosSelector' as="li" onClick={() => clicEnlacePdf('manual')}>
                                        {logoPdf}
                                        Manual VCC-64
                                    </Nav.Item>

                                </Nav>
                            </div>
                        </div>
                    </div>

                </div>

            </div >

            <Modal className='imgModalContainer' show={fotoSeleccionada !== null} onHide={() => setFotoSeleccionada(null)}>
                <Modal.Header closeButton>
                    {subtituloModal && <Modal.Title>{subtituloModal}</Modal.Title>}
                </Modal.Header>
                <Modal.Body>
                    {fotoSeleccionada && (<img src={fotoSeleccionada} alt="Foto Selector" className="imgModal" />)}
                </Modal.Body>
            </Modal>

        </>
    );

}

