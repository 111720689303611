import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Row from 'react-bootstrap/Row'; import { MenuLateral } from '../MenuLateral/MenuLateral'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'; // Importa el icono faBars
import Nav from 'react-bootstrap/Nav';
import { fichaPCM20tn, fichaPCM220tn, fichaPS30tn, certificadoPCM, certificadoPS30tn } from '../../Archivos/proyectores';
import './Proyectores.css';

export function Proyectores() {

    const [fotoSeleccionada, setFotoSeleccionada] = useState(null);
    const [subtituloModal, setSubtituloModal] = useState("");

    const modalFoto = (foto, subtitulo) => {
        setFotoSeleccionada(foto);
        setSubtituloModal(subtitulo)
    };

    const logoPdf = <FontAwesomeIcon icon={faFilePdf} size="xl" style={{ color: "#006c87", }} />

    const abrirPdf = (archivo) => {
        window.open(archivo, '_blank');
    };

    const archivosPDF = {
        fichaPCM20tn: fichaPCM20tn,
        fichaPCM220tn: fichaPCM220tn,
        fichaPS30tn: fichaPS30tn,
        certificadoPCM: certificadoPCM,
        certificadoPS30tn: certificadoPS30tn

    };

    const clicEnlacePdf = (tipoArchivo) => {
        abrirPdf(archivosPDF[tipoArchivo]);
    };

    return (
        <>
            <div className="mainContainer">
                <MenuLateral />

                <div className='contenedorProducto'>
                    <div className='row'>
                        <div className='col-xl-3 col-lg-3 col-md-12 col-sm-12'>
                            <div className='imagenesProyectores'>

                                <Row>
                                    <div className='contenedorFoto' >
                                        <img className="imagenProductoProyectores"
                                            src={require("../Img/ch-42tn.webp")}
                                            
                                            onClick={() => modalFoto(require("../Img/pcm-20tn.webp"), 'PCM-20TN')}
                                            alt='PCM-20TN' />
                                    </div>
                                </Row>
                                <Row>
                                    <div className='contenedorFoto' >
                                        <img className="imagenProductoProyectores"
                                            src={require("../Img/pcm-220tn.webp")}
                                            
                                            onClick={() => modalFoto(require("../Img/pcm-220tn.webp"), 'PCM-220TN')}
                                            alt='PCM-220TN' />
                                    </div>
                                </Row>
                                <Row>
                                    <div className='contenedorFoto' >
                                        <img className="imagenProductoProyectores"
                                            src={require("../Img/ps-30tn.webp")}
                                            
                                            onClick={() => modalFoto(require("../Img/ps-30tn.webp"), 'PS-30TN')}
                                            alt='PS-30TN' />
                                    </div>
                                </Row>

                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                            <div className='descripcionProyectores'>
                                <h2 className='nombreProductoProyectores'>Proyectores de sonido</h2>
                                <h5 className='tituloProductoProyectores'>Proyector unidireccional PCM-20TN
                                </h5>
                                <p className='textoProductoProyectores'>
                                    El altavoz PCM-20TN es un proyector acústico unidireccional de 20W que cumple con la normativa para alarma por voz
                                    EN54 – 24.
                                    <br /><br />
                                    Ofrece una alta calidad de sonido y mensajes de evacuación por voz inteligibles gracias a su alta presión sonora,
                                    baja distorsión y amplia respuesta en frecuencia. Su alto grado de protección IP (65), asegura un funcionamiento
                                    fiable en un amplio rango de temperaturas y humedad, haciéndolo idóneo tanto para aplicaciones de interior como de
                                    exterior.

                                </p>

                                <h5 className='tituloProductoProyectores'> Proyector bidireccional PCM-220TN
                                </h5>
                                <p className='textoProductoProyectores'>
                                    El altavoz PCM-220TN es un proyector bidireccional de 20W de gran rendimiento que cumple con la normativa para alarma
                                    por voz EN54-24.
                                    <br /><br />
                                    Ofrece una alta calidad de sonido y mensajes de evacuación por voz inteligibles gracias a su alta presión sonora, baja
                                    distorsión y amplia respuesta en frecuencia. Su alto grado de protección IP asegura un funcionamiento fiable en un
                                    amplio rango de temperaturas y de humedad, haciéndolo idóneo tanto para aplicaciones de interior como de exterior.

                                </p>
                                <h5 className='tituloProductoProyectores'> Bocina PS-30TN
                                </h5>
                                <p className='textoProductoProyectores'>
                                    El altavoz LDA PS-30TN es una bocina que cumple la normativa EN 54-24 diseñada para una alta resistencia al fuego y 
                                    preparada para su instalación en grandes superficies debido a su gran sensibilidad y directividad.
                                    <br /><br />
                                    Está construido en ABS altamente resistente a impactos e incluye un terminal cerámico y un fusible térmico de protección 
                                    extra contra incendios. El selector de potencias que incorpora permite seleccionar la potencia en un rango de 
                                    15 W / 10 W / 5 W a 100V u 8 Ohms.
                                    <br /><br />
                                    Las características del sistema permiten cubrir las necesidades auditivas de espacios de tamaño mediano y grandes tales 
                                    como estaciones de tren, túneles, estadios deportivos o parkings.

                                </p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12'>

                            <div className='contenedorNavProyectores'>
                                <Nav as="ul" className="flex-column">
                                    <Nav.Item className='linksArchivosProyectores' as="li" onClick={() => clicEnlacePdf('fichaPCM20tn')}>
                                        {logoPdf}
                                        Ficha PCM-20TN
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosProyectores' as="li" onClick={() => clicEnlacePdf('fichaPCM220tn')}>
                                        {logoPdf}
                                        Ficha PCM-220TN
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosProyectores' as="li" onClick={() => clicEnlacePdf('fichaPS30tn')}>
                                        {logoPdf}
                                        Ficha PS-30TN
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosProyectores' as="li" onClick={() => clicEnlacePdf('certificadoPCM')}>
                                        {logoPdf}
                                        Certificado PCM-20TN y PCM-220TN
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosProyectores' as="li" onClick={() => clicEnlacePdf('certificadoPS30tn')}>
                                        {logoPdf}
                                        Certificado PS-30TN
                                    </Nav.Item>

                                </Nav>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <Modal className='imgModalContainer' show={fotoSeleccionada !== null} onHide={() => setFotoSeleccionada(null)}>
                <Modal.Header closeButton>
                    {subtituloModal && <Modal.Title>{subtituloModal}</Modal.Title>}
                </Modal.Header>
                <Modal.Body>
                    {fotoSeleccionada && (<img src={fotoSeleccionada} alt="Foto proyectores de sonido" className="imgModal" />)}
                </Modal.Body>
            </Modal>

        </>
    );

}

