import React from 'react';
import { MenuLateral } from '../MenuLateral/MenuLateral'
import { useNavigate } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import './Neo.css';

export function Neo() {

    const navigate = useNavigate();

    const irANeoUnidadPrincipal = () => {
        navigate(`/neoUnidadPrincipal`);
    };

    const irANeoExtension = () => {
        navigate(`/neoExtension`);
    };

    const irAAccesoriosNeo = () => {
        navigate(`/accesoriosNeo`);
    };

    const irASoftwareDeControl = () => {
        navigate(`/softwareControl`);
    };

    return (
        <>
            <div className="mainContainer">
                <MenuLateral />
                <div className='contenedor'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='tituloNeo2'>
                                <h1>Neo</h1>
                            </div>

                        </div>

                    </div>

                    <div className='row'>
                        <div className='contenedorCardsNeo'>
                            <div className='row'>
                                <div className='col-xl-6 col-lg-6 col-md-12'>
                                    <Card>
                                        <Card.Img variant="neoPrincipal" src={require('../Img/neo.webp')} onClick={irANeoUnidadPrincipal} style={{ cursor: 'pointer' }} alt='NEO Unidad Principal' fetchpriority='high' />
                                        <Card.Body>
                                            <Card.Title>NEO Unidad Principal</Card.Title>

                                        </Card.Body>

                                    </Card>
                                </div>
                                <div className='col-xl-6 col-lg-6 col-md-12'>
                                    <Card>
                                        <Card.Img variant="neoExtension" src={require('../Img/neoExtension4250.webp')} onClick={irANeoExtension} style={{ cursor: 'pointer' }} alt='NEO Extensión' fetchpriority='high' />
                                        <Card.Body>
                                            <Card.Title>NEO Extensión</Card.Title>

                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-xl-6 col-lg-6 col-md-12'>
                                    <Card>
                                        <Card.Img variant="accesoriosNeo" src={require('../Img/power960.webp')} onClick={irAAccesoriosNeo} style={{ cursor: 'pointer' }} alt='Accesorios NEO' fetchpriority='high' />
                                        <Card.Body>
                                            <Card.Title>Accesorios NEO</Card.Title>

                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className='col-xl-6 col-lg-6 col-md-12'>
                                    <Card>
                                        <Card.Img variant="softwareControl" src={require('../Img/simeCalendar.webp')} onClick={irASoftwareDeControl} style={{ cursor: 'pointer' }} alt='Software de control' fetchpriority='high' />
                                        <Card.Body>
                                            <Card.Title>Software de control</Card.Title>

                                        </Card.Body>
                                    </Card>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

