import React from 'react';
import { MenuLateral } from '../MenuLateral/MenuLateral'
import { useNavigate } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import './SoftwareControl.css';

export function SoftwareControl() {

    const navigate = useNavigate();

    const irASimeControl = () => {
        navigate(`/simeControl`);
    };

    const irASimeCalendar = () => {
        navigate(`/simeCalendar`);
    };

    return (
        <>
            <div className="mainContainer">
                <MenuLateral />

                <div className='contenedor'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='tituloSoftwareControl'>
                                <h1>Software de Control</h1>
                            </div>

                        </div>

                    </div>

                    <div className='row'>

                        <div className='contenedorCardsSoftwareControl'>
                            <div className='row'>
                                <div className='col-xl-6 col-lg-6 col-md-12'>
                                    <Card>
                                        <Card.Img variant="simeControl" src={require('../Img/simeControl.webp')} onClick={irASimeControl} style={{ cursor: 'pointer' }} alt='SIME Control' fetchpriority='high' />
                                        <Card.Body>
                                            <Card.Title className='tituloSime'>SIME Control</Card.Title>

                                        </Card.Body>

                                    </Card>
                                </div>
                                <div className='col-xl-6 col-lg-6 col-md-12'>
                                    <Card>
                                        <Card.Img variant="power960" src={require('../Img/simeCalendar.webp')} onClick={irASimeCalendar} style={{ cursor: 'pointer' }} alt='SIME Calendar' fetchpriority='high' />
                                        <Card.Body>
                                            <Card.Title className='tituloCalendar'>SIME Calendar</Card.Title>

                                        </Card.Body>
                                    </Card>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

