import { useState } from 'react';
import './Contacto.css';
import { Form, Button, Card } from 'react-bootstrap';
import axios from 'axios';




export function Contacto() {

    const baseURL = 'https://servidordtgas.onrender.com/api/v1/publico/contacto';

    const [formulario, setFormulario] = useState({ nombre: '', apellido: '', email: '', empresa: '', conoce: '', mensaje: '' });

    const enviarInfo = async (e) => {
        e.preventDefault();
        console.log(formulario);
        axios.post(baseURL, formulario)
            .then(res => {
                console.log(res);
                alert(res.data.respuesta);
                e.target.reset();
            })
            .catch(error => {
                console.log('Error en el post ', error);
            })
    }

    return (
        <div className='container mt-5'>
            <div className='row'>


                <div className='col-md-6'>

                    <Card>

                        <Card.Body>
                            <Card.Title>Ingresa tu consulta</Card.Title>
                        </Card.Body>

                        <Form onSubmit={e => enviarInfo(e)}>
                            <Form.Group className="mb-3" controlId="formBasicNombre">
                                <Form.Label>Nombre</Form.Label>
                                <Form.Control type="text" required onChange={(e) => setFormulario({ ...formulario, nombre: e.target.value })} />

                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicNombre">
                                <Form.Label>Apellido</Form.Label>
                                <Form.Control type="text" required onChange={(e) => setFormulario({ ...formulario, apellido: e.target.value })} />

                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" required onChange={(e) => setFormulario({ ...formulario, email: e.target.value })} />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicEmpresa">
                                <Form.Label>Empresa</Form.Label>
                                <Form.Control type="text" required onChange={(e) => setFormulario({ ...formulario, empresa: e.target.value })} />
                            </Form.Group>

                            <Form.Select aria-label="Default select example" onChange={(e) => setFormulario({
                                ...formulario, conoce: e.target.value === "" ? "" : (

                                    e.target.value === "1" ? "Búsqueda por internet" :
                                        e.target.value === "2" ? "Recomendación" :
                                            e.target.value === "3" ? "Publicidad" :
                                                e.target.value === "4" ? "Otro" :
                                                    ""
                                )
                            })}>
                                <option value="">¿Cómo nos ha conocido?</option>
                                <option value="1">Búsqueda por internet</option>
                                <option value="2">Recomendación</option>
                                <option value="3">Publicidad</option>
                                <option value="4">Otro</option>
                            </Form.Select>

                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Mensaje</Form.Label>
                                <Form.Control as="textarea" rows={4} placeholder='Introduzca su mensaje' onChange={(e) => setFormulario({ ...formulario, mensaje: e.target.value })} />
                            </Form.Group>

                            <Button id='boton' variant="primary" type="submit">
                                Enviar
                            </Button>
                        </Form>

                    </Card>



                </div>

                <div className='col-md-6'>
                    <div className="container-form">
                        <div className="info-form">
                            <h1 id='nombreEmpresa'>DTGAS</h1>
                            <div className='datos'>
                                <h2>Contacto</h2>
                                <p className="p-info">Teléfono: +569 5866 5386</p>
                                <p className="p-info">Av. Las Condes 12253, Dpto. 1011 - T1</p>
                                <p className="p-info">Santiago, Chile</p>

                            </div>



                        </div>
                        <div className="mapaGoogle">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3701.455754131598!2d-70.5170107!3d-33.3724231!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662cbfa892465e3%3A0xba14c62880cae41d!2sAv.%20Las%20Condes%2012523%2C%207590943%20Las%20Condes%2C%20Regi%C3%B3n%20Metropolitana%2C%20Chile!5e1!3m2!1ses!2sar!4v1697062530252!5m2!1ses!2sar"
                                width="100%" height="400" allowFullScreen="" loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade">
                            </iframe>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}


