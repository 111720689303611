import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Row from 'react-bootstrap/Row'; import { MenuLateral } from '../MenuLateral/MenuLateral'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'; // Importa el icono faBars
import Nav from 'react-bootstrap/Nav';
import { fichaDurtexHc, fichaDurtexHcPro, fichaDurtexXHcPro, fichaCajaDerivacionAtex, manualDurtex, presentacionDurtex } from '../../Archivos/durtex';
import './DetectorDurtex.css';

export function DetectorDurtex() {

    const [fotoSeleccionada, setFotoSeleccionada] = useState(null);
    const [subtituloModal, setSubtituloModal] = useState("");

    const modalFoto = (foto, subtitulo) => {
        setFotoSeleccionada(foto);
        setSubtituloModal(subtitulo)
    };

    const logoPdf = <FontAwesomeIcon icon={faFilePdf} size="xl" style={{ color: "#006c87", }} />

    const abrirPdf = (archivo) => {
        window.open(archivo, '_blank');
    };

    const archivosPDF = {
        fichaHc: fichaDurtexHc,
        fichaHcPro: fichaDurtexHcPro,
        fichaXHcPro: fichaDurtexXHcPro,
        fichaCaja: fichaCajaDerivacionAtex,
        manual: manualDurtex,
        presentacion: presentacionDurtex
    };

    const clicEnlacePdf = (tipoArchivo) => {
        abrirPdf(archivosPDF[tipoArchivo]);
    };

    return (
        <>
            <div className="mainContainer">
                <MenuLateral />

                <div className='contenedorProducto'>
                    <div className='row'>
                        <div className='col-xl-3 col-lg-3 col-md-12 col-sm-12'>
                            <div className='imagenesDurtex'>

                                <Row>
                                    <div className='contenedorFoto' >
                                        <img className="imagenProductoDurtex"
                                            src={require("../Img/durtex1.webp")}
                                            
                                            onClick={() => modalFoto(require("../Img/durtex1.webp"), 'Detector Durtex X HC PRO')}
                                            alt='Detector Durtex X HC PRO' />
                                    </div>
                                </Row>
                                <Row>
                                    <div className='contenedorFoto' >
                                        <img className="imagenProductoDurtex"
                                            src={require("../Img/durtex2.webp")}
                                            
                                            onClick={() => modalFoto(require("../Img/durtex2.webp"), 'Detector Durtex HC')}
                                            alt='Detector Durtex HC' />
                                    </div>
                                </Row>
                                <Row>
                                    <div className='contenedorFoto' >
                                        <img className="imagenProductoDurtex"
                                            src={require("../Img/durtex3.webp")}
                                            
                                            onClick={() => modalFoto(require("../Img/durtox3.webp"), 'Detector Durtex HC PRO')}
                                            alt='Detector Durtex HC PRO' />
                                    </div>
                                </Row>

                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                            <div className='descripcionDurtex'>
                                <h2 className='nombreProductoDurtex'>Detector Durtex 4-20mA</h2>
                                <h5 className='tituloProductoDurtex'>Detectores 4-20mA para la detección de gases explosivos mediante tecnología
                                    catalítica -pellistor- compatibles con cualquier sistema con entradas estándar 4-20mA y con las centrales DURGAS
                                    y EUROSONDELCO mediante Interface.
                                </h5>
                                <p className='textoProductoDurtex'>
                                    DURTEX HC. Gases disponibles: butano-propano, metano-gas natural e hidrógeno.
                                    <br />
                                    DURTEX HC PRO. Gases disponibles: metano-gas natural, butano-propano, hidrógeno, heptano, hexano, pentano, metanol,
                                    estireno, etano, etanol, etileno, propileno, acetona, amoniaco, ciclohexano, ciclopentano, dioxano, acetato de butilo,
                                    acetato de etilo, ácido acético, alcohol isobutílico, alcohol isopropílico, decano, benceno, octano, metil etil
                                    quetona, -butanona-, nonano, propanol, tolueno, xileno. Para otros gases consultar disponibilidad. También se
                                    fabrica una versión especial para detección de ACETILENO.
                                    <br />
                                    DURTEX X HC PRO es un detector antideflagrante por tecnología catalítica para la detección de gases explosivos. Ha
                                    sido diseñado para su uso en ambientes en los cuales sea probable la formación de atmósferas explosivas por gases o
                                    vapores, asegurando un alto nivel de protección. (Grupo de aparatos II – Categoría 2 de la Directiva ATEX). Existe
                                    una versión del detector, también certificada ATEX, con un alto nivel de protección y destinada a usarse, además,
                                    en atmósferas explosivas de polvo. Está certificado según la Directiva Europea ATEX LOM08ATEX2059 X.
                                    <br />
                                    Rango de detección de 0 a 100% L.I.E.
                                    <br />
                                    Indicaciones ópticas de avería de lazo y de sensor.
                                    <br />
                                    Módulo de relé de alarma programable opcional: 1 nivel de alarma programable, desconexión instantánea/retardada, 
                                    programación retardo de desconexión y estado inicial del relé en reposo.
                                    <br />
                                    Bajo consumo (30mA a 24V) que permite la instalación de más detectores y mayor longitud del lazo sin necesidad de 
                                    fuentes de alimentación suplementarias.
                                    <br />
                                    Amplio rango de alimentación (10V a 30V DC).
                                    <br />
                                    Detector con filtro sinterizado. (DURTEX HC PRO).
                                    <br />
                                    Calibrados en fábrica con gas patrón. No necesitan ser recalibrados en la instalación.
                                    <br />
                                    Sencillas operaciones de verificación y mantenimiento por un sólo operario.
                                    <br />
                                    Resistente a los vapores de silicona (HDMS).
                                    <br />
                                    Disponibles también en formato RS485C.
                                    <br />
                                    Calibrados en fábrica con gas patrón. No necesitan ser recalibrados en la puesta en marcha inicial de la instalación.                          
                                

                                </p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12'>

                            <div className='contenedorNavDurtex'>
                                <Nav as="ul" className="flex-column">
                                    <Nav.Item className='linksArchivosDurtex' as="li" onClick={() => clicEnlacePdf('fichaHc')}>
                                        {logoPdf}
                                        Ficha Detector Durtex HC
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosDurtex' as="li" onClick={() => clicEnlacePdf('fichaHcPro')}>
                                        {logoPdf}
                                        Ficha Detector Durtex HC Pro
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosDurtex' as="li" onClick={() => clicEnlacePdf('fichaXHcPro')}>
                                        {logoPdf}
                                        Ficha Detector Durtex X HC Pro
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosDurtex' as="li" onClick={() => clicEnlacePdf('fichaCaja')}>
                                        {logoPdf}
                                        Ficha Caja de Derivación Atex
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosDurtex' as="li" onClick={() => clicEnlacePdf('manual')}>
                                        {logoPdf}
                                        Manual Detector Durtex
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosDurtex' as="li" onClick={() => clicEnlacePdf('presentacion')}>
                                        {logoPdf}
                                        Presentación Detector Durtex
                                    </Nav.Item>

                                </Nav>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <Modal className='imgModalContainer' show={fotoSeleccionada !== null} onHide={() => setFotoSeleccionada(null)}>
                <Modal.Header closeButton>
                    {subtituloModal && <Modal.Title>{subtituloModal}</Modal.Title>}
                </Modal.Header>
                <Modal.Body>
                    {fotoSeleccionada && (<img src={fotoSeleccionada} alt="Foto Durtex" className="imgModal" />)}
                </Modal.Body>
            </Modal>

        </>
    );

}

