import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Row from 'react-bootstrap/Row'; import { MenuLateral } from '../MenuLateral/MenuLateral'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'; // Importa el icono faBars
import Nav from 'react-bootstrap/Nav';
import { fichaPCM20t, fichaPS30t } from '../../Archivos/proyectoresNoCert';
import './ProyectoresNoCert.css';

export function ProyectoresNoCert() {

    const [fotoSeleccionada, setFotoSeleccionada] = useState(null);
    const [subtituloModal, setSubtituloModal] = useState("");

    const modalFoto = (foto, subtitulo) => {
        setFotoSeleccionada(foto);
        setSubtituloModal(subtitulo)
    };

    const logoPdf = <FontAwesomeIcon icon={faFilePdf} size="xl" style={{ color: "#006c87", }} />

    const abrirPdf = (archivo) => {
        window.open(archivo, '_blank');
    };

    const archivosPDF = {
        fichaPCM20t: fichaPCM20t,
        fichaPS30t: fichaPS30t
    };

    const clicEnlacePdf = (tipoArchivo) => {
        abrirPdf(archivosPDF[tipoArchivo]);
    };

    return (
        <>
            <div className="mainContainer">
                <MenuLateral />

                <div className='contenedorProducto'>
                    <div className='row'>
                        <div className='col-xl-3 col-lg-3 col-md-12 col-sm-12'>
                            <div className='imagenesProyectoresNoCert'>

                                <Row>
                                    <div className='contenedorFoto' >
                                        <img className="imagenProductoProyectoresNoCert"
                                            src={require("../Img/pcm-20t.webp")}
                                            
                                            onClick={() => modalFoto(require("../Img/pcm-20t.webp"), 'PCM-20T')}
                                            alt='PCM-20T' />
                                    </div>
                                </Row>
                                <Row>
                                    <div className='contenedorFoto' >
                                        <img className="imagenProductoProyectoresNoCert"
                                            src={require("../Img/ps-30t.webp")}
                                            
                                            onClick={() => modalFoto(require("../Img/ps-30t.webp"), 'PS-30T')}
                                            alt='PS-30T' />
                                    </div>
                                </Row>

                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                            <div className='descripcionProyectoresNoCert'>
                                <h2 className='nombreProductoProyectoresNoCert'>Proyectores de sonido</h2>
                                <h5 className='tituloProductoProyectoresNoCert'>El proyector PCM-20T es un altavoz con características antivandálicas y
                                    de intemperie, que integra un altavoz coaxial, full-range de 5”.
                                </h5>
                                <p className='textoProductoProyectoresNoCert'>
                                    Gracias al diseño de su envolvente de aluminio de 3mm de espesor y su rejilla de acero, es óptimo para su instalación
                                    en recintos donde la durabilidad de los elementos es un factor a tener en cuenta.
                                    <br /><br />
                                    Cuenta con transformador con diferentes tomas de potencia para su conexión a líneas de 70 y 100V, que le permiten
                                    adaptarse a los niveles de presión sonora deseados.

                                </p>

                                <h5 className='tituloProductoProyectoresNoCert'> El PS-30T es una bocina diseñada para líneas de 70V y 100V preparada para
                                    su instalación en grandes superficies debido a su gran sensibilidad y directividad
                                </h5>
                                <p className='textoProductoProyectoresNoCert'>
                                    Está construido en ABS con protección UV y acero inoxidable recubierto de resina epoxi. El selector de potencias que
                                    incorpora permite seleccionar la potencia en un rango de 30/15/7.5/3.75W @70V/100V o para baja impedancia (8Ohms).
                                    <br /><br />
                                    Las características del sistema permiten cubrir las necesidades auditivas de espacios de tamaño mediano tales como 
                                    estaciones de tren, estadios deportivos, parkings o piscinas ya sean cubiertas o al aire libre.

                                </p>

                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12'>

                            <div className='contenedorNavProyectoresNoCert'>
                                <Nav as="ul" className="flex-column">
                                    <Nav.Item className='linksArchivosProyectoresNoCert' as="li" onClick={() => clicEnlacePdf('fichaPCM20t')}>
                                        {logoPdf}
                                        Ficha PCM-20T
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosProyectoresNoCert' as="li" onClick={() => clicEnlacePdf('fichaPS30t')}>
                                        {logoPdf}
                                        Ficha PS-30T
                                    </Nav.Item>

                                </Nav>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <Modal className='imgModalContainer' show={fotoSeleccionada !== null} onHide={() => setFotoSeleccionada(null)}>
                <Modal.Header closeButton>
                    {subtituloModal && <Modal.Title>{subtituloModal}</Modal.Title>}
                </Modal.Header>
                <Modal.Body>
                    {fotoSeleccionada && (<img src={fotoSeleccionada} alt="Foto proyectores de sonido no certificados" className="imgModal" />)}
                </Modal.Body>
            </Modal>

        </>
    );

}

