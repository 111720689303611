import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Row from 'react-bootstrap/Row'; import { MenuLateral } from '../MenuLateral/MenuLateral'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'; // Importa el icono faBars
import Nav from 'react-bootstrap/Nav';
import { fichaNeo4250, manualNeo4250, certificado } from '../../Archivos/neo4250';
import './Neo4250.css';

export function Neo4250() {

    const [fotoSeleccionada, setFotoSeleccionada] = useState(null);
    const [subtituloModal, setSubtituloModal] = useState("");

    const modalFoto = (foto, subtitulo) => {
        setFotoSeleccionada(foto);
        setSubtituloModal(subtitulo)
    };

    const logoPdf = <FontAwesomeIcon icon={faFilePdf} size="xl" style={{ color: "#006c87", }} />

    const abrirPdf = (archivo) => {
        window.open(archivo, '_blank');
    };

    const archivosPDF = {
        ficha: fichaNeo4250,
        manual: manualNeo4250,
        certificado: certificado

    };

    const clicEnlacePdf = (tipoArchivo) => {
        abrirPdf(archivosPDF[tipoArchivo]);
    };

    return (
        <>
            <div className="mainContainer">
                <MenuLateral />

                <div className='contenedorProducto'>
                    <div className='row'>
                        <div className='col-xl-3 col-lg-3 col-md-12 col-sm-12'>
                            <div className='imagenesNeo4250'>

                                <Row>
                                    <div className='contenedorFoto' >
                                        <img className="imagenProductoNeo4250"
                                            src={require("../Img/neoExtension4250.webp")}
                                            
                                            onClick={() => modalFoto(require("../Img/neoExtension4250.webp"), 'NEO Extensión 4250')}
                                            alt='NEO Extensión 4250' />
                                    </div>
                                </Row>

                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                            <div className='descripcionNeo4250'>
                                <h2 className='nombreProductoNeo4250'>NEO Extensión 4250</h2>
                                <h5 className='tituloProductoNeo4250'>LDA NEO-Extension 4250 es un controlador de extensión y un
                                    amplificador de 4 canales de 250W @100V para el sistema compacto NEO EN 54-16 de megafonía y evacuación
                                    por voz.
                                </h5>
                                <p className='textoProductoNeo4250'>
                                    El audio y el control se transmite desde la unidad principal hasta esta unidad de extensión con cable UTP,
                                    a través de una conexión en cascada mediante protocolo FlexNet, garantizando la máxima calidad e integridad
                                    de la señal.
                                    <br />
                                    La extensión es configurable con el software NEO-Configurator(incluido en NEO). El software, permite
                                    configurar el equipo de forma remota y crear eventos para realizar acciones en el sistema archivados por
                                    condiciones de: nivel de entrada, fecha y hora, comando UDP, GPIO o botón del micrófono MPS.
                                    <br /><br />
                                    Características Generales:
                                    <br />
                                    - Certificado EN 54-16.
                                    <br />
                                    - 4 amplificadores de clase D de 250W o 2 amplificadores de 500W en líneas de 100V/70V.
                                    <br />
                                    - 4 salidas de audio preamplificadas para la conexión de amplificadores de megafonía externos.
                                    <br />
                                    - DSP: Ecualizador paramétrico de 7 bandas entrada/salida, loudness, potenciador de sonido LDA,
                                    controles de volumen independientes por cada canal E/S y filtros de audio.
                                    <br />
                                    - Triple puerto Ethernet: para Audio Digital Cobranet, control remoto y supervisión, y para 
                                    escalar el sistema con NEO-Extension en modo daisy-chain con tecnología FlexNet (hasta 1024 zonas).
                                    <br />
                                    - Control para hasta 16 atenuadores LDA AT6.
                                    <br />
                                    - 6 cierres de contacto supervisados con el panel central de alarma de incendios.
                                    
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12'>

                            <div className='contenedorNavNeo4250'>
                                <Nav as="ul" className="flex-column">
                                    <Nav.Item className='linksArchivosNeo4250' as="li" onClick={() => clicEnlacePdf('ficha')}>
                                        {logoPdf}
                                        Ficha NEO Extensión 4250
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosNeo4250' as="li" onClick={() => clicEnlacePdf('manual')}>
                                        {logoPdf}
                                        Manual Neo Extensión 4250
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosNeo4250' as="li" onClick={() => clicEnlacePdf('certificado')}>
                                        {logoPdf}
                                        Certificado en 54-16
                                    </Nav.Item>

                                </Nav>
                            </div>
                        </div>
                    </div>

                </div>

            </div >

            <Modal className='imgModalContainer' show={fotoSeleccionada !== null} onHide={() => setFotoSeleccionada(null)}>
                <Modal.Header closeButton>
                    {subtituloModal && <Modal.Title>{subtituloModal}</Modal.Title>}
                </Modal.Header>
                <Modal.Body>
                    {fotoSeleccionada && (<img src={fotoSeleccionada} alt="Foto NEO Extensión 4250" className="imgModal" />)}
                </Modal.Body>
            </Modal>

        </>
    );

}

