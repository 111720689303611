import { MenuLateral } from '../MenuLateral/MenuLateral'
import './PuestaEnMarcha.css';

export function PuestaEnMarcha() {

    const tab = <>&nbsp;&nbsp;&nbsp;&nbsp;</>;

    return (
        <>
            <div className="contenedorGeneral">
                <MenuLateral />
                <div className="container">
                    <div className="puestaEnMarcha">
                        <div >
                            <div className='row'>
                                <div className='col-12'>
                                    <h1 className='nombrePuestaEnMarcha'>Puesta En Marcha</h1>
                                </div>
                            </div>
                        </div>

                        <div >

                            <p className='textoPuestaEnMarcha' fetchpriority='high'>{tab} En DTGAS, nos enorgullece ofrecer servicios especializados en la puesta en marcha de sistemas de detección
                                de gases, asegurando que sus instalaciones cuenten con tecnología de vanguardia para la identificación temprana y eficaz de
                                posibles riesgos. Con un equipo comprometido con la excelencia, trabajamos incansablemente para garantizar la seguridad y
                                tranquilidad de nuestros clientes.</p>
                            <p className='textoPuestaEnMarcha'>{tab} Llevamos a cabo la inspección meticulosa de la instalación de sistemas de detección de gases, garantizando
                                que cada componente esté correctamente posicionado y conectado para lograr una cobertura efectiva.</p>
                            <p className='textoPuestaEnMarcha'>{tab} Adaptamos la configuración de los sistemas a las necesidades específicas de su entorno. Desde la sensibilidad
                                de los sensores hasta la integración con otros sistemas de seguridad, nos aseguramos de que cada detalle esté ajustado para un
                                rendimiento óptimo.</p>
                            <p className='textoPuestaEnMarcha'>{tab} Nuestros profesionales realizan la puesta en marcha de los sistemas con eficiencia y precisión. Nos
                                aseguramos de que cada componente funcione según las especificaciones del fabricante y de acuerdo con las normativas aplicables.</p>
                            <p className='textoPuestaEnMarcha'>{tab} No solo implementamos sistemas, sino que también proporcionamos formación integral para los usuarios.
                                Queremos asegurarnos de que su equipo esté completamente capacitado para operar y comprender el funcionamiento de nuestros sistemas 
                                de detección de gases.</p>                            
                        </div>
                    </div>

                </div>
            </div>
        </>
    );

}