import React from 'react';
import { MenuLateral } from '../MenuLateral/MenuLateral'
import { useNavigate } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import './AccesoriosNeoYOne.css';

export function AccesoriosNeoYOne() {

    const navigate = useNavigate();

    const irAMps = () => {
        navigate(`/mps`);
    };

    const irASelector = () => {
        navigate(`/selectorCanalYVolumen`);
    };

    const irAPanelDeAlarma = () => {
        navigate(`/panelDeAlarma`);
    };

    const irAOtros = () => {
        navigate(`/otrosAccesorios`);
    };

    return (
        <>
            <div className="mainContainer">
                <MenuLateral />
                <div className='contenedor'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='tituloAccesoriosNeoYOne'>
                                <h1>Accesorios Neo y One</h1>
                            </div>

                        </div>

                    </div>

                    <div className='row'>
                        <div className='contenedorCardsAccesoriosNeoYOne'>
                            <div className='row'>
                                <div className='col-xl-6 col-lg-6 col-md-12'>
                                    <Card>
                                        <Card.Img variant="MPS" src={require('../Img/MPS-8Z.webp')} onClick={irAMps} style={{ cursor: 'pointer' }} alt='MPS-8Z y MPS-8K' fetchpriority='high' />
                                        <Card.Body>
                                            <Card.Title>MPS-8Z y MPS-8K</Card.Title>

                                        </Card.Body>

                                    </Card>
                                </div>
                                <div className='col-xl-6 col-lg-6 col-md-12'>
                                    <Card>
                                        <Card.Img variant="selector" src={require('../Img/vcc64.webp')} onClick={irASelector} style={{ cursor: 'pointer' }} alt='Selector de canal y volúmen VCC-64' fetchpriority='high' />
                                        <Card.Body>
                                            <Card.Title>Selector de canal y volúmen VCC-64</Card.Title>

                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-xl-6 col-lg-6 col-md-12'>
                                    <Card>
                                        <Card.Img variant="alarma" src={require('../Img/vap1.webp')} onClick={irAPanelDeAlarma} style={{ cursor: 'pointer' }} alt='Panel de alarma VAP-1 y Extensión VAP 8K' fetchpriority='high' />
                                        <Card.Body>
                                            <Card.Title>Panel de alarma VAP-1 y Extensión VAP 8K</Card.Title>

                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className='col-xl-6 col-lg-6 col-md-12'>
                                    <Card>
                                        <Card.Img variant="otros" src={require('../Img/zes22.webp')} onClick={irAOtros} style={{ cursor: 'pointer' }} alt='Otros accesorios' fetchpriority='high' />
                                        <Card.Body>
                                            <Card.Title>Otros Accesorios</Card.Title>

                                        </Card.Body>
                                    </Card>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

