import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Row from 'react-bootstrap/Row'; import { MenuLateral } from '../MenuLateral/MenuLateral'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'; // Importa el icono faBars
import Nav from 'react-bootstrap/Nav';
import { fichaOpacimetro, manualOpacimetro, presentacionOpacimetro } from '../../Archivos/opacimetro';
import './Opacimetro.css';

export function Opacimetro() {

    const [fotoSeleccionada, setFotoSeleccionada] = useState(null);
    const [subtituloModal, setSubtituloModal] = useState("");

    const modalFoto = (foto, subtitulo) => {
        setFotoSeleccionada(foto);
        setSubtituloModal(subtitulo)
    };

    const logoPdf = <FontAwesomeIcon icon={faFilePdf} size="xl" style={{ color: "#006c87", }} />

    const abrirPdf = (archivo) => {
        window.open(archivo, '_blank');
    };

    const archivosPDF = {
        ficha: fichaOpacimetro,
        manual: manualOpacimetro,
        presentacion: presentacionOpacimetro
    };

    const clicEnlacePdf = (tipoArchivo) => {
        abrirPdf(archivosPDF[tipoArchivo]);
    };

    return (
        <>
            <div className="mainContainer">
                <MenuLateral />

                <div className='contenedorProducto'>
                    <div className='row'>
                        <div className='col-xl-3 col-lg-3 col-md-12 col-sm-12'>
                            <div className='imagenesOpacimetro'>

                                <Row>
                                    <div className='contenedorFoto' >
                                        <img className="imagenProductoOpacimetro"
                                            src={require("../Img/opacimetro.webp")}
                                            
                                            onClick={() => modalFoto(require("../Img/opacimetro.webp"), 'Opacímetro Sentry')}
                                            alt='Opacímetro Sentry' />
                                    </div>
                                </Row>

                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                            <div className='descripcionOpacimetro'>
                                <h2 className='nombreProductoOpacimetro'>Opacímetro Sentry</h2>
                                <h5 className='tituloProductoOpacimetro'>SENTRY es un sensor electro-óptico cuya función es medir la opacidad en
                                    el aire a través de las partículas existentes (partículas en suspensión, emisiones de gases, etc.).</h5>
                                <p className='textoProductoOpacimetro'>
                                    Utiliza el principio de dispersión frontal, captando una muestra de luz en un ángulo de 42º. La amplitud de este
                                    ángulo permite detectar partí­culas de gran tamaño.
                                    <br />
                                    Las ventajas de SENTRY frente a otros opací­metros son:
                                    <ol className='listadoOpacimetro'>
                                        <li>No necesita ser calibrado en la instalación.</li>
                                        <li>Las vibraciones externas no afectan a la calibración del sensor.</li>
                                        <li>Utiliza el principio de dispersión frontal, frente a otros opacímetros, que utilizan tecnologías menos
                                            efectivas, como la retrodispersión.</li>
                                    </ol>
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12'>

                            <div className='contenedorNavOpacimetro'>
                                <Nav as="ul" className="flex-column">
                                    <Nav.Item className='linksArchivosOpacimetro' as="li" onClick={() => clicEnlacePdf('ficha')}>
                                        {logoPdf}
                                        Ficha Opacimetro Sentry
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosOpacimetro' as="li" onClick={() => clicEnlacePdf('manual')}>
                                        {logoPdf}
                                        Manual Opacimetro Sentry
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosOpacimetro' as="li" onClick={() => clicEnlacePdf('presentacion')}>
                                        {logoPdf}
                                        Presentación Opacimetro Sentry
                                    </Nav.Item>

                                </Nav>
                            </div>
                        </div>
                    </div>

                </div>

            </div >

            <Modal className='imgModalContainer' show={fotoSeleccionada !== null} onHide={() => setFotoSeleccionada(null)}>
                <Modal.Header closeButton>
                    {subtituloModal && <Modal.Title>{subtituloModal}</Modal.Title>}
                </Modal.Header>
                <Modal.Body>
                    {fotoSeleccionada && (<img src={fotoSeleccionada} alt="Foto Opacimetro" className="imgModal" />)}
                </Modal.Body>
            </Modal>

        </>
    );

}

