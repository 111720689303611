import React, { useState } from 'react';

export function MenuLateralState() {
    const [state, setState] = useState({
        menuState: {
            estaAbierto: true,
        },
        subMenuAbierto: '',
        subSubMenuAbierto: '',
        subSubSubMenuAbierto: '',
        productosMenuAbierto: '',
    });

    const handleToggleMenu = () => {
        setState((prevState) => ({
            ...prevState,
            menuState: {
                ...prevState.menuState,
                estaAbierto: !prevState.menuState.estaAbierto,
            },
        }));
    };

    const handleSubMenuChange = (texto) => {
        setState((prevState) => ({
            ...prevState,
            subMenuAbierto: prevState.subMenuAbierto === texto ? '' : texto,
        }));
    };

    const handleSubSubMenuChange = (texto) => {
        setState((prevState) => ({
            ...prevState,
            subSubMenuAbierto: prevState.subSubMenuAbierto === texto ? '' : texto,
        }));
    };
    
    const handleSubSubSubMenuChange = (texto) => {
        setState((prevState) => ({
            ...prevState,
            subSubSubMenuAbierto: prevState.subSubSubMenuAbierto === texto ? '' : texto,
        }));
    };
    
    const handleProductosChange = (texto) => {
        setState((prevState) => ({
            ...prevState,
            productosMenuAbierto: prevState.productosMenuAbierto === texto ? '' : texto,
        }));
    };


    return {
        menuState: state.menuState,
        subMenuAbierto: state.subMenuAbierto,
        subSubMenuAbierto: state.subSubMenuAbierto,
        subSubSubMenuAbierto: state.subSubSubMenuAbierto,
        productosMenuAbierto: state.productosMenuAbierto,
        handleToggleMenu,
        handleSubMenuChange,
        handleSubSubMenuChange,
        handleSubSubSubMenuChange,
        handleProductosChange
    };
}