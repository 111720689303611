import React from 'react';
import { MenuLateral } from '../MenuLateral/MenuLateral'
import { useNavigate } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import './Anemometros.css';

export function Anemometros() {

    const navigate = useNavigate();

    const irAHelicesYConversor = () => {
        navigate(`/helicesYConversor`);
    };

    const irAUltrasonico = () => {
        navigate(`/ultrasonico`);
    };

    return (
        <>
            <div className="mainContainer">
                <MenuLateral />

                <div className='contenedor'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='tituloDeAnemometros'>
                                <h1>Anemómetros</h1>
                            </div>

                        </div>

                    </div>

                    <div className='row'>

                        <div className='contenedorCardsAnemometros'>
                            <div className='row'>
                                <div className='col-xl-6 col-lg-6 col-md-12'>
                                    <Card>
                                        <Card.Img variant="helices" src={require('../Img/helices.webp')} onClick={irAHelicesYConversor} style={{ cursor: 'pointer' }} alt='Hélices y conversor' fetchpriority='high' />
                                        <Card.Body>
                                            <Card.Title className='tituloHelices'>Hélices y conversor</Card.Title>

                                        </Card.Body>

                                    </Card>
                                </div>
                                <div className='col-xl-6 col-lg-6 col-md-12'>
                                    <Card>
                                        <Card.Img variant="ultrasonico" src={require('../Img/ultrasonico.webp')} onClick={irAUltrasonico} style={{ cursor: 'pointer' }} alt='Ultrasónico' fetchpriority='high' />
                                        <Card.Body>
                                            <Card.Title className='tituloUltrasonico'>Ultrasónico</Card.Title>

                                        </Card.Body>
                                    </Card>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

