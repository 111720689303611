import React from 'react';
import { MenuLateral } from '../MenuLateral/MenuLateral'
import { useNavigate } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import './SensoresAtmosfericos.css';

export function SensoresAtmosfericos() {

    const navigate = useNavigate();

    const irAVisibilidad = () => {
        navigate(`/sensorVisibilidad`);
    };

    const irAPluviometro = () => {
        navigate(`/pluviometro`);
    };

    const irASensorHumedad = () => {
        navigate(`/sensorHumedad`);
    };

    const irABarometro = () => {
        navigate(`/barometro`);
    };

    const irAPiranometro = () => {
        navigate(`/piranometro`);
    };

    const irASensorHielo = () => {
        navigate(`/sensorHielo`);
    };

    return (
        <>
            <div className="mainContainer">
                <MenuLateral />

                <div className='contenedor'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='tituloAtmosfericos'>
                                <h1>Sensores atmosféricos</h1>
                            </div>

                        </div>

                    </div>

                    <div className='row'>

                        <div className='contenedorCardsSensoresAtmosfericos'>
                            <div className='row'>
                                <div className='col-xxl-4 col-xl-6 col-lg-6 col-md-12'>
                                    <Card>
                                        <Card.Img variant="visibilidad" src={require('../Img/visibilidad.webp')} onClick={irAVisibilidad} style={{ cursor: 'pointer' }} alt='Sensor de visibilidad y tiempo presente' fetchpriority='high' />
                                        <Card.Body>
                                            <Card.Title className='tituloSensoresAtmosfericos'>Sensor de visibilidad y tiempo presente</Card.Title>

                                        </Card.Body>

                                    </Card>
                                </div>
                                <div className='col-xxl-4 col-xl-6 col-lg-6 col-md-12'>
                                    <Card>
                                        <Card.Img variant="pluviometro" src={require('../Img/pluviometro.webp')} onClick={irAPluviometro} style={{ cursor: 'pointer' }} alt='Pluviómetro' fetchpriority='high' />
                                        <Card.Body>
                                            <Card.Title className='tituloSensoresAtmosfericos'>Pluviómetro</Card.Title>

                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className='col-xxl-4 col-xl-6 col-lg-6 col-md-12'>
                                    <Card>
                                        <Card.Img variant="humedad" src={require('../Img/humedad.webp')} onClick={irASensorHumedad} style={{ cursor: 'pointer' }} alt='Sensor de humedad y temperatura' fetchpriority='high' />
                                        <Card.Body>
                                            <Card.Title className='tituloSensoresAtmosfericos'>Sensor de humedad y temperatura</Card.Title>

                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className='col-xxl-4 col-xl-6 col-lg-6 col-md-12'>
                                    <Card>
                                        <Card.Img variant="barometro" src={require('../Img/barometro.webp')} onClick={irABarometro} style={{ cursor: 'pointer' }} alt='Barómetro' fetchpriority='high' />
                                        <Card.Body>
                                            <Card.Title className='tituloSensoresAtmosfericos'>Barómetro</Card.Title>

                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className='col-xxl-4 col-xl-6 col-lg-6 col-md-12'>
                                    <Card>
                                        <Card.Img variant="piranometro" src={require('../Img/piranometro.webp')} onClick={irAPiranometro} style={{ cursor: 'pointer' }} alt='Piranómetro' fetchpriority='high' />
                                        <Card.Body>
                                            <Card.Title className='tituloSensoresAtmosfericos'>Piranómetro</Card.Title>

                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className='col-xxl-4 col-xl-6 col-lg-6 col-md-12'>
                                    <Card>
                                        <Card.Img variant="hielo" src={require('../Img/hielo.webp')} onClick={irASensorHielo} style={{ cursor: 'pointer' }} alt='Sensor de hielo en calzada' fetchpriority='high' />
                                        <Card.Body>
                                            <Card.Title className='tituloSensoresAtmosfericos'>Sensor de hielo en calzada</Card.Title>

                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

