import React from 'react';
import { MenuLateral } from '../MenuLateral/MenuLateral'
import { useNavigate } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import './NoCertificados.css';

export function NoCertificados() {

    const navigate = useNavigate();

    const irATechoNoCert = () => {
        navigate(`/altavocesDeTechoOSuperficieNoCertificados`);
    };

    const irACajasAcusticas = () => {
        navigate(`/cajasAcusticas`);
    };

    const irAProyectoresNoCert = () => {
        navigate(`/proyectoresDeSonidoNoCertificados`);
    };

    const irAColumnasNoCert = () => {
        navigate(`/columnasNoCertificadas`);
    };

    const irAOtrosNoCert = () => {
        navigate(`/otrosNoCertificados`);
    };

    return (
        <>
            <div className="mainContainer">
                <MenuLateral />

                <div className='contenedor'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='tituloNoCertificados'>
                                <h1>No certificados</h1>
                            </div>

                        </div>

                    </div>

                    <div className='row'>

                        <div className='contenedorCardsNoCertificados'>
                            <div className='row'>
                                <div className='col-xl-4 col-lg-6 col-md-12'>
                                    <Card>
                                        <Card.Img variant="techoNoCert" src={require('../Img/sc-32t.webp')} onClick={irATechoNoCert} style={{ cursor: 'pointer' }} alt='Altavoces de techo o superficie' fetchpriority='high' />
                                        <Card.Body>
                                            <Card.Title className='tituloTechoNoCert'>Altavoces de techo o superficie</Card.Title>

                                        </Card.Body>

                                    </Card>
                                </div>
                                <div className='col-xl-4 col-lg-6 col-md-12'>
                                    <Card>
                                        <Card.Img variant="cajas" src={require('../Img/ls-51t.webp')} onClick={irACajasAcusticas} style={{ cursor: 'pointer' }} alt='Cajas acústicas' fetchpriority='high' />
                                        <Card.Body>
                                            <Card.Title className='tituloCajas'>Cajas acústicas</Card.Title>

                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className='col-xl-4 col-lg-6 col-md-12'>
                                    <Card>
                                        <Card.Img variant="proyectoresNoCert" src={require('../Img/pcm-20t.webp')} onClick={irAProyectoresNoCert} style={{ cursor: 'pointer' }} alt='Proyectores de sonido' fetchpriority='high' />
                                        <Card.Body>
                                            <Card.Title className='tituloProyectoresNoCert'>Proyectores de sonido</Card.Title>

                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className='col-xl-4 col-lg-6 col-md-12'>
                                    <Card>
                                        <Card.Img variant="columnasNoCert" src={require('../Img/ci-625t.webp')} onClick={irAColumnasNoCert} style={{ cursor: 'pointer' }} alt='Columnas' fetchpriority='high' />
                                        <Card.Body>
                                            <Card.Title className='tituloColumnasNoCert'>Columnas</Card.Title>

                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className='col-xl-4 col-lg-6 col-md-12'>
                                    <Card>
                                        <Card.Img variant="otrosNoCert" src={require('../Img/sw100t.webp')} onClick={irAOtrosNoCert} style={{ cursor: 'pointer' }} alt='Otros' fetchpriority='high' />
                                        <Card.Body>
                                            <Card.Title className='tituloOtrosNoCert'>Otros</Card.Title>

                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

