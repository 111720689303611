import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Row from 'react-bootstrap/Row'; 
import { MenuLateral } from '../MenuLateral/MenuLateral'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'; // Importa el icono faBars
import Nav from 'react-bootstrap/Nav';
import { fichaCable, fichaCable218, manualCable, presentacionCable, guiaCable, tablaCable } from '../../Archivos/cableDeteccionLineal';
import './CableDeteccionLineal.css';

export function CableDeteccionLineal() {

    const [fotoSeleccionada, setFotoSeleccionada] = useState(null);
    const [subtituloModal, setSubtituloModal] = useState("");

    const modalFoto = (foto, subtitulo) => {
        setFotoSeleccionada(foto);
        setSubtituloModal(subtitulo)
    };

    const logoPdf = <FontAwesomeIcon icon={faFilePdf} size="xl" style={{ color: "#006c87", }} />

    const abrirPdf = (archivo) => {
        window.open(archivo, '_blank');
    };

    const archivosPDF = {
        ficha: fichaCable,
        ficha218: fichaCable218,
        manual: manualCable,
        presentacion: presentacionCable,
        guia: guiaCable,
        tabla: tablaCable
    };

    const clicEnlacePdf = (tipoArchivo) => {
        abrirPdf(archivosPDF[tipoArchivo]);
    };

    return (
        <>
            <div className="mainContainer">
                <MenuLateral />

                <div className='contenedorProducto'>
                    <div className='row'>
                        <div className='col-xl-3 col-lg-3 col-md-12 col-sm-12'>
                            <div className='imagenesCable'>

                                <Row>
                                    <div className='contenedorFoto' >
                                        <img className="imagenProductoCable"
                                            src={require("../Img/cable1.webp")}
                                            
                                            onClick={() => modalFoto(require("../Img/cable1.webp"), 'Cable térmico')}
                                            alt='Cable térmico' />
                                    </div>
                                </Row>
                                <Row>
                                    <div className='contenedorFoto' >
                                        <img className="imagenProductoCable"
                                            src={require("../Img/cable2.webp")}
                                            
                                            onClick={() => modalFoto(require("../Img/cable2.webp"), 'Localizadora')}
                                            alt='Localizadora' />
                                    </div>
                                </Row>
                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                            <div className='descripcionCable'>
                                <h2 className='nombreProductoCable'>Cable de detección lineal</h2>
                                <p className='textoProductoCable'>
                                    El cable térmico CDL es un sistema de detección que, conectado a una central de incendios, puede detectar una subida de
                                    temperatura en cualquier punto de toda su longitud. Consta de dos conductores de acero trenzados cubiertos por una capa
                                    de polímero sensible al calor que, al alcanzar la temperatura prefijada, se rompe permitiendo el contacto de los
                                    conductores para producir una alarma.
                                    <br />
                                    Puede ser conectado a cualquier tipo de central convencional o analógica, usando un módulo convencional. El cable térmico
                                    CDL se puede conectar a la localizadora DL para identificar el punto exacto de alarma a lo largo de toda la longitud del
                                    cable. Hasta 3.000 metros.
                                    <br />
                                    Existen 5 tipos distintos de cable térmico CDL en función de la temperatura de alarma elegida: 68°C, 78°C, 88°C, 105°C
                                    y 235°C.
                                    <br />
                                    Los cuatro primeros pueden llevar distinta cubierta exterior según la aplicación:
                                </p>
                                <ul className='listadoCable'>
                                    <li>Estándar: Para cualquier tipo de instalaciones. Está protegido contra rayos UV, por lo tanto puede ser instalado
                                        en exteriores.</li>
                                    <li>Polipropileno: Especialmente diseñado para instalaciones con sustancias químicas corrosivas en el ambiente.</li>
                                    <li>Nylon: Recubierto de Nylon, ha sido especialmente diseñado para instalaciones con riesgo de daño mecánico.</li>
                                </ul>
                                <p className='textoProductoCable'>
                                    El cable de 235ºC solo está disponible con cubierta de goma de silicona.
                                    <br />
                                    Además disponemos de fiadores de cable y la posibilidad de que el cable lleve el fiador incorporado de fábrica.
                                    <br />
                                    Certificado UL y FM.
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12'>

                            <div className='contenedorNavCable'>
                                <Nav as="ul" className="flex-column">
                                    <Nav.Item className='linksArchivosCable' as="li" onClick={() => clicEnlacePdf('ficha')}>
                                        {logoPdf}
                                        Ficha Cable
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosCable' as="li" onClick={() => clicEnlacePdf('ficha218')}>
                                        {logoPdf}
                                        Ficha Cable 218 o 235???
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosCable' as="li" onClick={() => clicEnlacePdf('manual')}>
                                        {logoPdf}
                                        Manual
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosCable' as="li" onClick={() => clicEnlacePdf('presentacion')}>
                                        {logoPdf}
                                        Presentación
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosCable' as="li" onClick={() => clicEnlacePdf('guia')}>
                                        {logoPdf}
                                        Guía
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosCable' as="li" onClick={() => clicEnlacePdf('tabla')}>
                                        {logoPdf}
                                        Tabla de Resistencia Química
                                    </Nav.Item>
                                </Nav>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <Modal className='imgModalContainer' show={fotoSeleccionada !== null} onHide={() => setFotoSeleccionada(null)}>
                <Modal.Header closeButton>
                    {subtituloModal && <Modal.Title>{subtituloModal}</Modal.Title>}
                </Modal.Header>
                <Modal.Body>
                    {fotoSeleccionada && (<img src={fotoSeleccionada} alt="Foto Cable de Detección Lineal" className="imgModal" />)}
                </Modal.Body>
            </Modal>

        </>
    );

}

