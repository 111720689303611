import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Row from 'react-bootstrap/Row'; import { MenuLateral } from '../MenuLateral/MenuLateral'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'; // Importa el icono faBars
import Nav from 'react-bootstrap/Nav';
import { fichaTecnicaCentralDurgas, manualCentralDurgas, presentacionCentralDurgas, montajeCentralDurgas, graficoCompatibilidadCentralDurgas } from '../../Archivos/centralDurgas';
import './CentralDurgas.css';

export function CentralDurgas() {

    const [fotoSeleccionada, setFotoSeleccionada] = useState(null);
    const [subtituloModal, setSubtituloModal] = useState("");

    const modalFoto = (foto, subtitulo) => {
        setFotoSeleccionada(foto);
        setSubtituloModal(subtitulo)
    };

    const logoPdf = <FontAwesomeIcon icon={faFilePdf} size="xl" style={{ color: "#006c87", }} />
    // const urlArchivos = '../../Archivos/centralDurgas/fichaTecnicaCentralDurgas.pdf'

    const abrirPdf = (archivo) => {
        window.open(archivo, '_blank');
    };

    const archivosPDF = {
        fichaTecnica: fichaTecnicaCentralDurgas,
        manual: manualCentralDurgas,
        presentacion: presentacionCentralDurgas,
        montaje: montajeCentralDurgas,
        compatibilidad: graficoCompatibilidadCentralDurgas,
    };

    const clicEnlacePdf = (tipoArchivo) => {
        abrirPdf(archivosPDF[tipoArchivo]);
    };

    return (
        <>
            <div className="mainContainer">
                <MenuLateral />

                <div className='contenedorProducto'>
                    <div className='row'>
                        <div className='col-xl-3 col-lg-3 col-md-12 col-sm-12'>
                            <div className='imagenesDurgas'>

                                <Row>
                                    <div className='contenedorFoto' >
                                        <img className="imagenProductoDurgas"
                                            src={require("../Img/CentralDurgas.webp")}
                                            
                                            onClick={() => modalFoto(require("../Img/CentralDurgas.webp"), 'Central Durgas')}
                                            alt='Central Durgas' />
                                    </div>
                                </Row>
                                <Row>
                                    <div className='contenedorFoto' >
                                        <img className="imagenProductoDurgas"
                                            src={require("../Img/CentralDurgas2.webp")}
                                            
                                            onClick={() => modalFoto(require("../Img/CentralDurgas2.webp"), 'Central Durgas Mini')}
                                            alt='Central Durgas Mini' />
                                    </div>
                                </Row>
                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                            <div className='descripcionDurgas'>
                                <h2 className='nombreProductoDurgas'>Central Durgas</h2>
                                <h5 className='tituloProductoDurgas'>Central para la detección de gases tóxicos, explosivos y oxígeno.</h5>
                                <p className='textoProductoDurgas'>
                                    Puede detectar hasta cuatro gases distintos (tóxicos, explosivos u Oxígeno) simultáneamente por zona, en la que
                                    también se pueden conectar módulos de relés remotos y módulos de conversión 4-20mA a RS485.
                                    <br />
                                    Detecta automáticamente la cantidad y tipo de detectores, así como el resto de dispositivos conectados a su lazo, autoconfigurando tipo de gas, niveles de ventilación y alarma.
                                    Idónea para aplicaciones industriales, aparcamientos subterráneos y túneles.
                                    <br />
                                    Es compatible con todos los detectores Eurosondelco, DURTEX, DIREX, DURTOX, SONDELTOX y los nuevos detectores DURPARK RS485.
                                    Programada según distintas Normativas e idiomas. Cada Zona incorpora un display LCD con funcionamiento independiente.
                                    <br />
                                    Numerosas Indicaciones ópticas y acústicas. Software flexible y sencillo de programar. Capacidad de maniobra excepcional.
                                    Disponible en versión MINI y en ampliable de 1 a 4 zonas. Capacidad de integración en un SCADA (versión ampliable).
                                </p>
                                <ul className='listadoDurgas'>
                                    <li>Conforme UNE 23-300-84.</li>
                                    <li>Certif. LOM 14MOGA3168.</li>
                                    <li>Homologación: CDM-0100010 (DURGAS) y CDM-0100011 (DURGAS Mini).</li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12'>

                            <div className='contenedorNavDurgas'>
                                <Nav as="ul" className="flex-column">
                                    <Nav.Item className='linksArchivosDurgas' as="li" onClick={() => clicEnlacePdf('fichaTecnica')}>
                                        {logoPdf}
                                        Ficha técnica
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosDurgas' as="li" onClick={() => clicEnlacePdf('manual')}>
                                        {logoPdf}
                                        Manual
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosDurgas' as="li" onClick={() => clicEnlacePdf('presentacion')}>
                                        {logoPdf}
                                        Presentación
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosDurgas' as="li" onClick={() => clicEnlacePdf('montaje')}>
                                        {logoPdf}
                                        Montaje
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosDurgas' as="li" onClick={() => clicEnlacePdf('compatibilidad')}>
                                        {logoPdf}
                                        Gráfico de Compatibilidad
                                    </Nav.Item>
                                </Nav>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <Modal className='imgModalContainer' show={fotoSeleccionada !== null} onHide={() => setFotoSeleccionada(null)}>
                <Modal.Header closeButton>
                    {subtituloModal && <Modal.Title>{subtituloModal}</Modal.Title>}
                </Modal.Header>
                <Modal.Body>
                    {fotoSeleccionada && (<img src={fotoSeleccionada} alt="Foto Central Durgas" className="imgModal" />)}
                </Modal.Body>
            </Modal>

        </>
    );

}

