import React from 'react';
import { MenuLateral } from '../MenuLateral/MenuLateral'
import { useNavigate } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import './Detectores4_20ma.css';

export function Detectores4_20ma() {

    const navigate = useNavigate();

    const irASondeltox = () => {
        navigate(`/sondeltox`);
    };

    const irADetectorDurtox = () => {
        navigate(`/detectorDurtox`);
    };

    const irADetectorDirex = () => {
        navigate(`/detectorDirex`);
    };

    return (
        <>
            <div className="mainContainer">
                <MenuLateral />

                <div className='contenedor'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='titulo4_20ma'>
                                <h1>Detectores 4_20mA</h1>
                            </div>

                        </div>

                    </div>

                    <div className='row'>

                        <div className='contenedorCards4_20'>
                            <div className='row'>
                                <div className='col-xl-4 col-lg-4 col-md-12'>
                                    <Card>
                                        <Card.Img variant="sondeltox" src={require('../Img/sondeltox.webp')} onClick={irASondeltox} style={{ cursor: 'pointer' }} alt='Sondeltox' fetchpriority='high' />
                                        <Card.Body>
                                            <Card.Title className='tituloSondeltox'>Sondeltox</Card.Title>

                                        </Card.Body>

                                    </Card>
                                </div>
                                <div className='col-xl-4 col-lg-4 col-md-12'>
                                    <Card>
                                        <Card.Img variant="durtox" src={require('../Img/durtox1.webp')} onClick={irADetectorDurtox} style={{ cursor: 'pointer' }} alt='Detector Durtox 4-20mA' fetchpriority='high' />
                                        <Card.Body>
                                            <Card.Title className='tituloDurtox'>Detector Durtox 4-20mA</Card.Title>

                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className='col-xl-4 col-lg-4 col-md-12'>
                                    <Card>
                                        <Card.Img variant="direx" src={require('../Img/direx1.webp')} onClick={irADetectorDirex} style={{ cursor: 'pointer' }} alt='Detector Direx 4-20mA' fetchpriority='high' />
                                        <Card.Body>
                                            <Card.Title className='tituloDirex'>Detector Direx 4-20mA</Card.Title>

                                        </Card.Body>
                                    </Card>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

