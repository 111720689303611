import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Row from 'react-bootstrap/Row'; import { MenuLateral } from '../MenuLateral/MenuLateral'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'; // Importa el icono faBars
import Nav from 'react-bootstrap/Nav';
import { fichaStandgasHc, manualStandgasHc, presentacionStandgasHc, folletoStandgasHc } from '../../Archivos/standgasHc';
import './StandgasHc.css';

export function StandgasHc() {

    const [fotoSeleccionada, setFotoSeleccionada] = useState(null);
    const [subtituloModal, setSubtituloModal] = useState("");

    const modalFoto = (foto, subtitulo) => {
        setFotoSeleccionada(foto);
        setSubtituloModal(subtitulo)
    };

    const logoPdf = <FontAwesomeIcon icon={faFilePdf} size="xl" style={{ color: "#006c87", }} />

    const abrirPdf = (archivo) => {
        window.open(archivo, '_blank');
    };

    const archivosPDF = {
        ficha: fichaStandgasHc,
        manual: manualStandgasHc,
        presentacion: presentacionStandgasHc,
        folleto: folletoStandgasHc
    };

    const clicEnlacePdf = (tipoArchivo) => {
        abrirPdf(archivosPDF[tipoArchivo]);
    };

    return (
        <>
            <div className="mainContainer">
                <MenuLateral />

                <div className='contenedorProducto'>
                    <div className='row'>
                        <div className='col-xl-3 col-lg-3 col-md-12 col-sm-12'>
                            <div className='imagenesStandgasHc'>

                                <Row>
                                    <div className='contenedorFoto' >
                                        <img className="imagenProductoStandgasHc"
                                            src={require("../Img/standgasHc.webp")}
                                            
                                            onClick={() => modalFoto(require("../Img/standgasHc.webp"), 'Standgas HC')}
                                            alt='Standgas HC' />
                                    </div>
                                </Row>

                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                            <div className='descripcionStandgasHc'>
                                <h2 className='nombreProductoStandgasHc'>Standgas HC</h2>
                                <h5 className='tituloProductoStandgasHc'>STANDGAS HC es un detector autónomo por tecnología
                                    catalítica para la detección de butano-propano, metano-gas natural e hidrógeno provisto
                                    de relé. Existe una versión especial con tres salidas de relé programables. Funcionamiento
                                    autónomo: sin conexión a ninguna central.</h5>
                                <p className='textoProductoStandgasHc'>
                                    Permite la selección automática del gas a detectar, de entre cualquiera de los gases 
                                    disponibles, mediante protocolo establecido con un jumper.
                                    <br />
                                    – Rango de detección de 0 a 100% L.I.E.
                                    <br />
                                    – Indicación óptica de estados y avería de sensor.
                                    <br />
                                    – 1 salida de relé de alarma programable entre 2 niveles (20% LIE / 50% LIE), desconexión 
                                    instantánea/retardada, programación retardo de desconexión y estado inicial del relé en 
                                    reposo. Existe una versión especial con tres niveles o dos niveles más una salida de avería.
                                    <br />
                                    – Alimentación 10V a 30V DC.
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12'>

                            <div className='contenedorNavStandgasHc'>
                                <Nav as="ul" className="flex-column">
                                    <Nav.Item className='linksArchivosStandgasHc' as="li" onClick={() => clicEnlacePdf('ficha')}>
                                        {logoPdf}
                                        Ficha Standgas Hc
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosStandgasHc' as="li" onClick={() => clicEnlacePdf('manual')}>
                                        {logoPdf}
                                        Manual Standgas Hc
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosStandgasHc' as="li" onClick={() => clicEnlacePdf('presentacion')}>
                                        {logoPdf}
                                        Presentación Standgas Hc
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosStandgasHc' as="li" onClick={() => clicEnlacePdf('folleto')}>
                                        {logoPdf}
                                        Folleto Standgas Hc
                                    </Nav.Item>

                                </Nav>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <Modal className='imgModalContainer' show={fotoSeleccionada !== null} onHide={() => setFotoSeleccionada(null)}>
                <Modal.Header closeButton>
                    {subtituloModal && <Modal.Title>{subtituloModal}</Modal.Title>}
                </Modal.Header>
                <Modal.Body>
                    {fotoSeleccionada && (<img src={fotoSeleccionada} alt="Foto Standgas Hc" className="imgModal" />)}
                </Modal.Body>
            </Modal>

        </>
    );

}

