import React from 'react';
import { MenuLateral } from '../MenuLateral/MenuLateral'
import { useNavigate } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import './Altavoces.css';

export function Altavoces() {

    const navigate = useNavigate();

    const irAAltCertificados = () => {
        navigate(`/altavocesCertificadosen54`);
    };

    const irAAltNoCertificados = () => {
        navigate(`/altavocesNoCertificados`);
    };

    return (
        <>
            <div className="mainContainer">
                <MenuLateral />

                <div className='contenedor'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='tituloAltavoces'>
                                <h1>Altavoces</h1>
                            </div>

                        </div>

                    </div>

                    <div className='row'>

                        <div className='contenedorCardsAltavoces'>
                            <div className='row'>
                                <div className='col-xl-6 col-lg-6 col-md-12'>
                                    <Card>
                                        <Card.Img variant="altCertificados" src={require('../Img/altCertificados.webp')} onClick={irAAltCertificados} style={{ cursor: 'pointer' }} alt='Certificados en -54' fetchpriority='high' />
                                        <Card.Body>
                                            <Card.Title className='tituloAltCertificados'>Certificados en -54</Card.Title>

                                        </Card.Body>

                                    </Card>
                                </div>
                                <div className='col-xl-6 col-lg-6 col-md-12'>
                                    <Card>
                                        <Card.Img variant="altNoCertificados" src={require('../Img/altNoCertificados.webp')} onClick={irAAltNoCertificados} style={{ cursor: 'pointer' }} alt='No certificados' fetchpriority='high' />
                                        <Card.Body>
                                            <Card.Title className='tituloAltNoCertificados'>No Certificados</Card.Title>

                                        </Card.Body>
                                    </Card>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

