import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Row from 'react-bootstrap/Row'; import { MenuLateral } from '../MenuLateral/MenuLateral'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'; // Importa el icono faBars
import Nav from 'react-bootstrap/Nav';
import { fichaVap1, fichaVap8k, fichaRack4, fichaRack6, manualVap1 } from '../../Archivos/panelAlarma';
import './PanelAlarma.css';

export function PanelAlarma() {

    const [fotoSeleccionada, setFotoSeleccionada] = useState(null);
    const [subtituloModal, setSubtituloModal] = useState("");

    const modalFoto = (foto, subtitulo) => {
        setFotoSeleccionada(foto);
        setSubtituloModal(subtitulo)
    };

    const logoPdf = <FontAwesomeIcon icon={faFilePdf} size="xl" style={{ color: "#006c87", }} />

    const abrirPdf = (archivo) => {
        window.open(archivo, '_blank');
    };

    const archivosPDF = {
        fichaVap1: fichaVap1,
        fichaVap8k: fichaVap8k,
        fichaRack4: fichaRack4,
        fichaRack6: fichaRack6,
        manual: manualVap1

    };

    const clicEnlacePdf = (tipoArchivo) => {
        abrirPdf(archivosPDF[tipoArchivo]);
    };

    return (
        <>
            <div className="mainContainer">
                <MenuLateral />

                <div className='contenedorProducto'>
                    <div className='row'>
                        <div className='col-xl-3 col-lg-3 col-md-12 col-sm-12'>
                            <div className='imagenesPanelAlarma'>

                                <Row>
                                    <div className='contenedorFoto' >
                                        <img className="imagenProductoPanelAlarma"
                                            src={require("../Img/vap1.webp")}
                                            
                                            onClick={() => modalFoto(require("../Img/vap1.webp"), 'VAP-1')}
                                            alt='VAP-1' />
                                    </div>
                                </Row>
                                <Row>
                                    <div className='contenedorFoto' >
                                        <img className="imagenProductoPanelAlarma"
                                            src={require("../Img/vap8k.webp")}
                                            
                                            onClick={() => modalFoto(require("../Img/vap8k.webp"), 'VAP-8K')}
                                            alt='VAP-8K' />
                                    </div>
                                </Row>
                                <Row>
                                    <div className='contenedorFoto' >
                                        <img className="imagenProductoPanelAlarma"
                                            src={require("../Img/rack.webp")}
                                            
                                            onClick={() => modalFoto(require("../Img/rack.webp"), 'Rack')}
                                            alt='Rack' />
                                    </div>
                                </Row>

                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                            <div className='descripcionPanelAlarma'>
                                <h2 className='nombreProductoPanelAlarma'>Panel de Alarma VAP-1 y Extensión VAP-8K</h2>
                                <h5 className='tituloProductoPanelAlarma'>El panel de alarma por voz VAP-1 está diseñado para dotar al sistema NEO de
                                    controles para el sistema de evacuación en diferentes ubicaciones.
                                </h5>
                                <p className='textoProductoPanelAlarma'>
                                    Permite conocer el estado del sistema y dar avisos de alerta y evacuación por voz en vivo o mediante mensaje pregrabado
                                    por hasta 56 memorias de selección de zonas de evacuación. Esto es posible gracias a que puede equipar teclados de
                                    expansión VAP-8K, cada uno con 8 memorias de zona. Se conecta al equipo NEO por cable UTP CAT5 a través del bus ACSI,
                                    el cual permite una distancia máxima de 1000m y ofrece niveles de prioridad entre los dispositivos conectados en bus.
                                    <br /><br />
                                    Cuenta con funciones como control de volumen y ajuste dinámico de sonido, además de botones programables que permiten
                                    funciones del sistema tales como el lanzamiento de mensajes pregrabados.
                                    <br />
                                    Tiene un chasis metálico que le proporciona estabilidad y protección contra daños. Todos los botones están diseñados
                                    para un uso intensivo.
                                </p>

                                <h5 className='tituloProductoPanelAlarma'> El VAP-8K es un teclado de expansión para el panel de alarma por voz VAP-1.
                                </h5>
                                <p className='textoProductoPanelAlarma'>
                                    Cuenta con 8 botones programables a través de software para un máximo de 8 zonas de megafonía. Cada VAP puede equipar
                                    hasta 7 teclados de expansión mediante un puerto lateral que permite la instalación en el lateral derecho de la unidad
                                    principal. De este modo se puede lograr un direccionamiento de hasta 448 zonas.
                                    <br /><br />
                                    Existen armarios rack de 19″ de 4U o 6U para instalación en pared y puerta de cristal con control de acceso mediante
                                    cerradura, ideales para el panel de emergencias modelo LDA VAP-1 y extensiones LDA VAP-8K.

                                </p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12'>

                            <div className='contenedorNavPanelAlarma'>
                                <Nav as="ul" className="flex-column">
                                    <Nav.Item className='linksArchivosPanelAlarma' as="li" onClick={() => clicEnlacePdf('fichaVap1')}>
                                        {logoPdf}
                                        Ficha VAP-1
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosPanelAlarma' as="li" onClick={() => clicEnlacePdf('fichaVap8k')}>
                                        {logoPdf}
                                        Ficha VAP-8K
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosPanelAlarma' as="li" onClick={() => clicEnlacePdf('fichaRack4')}>
                                        {logoPdf}
                                        Ficha Rack 4
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosPanelAlarma' as="li" onClick={() => clicEnlacePdf('fichaRack6')}>
                                        {logoPdf}
                                        Ficha Rack 6
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosPanelAlarma' as="li" onClick={() => clicEnlacePdf('manual')}>
                                        {logoPdf}
                                        Manual VAP-1
                                    </Nav.Item>

                                </Nav>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <Modal className='imgModalContainer' show={fotoSeleccionada !== null} onHide={() => setFotoSeleccionada(null)}>
                <Modal.Header closeButton>
                    {subtituloModal && <Modal.Title>{subtituloModal}</Modal.Title>}
                </Modal.Header>
                <Modal.Body>
                    {fotoSeleccionada && (<img src={fotoSeleccionada} alt="Foto Panel de Alarma VAP-1 y Extensión VAP-8" className="imgModal" />)}
                </Modal.Body>
            </Modal>

        </>
    );

}

