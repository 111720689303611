import React from 'react';
import { MenuLateral } from '../MenuLateral/MenuLateral'
import { useNavigate } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import './DeteccionDeGasesTunel.css';

export function DeteccionDeGasesTunel() {

    const navigate = useNavigate();

    const irACentralDurgas = () => {
        navigate(`/centralDurgas`);
    };

    const irASondeltox = () => {
        navigate(`/sondeltox`);
    };

    const irADetectorDurtox = () => {
        navigate(`/detectorDurtox`);
    };

    return (
        <>
            <div className="mainContainer">
                <MenuLateral />

                <div className='contenedor'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='tituloGasesTunel'>
                                <h1>Detección de gases</h1>
                            </div>

                        </div>

                    </div>

                    <div className='row'>

                        <div className='contenedorCardsDeteccionGasesTunel'>
                            <div className='row'>
                                <div className='col-xl-4 col-lg-4 col-md-12'>
                                    <Card>
                                        <Card.Img variant="durgasTunel" src={require('../Img/CentralDurgas.webp')} onClick={irACentralDurgas} onTouchEnd={irACentralDurgas} style={{ cursor: 'pointer' }} alt='Central Durgas' fetchpriority='high' />
                                        <Card.Body>
                                            <Card.Title className='tituloDurgas'>Central Durgas</Card.Title>

                                        </Card.Body>

                                    </Card>
                                </div>

                                <div className='col-xl-4 col-lg-4 col-md-12'>
                                    <Card>
                                        <Card.Img variant="sondeltox" src={require('../Img/sondeltox.webp')} onClick={irASondeltox} style={{ cursor: 'pointer' }} alt='Sondeltox' fetchpriority='high' />
                                        <Card.Body>
                                            <Card.Title className='tituloSondeltox'>Sondeltox</Card.Title>

                                        </Card.Body>

                                    </Card>
                                </div>

                                <div className='col-xl-4 col-lg-4 col-md-12'>
                                    <Card>
                                        <Card.Img variant="durtox" src={require('../Img/durtox1.webp')} onClick={irADetectorDurtox} style={{ cursor: 'pointer' }} alt='Detector Durtox 4-20mA' fetchpriority='high' />
                                        <Card.Body>
                                            <Card.Title className='tituloDurtox'>Detector Durtox 4-20mA</Card.Title>

                                        </Card.Body>
                                    </Card>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

