import { MenuLateral } from '../MenuLateral/MenuLateral'
import './Empresa.css';

export function Empresa() {

    const tab = <>&nbsp;&nbsp;&nbsp;&nbsp;</>;

    return (
        <>
            <div className="contenedorGeneral">
                <MenuLateral />
                <div className="container">
                    <div className="empresa">
                        <div >
                            <div className='row'>
                                <div className='col-12'>
                                    <h1 className='nombreEmpresa'>DTGAS</h1>
                                </div>
                                <div className='col-12'>

                                    <div className='row'>
                                        <h4 className='subNombreEmpresa'>Entregando experiencia en seguridad</h4>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div>
                            <h6 className='quien'>{tab}¿Quiénes somos?</h6>
                        </div>
                        <div >

                            <p className='textoEmpresa' fetchpriority='high' >{tab} En DTGAS, nos enorgullece ser líderes en la industria de la detección de gases. Con una trayectoria de
                                excelencia y un compromiso inquebrantable con la seguridad, ofrecemos soluciones avanzadas para proteger su entorno,
                                proporcionando soluciones de vanguardia que garanticen la seguridad y el bienestar de nuestros clientes. Nos esforzamos
                                por superar las expectativas, brindando servicios y productos de calidad superior.</p>
                            <p className='textoEmpresa'>{tab} La seguridad es nuestra prioridad y trabajamos incansablemente para proporcionar soluciones que protejan
                                vidas y propiedades, contribuyendo a entornos más seguros y sostenibles.</p>
                            <p className='textoEmpresa'>{tab} Contamos con un equipo altamente capacitado de profesionales. Nuestra experiencia y dedicación nos
                                distinguen, permitiéndonos abordar desafíos complejos con soluciones efectivas.</p>
                            <p className='textoEmpresa'>{tab} Orgullosos de que nuestros equipos posean certificaciones que demuestran nuestro compromiso con la calidad
                                y el cumplimiento normativo. Su confianza en nosotros está respaldada por estándares reconocidos.</p>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );

}