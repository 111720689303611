import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Row from 'react-bootstrap/Row'; import { MenuLateral } from '../MenuLateral/MenuLateral'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'; // Importa el icono faBars
import Nav from 'react-bootstrap/Nav';
import { aplicaciones, fichaSWS100, fichaSWS200, fichaSWS250, manual, sensorLuzAmbiental } from '../../Archivos/sensorVisibilidad';
import './SensorVisibilidad.css';

export function SensorVisibilidad() {

    const [fotoSeleccionada, setFotoSeleccionada] = useState(null);
    const [subtituloModal, setSubtituloModal] = useState("");

    const modalFoto = (foto, subtitulo) => {
        setFotoSeleccionada(foto);
        setSubtituloModal(subtitulo)
    };

    const logoPdf = <FontAwesomeIcon icon={faFilePdf} size="xl" style={{ color: "#006c87", }} />

    const abrirPdf = (archivo) => {
        window.open(archivo, '_blank');
    };

    const archivosPDF = {
        ficha100: fichaSWS100,
        ficha200: fichaSWS200,
        ficha250: fichaSWS250,
        sensorLuz: sensorLuzAmbiental,
        manual: manual,
        aplicaciones: aplicaciones


    };

    const clicEnlacePdf = (tipoArchivo) => {
        abrirPdf(archivosPDF[tipoArchivo]);
    };

    return (
        <>
            <div className="mainContainer">
                <MenuLateral />

                <div className='contenedorProducto'>
                    <div className='row'>
                        <div className='col-xl-3 col-lg-3 col-md-12 col-sm-12'>
                            <div className='imagenesSensorVisibilidad'>

                                <Row>
                                    <div className='contenedorFoto' >
                                        <img className="imagenProductoSensorVisibilidad"
                                            src={require("../Img/visibilidad.webp")}
                                            
                                            onClick={() => modalFoto(require("../Img/visibilidad.webp"), 'Sensor de visibilidad y tiempo presente')}
                                            alt='Sensor de visibilidad y tiempo presente' />
                                    </div>
                                </Row>

                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                            <div className='descripcionSensorVisibilidad'>
                                <h2 className='nombreProductoSensorVisibilidad'>Sensor de visibilidad y tiempo presente</h2>
                                <h5 className='tituloProductoSensorVisibilidad'>El monitor de visibilidad y tiempo presente es capaz de medir, de
                                    manera simultánea, la visibilidad y la causa que la reduce (niebla, neblina, humo, polvo en suspensión,
                                    lluvia, nieve y precipitaciones en general).</h5>
                                <p className='textoProductoSensorVisibilidad'>
                                    Existen diferentes modelos:
                                    <br />
                                    <br />
                                    - SWS-100: Indica la visibilidad y los tipos de precipitación.
                                    <br />
                                    - SWS-200: Indica la visibilidad, los tipos de precipitación y la intensidad de la misma.
                                    <br />
                                    - SWS-250: Indica la visibilidad y las variables atmosféricas en el presente y en el pasado.
                                    <br />
                                    Además existe la familia HSS especial para Aeropuertos.
                                    <br />
                                    Como accesorio se presenta el sensor de luz ambiental ALS-2 que permite al SWS-250 y a la familia HSS 
                                    cambiar los algoritmos para calcular la visibilidad en función de la luminosidad medida en ese momento.
                                </p>
                            </div>
                        </div>


                        <div className='col-lg-3 col-md-12'>

                            <div className='contenedorNavSensorVisibilidad'>
                                <Nav as="ul" className="flex-column">
                                    <Nav.Item className='linksArchivosSensorVisibilidad' as="li" onClick={() => clicEnlacePdf('ficha100')}>
                                        {logoPdf}
                                        Ficha SWS-100
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosSensorVisibilidad' as="li" onClick={() => clicEnlacePdf('ficha200')}>
                                        {logoPdf}
                                        Ficha SWS-200
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosSensorVisibilidad' as="li" onClick={() => clicEnlacePdf('ficha250')}>
                                        {logoPdf}
                                        Ficha SWS-250
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosSensorVisibilidad' as="li" onClick={() => clicEnlacePdf('sensorLuz')}>
                                        {logoPdf}
                                        Sensor Luz Ambiental
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosSensorVisibilidad' as="li" onClick={() => clicEnlacePdf('manual')}>
                                        {logoPdf}
                                        Manual
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosSensorVisibilidad' as="li" onClick={() => clicEnlacePdf('aplicaciones')}>
                                        {logoPdf}
                                        Serie HSS Aplicaciones
                                    </Nav.Item>


                                </Nav>
                            </div>
                        </div>
                    </div>

                </div>

            </div >

            <Modal className='imgModalContainer' show={fotoSeleccionada !== null} onHide={() => setFotoSeleccionada(null)}>
                <Modal.Header closeButton>
                    {subtituloModal && <Modal.Title>{subtituloModal}</Modal.Title>}
                </Modal.Header>
                <Modal.Body>
                    {fotoSeleccionada && (<img src={fotoSeleccionada} alt="Foto Sensor de Visibilidad" className="imgModal" />)}
                </Modal.Body>
            </Modal>

        </>
    );

}

