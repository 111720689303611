import { MenuLateral } from '../MenuLateral/MenuLateral'
import './Mantenimientos.css';

export function Mantenimientos() {

    const tab = <>&nbsp;&nbsp;&nbsp;&nbsp;</>;

    return (
        <>
            <div className="contenedorGeneral">
                <MenuLateral />
                <div className="container">
                    <div className="mantenimientos">
                        <div >
                            <div className='row'>
                                <div className='col-12'>
                                    <h1 className='nombreMantenimientos'>Mantenimientos</h1>
                                </div>
                            </div>
                        </div>

                        <div >

                            <p className='textoMantenimientos' fetchpriority='high' >{tab} En DTGAS, nos dedicamos con pasión y compromiso al mantenimiento de detectores de gases, ofreciendo
                                soluciones confiables para garantizar la seguridad en entornos diversos. Nuestro equipo de profesionales altamente capacitados
                                se esfuerza por brindar servicios de calidad que cumplen con los más altos estándares de la industria.</p>
                            <p className='textoMantenimientos'>{tab} Implementamos programas de mantenimiento preventivo para asegurar que sus equipos estén siempre en
                                condiciones óptimas. La prevención es clave para evitar problemas potenciales y mantener un entorno seguro.</p>
                            <p className='textoMantenimientos'>{tab} Realizamos pruebas exhaustivas para verificar la funcionalidad, sensibilidad y respuesta de cada detector.
                                Nuestro enfoque meticuloso garantiza la precisión y confiabilidad de estos dispositivos críticos.</p>
                            <p className='textoMantenimientos'>{tab} Experiencia y Profesionalismo: Contamos con años de experiencia en el mantenimiento de detectores de gases,
                                respaldados por un equipo de profesionales altamente capacitados.</p>
                            <p className='textoMantenimientos'>{tab} Compromiso con la Seguridad: Nos tomamos en serio la seguridad de su entorno. Nuestro trabajo contribuye
                                directamente a la prevención de riesgos y la protección de vidas.</p>
                            <p className='textoMantenimientos'>{tab} Servicio Personalizado: Entendemos que cada cliente es único. Adaptamos nuestros servicios para satisfacer
                                sus necesidades específicas y proporcionamos soluciones personalizadas.</p>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );

}