import './Inicio.css';
import { Carrousel } from '../Carrousel/Carrousel'
import { useNavigate } from 'react-router-dom';
import Card from 'react-bootstrap/Card';


export function Inicio() {

    const navigate = useNavigate();

    const irADeteccionGases = () => {
        navigate('/deteccionDeGases');
    };

    const irAEstacionMeteorologica = () => {
        navigate('/estacionMeteorologica');
    };

    const irATuneles = () => {
        navigate('/tuneles');
    };

    const irAMegafonia = () => {
        navigate('/megafonia');
    };



    return (
        <>
            <Carrousel />

            <div className='contenedorCardsInicio'>
                <div className='row'>
                    <div className='col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-6'>
                        <Card>
                            <Card.Img variant="top" src={require('../Img/card1.webp')} onClick={irADeteccionGases} style={{ cursor: 'pointer' }} alt='Detección de gases' />

                        </Card>
                    </div>

                    <div className='col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-6'>

                        <Card>
                            <Card.Img variant="top" src={require('../Img/card3.webp')} onClick={irATuneles} style={{ cursor: 'pointer' }} alt='Control ambiental y túneles'/>

                        </Card>
                    </div>

                    <div className='col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-6'>
                        <Card>
                            <Card.Img variant="top" src={require('../Img/card2.webp')} onClick={irAEstacionMeteorologica} style={{ cursor: 'pointer' }} alt='Estaciones meteorológicas compactas' />

                        </Card>
                    </div>

                    <div className='col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-6'>

                        <Card>
                            <Card.Img variant="top" src={require('../Img/card4.webp')} onClick={irAMegafonia} style={{ cursor: 'pointer' }} alt='Megafonía' />

                        </Card>
                    </div>
                </div>
            </div>
        </>
    )
}