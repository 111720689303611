import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Row from 'react-bootstrap/Row'; import { MenuLateral } from '../MenuLateral/MenuLateral'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'; // Importa el icono faBars
import Nav from 'react-bootstrap/Nav';
import { fichaPower3600, manualPower3600, certificado } from '../../Archivos/power3600';
import './Power3600.css';

export function Power3600() {

    const [fotoSeleccionada, setFotoSeleccionada] = useState(null);
    const [subtituloModal, setSubtituloModal] = useState("");

    const modalFoto = (foto, subtitulo) => {
        setFotoSeleccionada(foto);
        setSubtituloModal(subtitulo)
    };

    const logoPdf = <FontAwesomeIcon icon={faFilePdf} size="xl" style={{ color: "#006c87", }} />

    const abrirPdf = (archivo) => {
        window.open(archivo, '_blank');
    };

    const archivosPDF = {
        ficha: fichaPower3600,
        manual: manualPower3600,
        certificado: certificado

    };

    const clicEnlacePdf = (tipoArchivo) => {
        abrirPdf(archivosPDF[tipoArchivo]);
    };

    return (
        <>
            <div className="mainContainer">
                <MenuLateral />

                <div className='contenedorProducto'>
                    <div className='row'>
                        <div className='col-xl-3 col-lg-3 col-md-12 col-sm-12'>
                            <div className='imagenesPower3600'>

                                <Row>
                                    <div className='contenedorFoto' >
                                        <img className="imagenProductoPower3600"
                                            src={require("../Img/power3600.webp")}
                                            
                                            onClick={() => modalFoto(require("../Img/power3600.webp"), 'Cargador de Baterías Power-3600')}
                                            alt='Cargador de Baterías Power-3600' />
                                    </div>
                                </Row>

                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                            <div className='descripcionPower3600'>
                                <h2 className='nombreProductoPower3600'>Cargador de Baterías Power-3600</h2>
                                <h5 className='tituloProductoPower3600'>Cargador de baterías EN54-4 24V, 3600W para sistemas de evacuación por voz.
                                </h5>
                                <p className='textoProductoPower3600'>
                                    Diseñado para cargar baterías de plomo-ácido (alimentación de reserva para el sistema de evacuación por voz) y, al 
                                    mismo tiempo, ofrece alimentación para aplicaciones auxiliares.
                                    <br /><br />
                                    Incluye 6 salidas de carga (cada una a 960W) y una potencia máxima de 3600W.
                                    <br /><br />
                                    Soporta hasta 3 unidades de NEO EXTENSION con 2 baterías de 120Ah 12V (no incluidas). Dimensiones (WxHxD): 
                                    482,6 x 89 x 244 mm, 2U. Peso: 5,4 kg.
                                    
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12'>

                            <div className='contenedorNavPower3600'>
                                <Nav as="ul" className="flex-column">
                                    <Nav.Item className='linksArchivosPower3600' as="li" onClick={() => clicEnlacePdf('ficha')}>
                                        {logoPdf}
                                        Ficha Cargador de Baterías Power-3600
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosPower3600' as="li" onClick={() => clicEnlacePdf('manual')}>
                                        {logoPdf}
                                        Manual Cargador de Baterías Power-3600
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosPower3600' as="li" onClick={() => clicEnlacePdf('certificado')}>
                                        {logoPdf}
                                        Certificado en 54-16
                                    </Nav.Item>

                                </Nav>
                            </div>
                        </div>
                    </div>

                </div>

            </div >

            <Modal className='imgModalContainer' show={fotoSeleccionada !== null} onHide={() => setFotoSeleccionada(null)}>
                <Modal.Header closeButton>
                    {subtituloModal && <Modal.Title>{subtituloModal}</Modal.Title>}
                </Modal.Header>
                <Modal.Body>
                    {fotoSeleccionada && (<img src={fotoSeleccionada} alt="Foto Cargador de Baterías Power-3600" className="imgModal" />)}
                </Modal.Body>
            </Modal>

        </>
    );

}

