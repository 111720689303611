import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';


//mis compnentes
import { Header } from './componentes/Header/Header';
import { Footer } from './componentes/Footer/Footer';
import { Contacto } from './componentes/Contacto/Contacto';
import { Empresa } from './componentes/Empresa/Empresa';
import { Mantenimientos } from './componentes/Mantenimientos/Mantenimientos';
import { TestPruebas } from './componentes/TestPruebas/TestPruebas';
import { PuestaEnMarcha } from './componentes/PuestaEnMarcha/PuestaEnMarcha';
import { Inicio } from './componentes/Inicio/Inicio';
import { DeteccionDeGases } from './componentes/DeteccionDeGases/DeteccionDeGases';
import { DeteccionDeGasesTunel } from './componentes/DeteccionDeGasesTunel/DeteccionDeGasesTunel';
import { Tuneles } from './componentes/Tuneles/Tuneles';
import { GasesToxicos } from './componentes/GasesToxicos/GasesToxicos';
import { SistemasCentralizados } from './componentes/SistemasCentralizados/SistemasCentralizados';
import { Detectores4_20ma } from './componentes/Detectores4_20ma/Detectores4_20ma';
import { DetectoresAutonomos } from './componentes/DetectoresAutonomos/DetectoresAutonomos';
import { CentralDurgas } from './componentes/CentralDurgas/CentralDurgas';
import { CentralDurpark } from './componentes/CentralDurpark/CentralDurpark';
import { Sondeltox } from './componentes/Sondeltox/Sondeltox';
import { DetectorDurtox } from './componentes/DetectorDurtox/DetectorDurtox';
import { DetectorDirex } from './componentes/DetectorDirex/DetectorDirex';
import { StandgasPro } from './componentes/StandgasPro/StandgasPro';
import { StandgasProLcdTox } from './componentes/StandgasProLcdTox/StandgasProLcdTox';
import { GasesExplosivos } from './componentes/GasesExplosivos/GasesExplosivos';
import { Detectores4_20maE } from './componentes/Detectores4_20maE/Detectores4_20maE';
import { DetectoresAutonomosE } from './componentes/DetectoresAutonomosE/DetectoresAutonomosE';
import { CableDeteccionLineal } from './componentes/CableDeteccionLineal/CableDeteccionLineal';
import { DetectorDurtex } from './componentes/DetectorDurtex/DetectorDurtex';
import { StandgasHc } from './componentes/StandgasHc/StandgasHc';
import { StandgasHcPro } from './componentes/StandgasHcPro/StandgasHcPro';
import { StandgasProLcdExp } from './componentes/StandgasProLcdExp/StandgasProLcdExp';
import { Opacimetro } from './componentes/Opacimetro/Opacimetro';
import { SensoresLuminosos } from './componentes/SensoresLuminosos/SensoresLuminosos';
import { Luminancimetro } from './componentes/Luminancimetro/Luminancimetro';
import { Luxometro } from './componentes/Luxometro/Luxometro';
import { Anemometros } from './componentes/Anemometros/Anemometros';
import { HelicesYConversor } from './componentes/HelicesYConversor/HelicesYConversor';
import { Ultrasonico } from './componentes/Ultrasonico/Ultrasonico';
import { SensoresAtmosfericos } from './componentes/SensoresAtmosfericos/SensoresAtmosfericos';
import { SensorVisibilidad } from './componentes/SensorVisibilidad/SensorVisibilidad';
import { Pluviometro } from './componentes/Pluviometro/Pluviometro';
import { SensorHumedad } from './componentes/SensorHumedad/SensorHumedad';
import { Barometro } from './componentes/Barometro/Barometro';
import { Piranometro } from './componentes/Piranometro/Piranometro';
import { SensorHielo } from './componentes/SensorHielo/SensorHielo';
import { EstacionMeteorologica } from './componentes/EstacionMeteorologica/EstacionMeteorologica';
import { Megafonia } from './componentes/Megafonia/Megafonia';
import { CertificadosEn54 } from './componentes/CertificadosEn54/CertificadosEn54';
import { Neo } from './componentes/Neo/Neo';
import { NeoUnidadPrincipal } from './componentes/NeoUnidadPrincipal/NeoUnidadPrincipal';
import { NeoExtension } from './componentes/NeoExtension/NeoExtension';
import { Neo4250 } from './componentes/Neo4250/Neo4250';
import { Neo4500 } from './componentes/Neo4500/Neo4500';
import { Neo4500L } from './componentes/Neo4500L/Neo4500L';
import { Neo8250 } from './componentes/Neo8250/Neo8250';
import { AccesoriosNeo } from './componentes/AccesoriosNeo/AccesoriosNeo';
import { Power3600 } from './componentes/Power3600/Power3600';
import { Power960 } from './componentes/Power960/Power960';
import { SoftwareControl } from './componentes/SoftwareControl/SoftwareControl';
import { SimeControl } from './componentes/SimeControl/SimeControl';
import { SimeCalendar } from './componentes/SimeCalendar/SimeCalendar';
import { One500 } from './componentes/One500/One500';
import { AccesoriosNeoYOne } from './componentes/AccesoriosNeoYOne/AccesoriosNeoYOne';
import { MPS } from './componentes/MPS/MPS';
import { Selector } from './componentes/Selector/Selector';
import { PanelAlarma } from './componentes/PanelAlarma/PanelAlarma';
import { OtrosAccesorios } from './componentes/OtrosAccesorios/OtrosAccesorios';
import { Altavoces } from './componentes/Altavoces/Altavoces';
import { AltCertificadosEn54 } from './componentes/AltCertificadosEn54/AltCertificadosEn54';
import { AltavocesTecho } from './componentes/AltavocesTecho/AltavocesTecho';
import { Proyectores } from './componentes/Proyectores/Proyectores';
import { Columnas } from './componentes/Columnas/Columnas';
import { AltavocesTechoNoCert } from './componentes/AltavocesTechoNoCert/AltavocesTechoNoCert';
import { NoCertificados } from './componentes/NoCertificados/NoCertificados';
import { CajasAcusticas } from './componentes/CajasAcusticas/CajasAcusticas';
import { ProyectoresNoCert } from './componentes/ProyectoresNoCert/ProyectoresNoCert';
import { ColumnasNoCert } from './componentes/ColumnasNoCert/ColumnasNoCert';
import { OtrosNoCert } from './componentes/OtrosNoCert/OtrosNoCert';




function App() {
    return (
        <>
            <BrowserRouter>
                <Header />

                <Routes>
                    <Route path='/' element={<Inicio />} />
                    <Route path='/empresa' element={<Empresa />} />
                    <Route path='/mantenimientos' element={<Mantenimientos />} />
                    <Route path='/testPruebas' element={<TestPruebas />} />
                    <Route path='/puestaEnMarcha' element={<PuestaEnMarcha />} />
                    <Route path='/contacto' element={<Contacto />} />

                    {/* Menues  */}
                    <Route path='/deteccionDeGases' element={<DeteccionDeGases />} />
                    <Route path='/tuneles' element={<Tuneles />} />

                    <Route path='/gasesToxicos' element={<GasesToxicos />} />
                    <Route path='/sistemasCentralizados' element={<SistemasCentralizados />} />
                    <Route path='/detectores4_20ma' element={<Detectores4_20ma />} />
                    <Route path='/detectoresAutonomos' element={<DetectoresAutonomos />} />

                    <Route path='/gasesExplosivos' element={<GasesExplosivos />} />
                    <Route path='/detectores4_20maE' element={<Detectores4_20maE />} />
                    <Route path='/detectoresAutonomosE' element={<DetectoresAutonomosE />} />
                    
                    <Route path='/deteccionGasesTunel' element={<DeteccionDeGasesTunel />} />
                    <Route path='/sensoresLuminosos' element={<SensoresLuminosos />} />
                    <Route path='/anemometros' element={<Anemometros />} />
                    <Route path='/sensoresAtmosfericos' element={<SensoresAtmosfericos />} />
                    
                    <Route path='/megafonia' element={<Megafonia />} />
                    <Route path='/sistemasCertificadosEn-54' element={<CertificadosEn54 />} />
                    <Route path='/neo' element={<Neo />} />
                    <Route path='/neoExtension' element={<NeoExtension />} />
                    <Route path='/accesoriosNeo' element={<AccesoriosNeo />} />
                    <Route path='/softwareControl' element={<SoftwareControl />} />
                    <Route path='/accesoriosNeoYOne' element={<AccesoriosNeoYOne />} />
                    <Route path='/altavoces' element={<Altavoces />} />
                    <Route path='/altavocesCertificadosEn54' element={<AltCertificadosEn54 />} />
                    <Route path='/altavocesNoCertificados' element={<NoCertificados />} />
                    



                    {/* Productos  */}
                    {/* Gases Toxicos */}
                    <Route path='/centralDurgas' element={<CentralDurgas />} />
                    <Route path='/centralDurpark' element={<CentralDurpark />} />
                    <Route path='/sondeltox' element={<Sondeltox />} />
                    <Route path='/detectorDurtox' element={<DetectorDurtox />} />
                    <Route path='/detectorDirex' element={<DetectorDirex />} />
                    <Route path='/standgasPro' element={<StandgasPro />} />
                    <Route path='/standgasProLcdTox' element={<StandgasProLcdTox />} />
                    
                    {/* Gases Explosivos */}
                    <Route path='/cableDeteccionLineal' element={<CableDeteccionLineal />} />
                    <Route path='/detectorDurtex' element={<DetectorDurtex />} />
                    <Route path='/standgasHc' element={<StandgasHc />} />
                    <Route path='/standgasHcPro' element={<StandgasHcPro />} />
                    <Route path='/standgasProLcdExp' element={<StandgasProLcdExp />} />
                    
                    {/* Tuneles */}
                    <Route path='/opacimetroSentry' element={<Opacimetro />} />
                    <Route path='/luminancimetro' element={<Luminancimetro />} />
                    <Route path='/luxometro' element={<Luxometro />} />
                    <Route path='/helicesYConversor' element={<HelicesYConversor />} />
                    <Route path='/ultrasonico' element={<Ultrasonico />} />
                    <Route path='/sensorVisibilidad' element={<SensorVisibilidad />} />
                    <Route path='/pluviometro' element={<Pluviometro />} />
                    <Route path='/sensorHumedad' element={<SensorHumedad />} />
                    <Route path='/barometro' element={<Barometro />} />
                    <Route path='/piranometro' element={<Piranometro />} />
                    <Route path='/sensorHielo' element={<SensorHielo />} />
                    <Route path='/estacionMeteorologica' element={<EstacionMeteorologica />} />
                    
                    {/* Megafonía */}
                    <Route path='/neoUnidadPrincipal' element={<NeoUnidadPrincipal />} />
                    <Route path='/neo4250' element={<Neo4250 />} />
                    <Route path='/Neo4500' element={<Neo4500 />} />
                    <Route path='/Neo4500L' element={<Neo4500L />} />
                    <Route path='/Neo8250' element={<Neo8250 />} />
                    <Route path='/power3600' element={<Power3600 />} />
                    <Route path='/power960' element={<Power960 />} />
                    <Route path='/simeControl' element={<SimeControl />} />
                    <Route path='/simeCalendar' element={<SimeCalendar />} />
                    <Route path='/one500' element={<One500 />} />
                    <Route path='/MPS' element={<MPS />} />
                    <Route path='/selectorCanalYVolumen' element={<Selector />} />
                    <Route path='/panelDeAlarma' element={<PanelAlarma />} />
                    <Route path='/otrosAccesorios' element={<OtrosAccesorios />} />
                    <Route path='/altavocesDeTechoOSuperficie' element={<AltavocesTecho />} />
                    <Route path='/proyectoresDeSonido' element={<Proyectores />} />
                    <Route path='/columnasAcusticas' element={<Columnas />} />
                    <Route path='/altavocesDeTechoOSuperficieNoCertificados' element={<AltavocesTechoNoCert />} />
                    <Route path='/cajasAcusticas' element={<CajasAcusticas />} />
                    <Route path='/proyectoresDeSonidoNoCertificados' element={<ProyectoresNoCert />} />
                    <Route path='/columnasNoCertificadas' element={<ColumnasNoCert />} />
                    <Route path='/otrosNoCertificados' element={<OtrosNoCert />} />



                </Routes>

                <Footer />
            </BrowserRouter>
        </>
    );
}

export default App;
