import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Row from 'react-bootstrap/Row'; import { MenuLateral } from '../MenuLateral/MenuLateral'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'; // Importa el icono faBars
import Nav from 'react-bootstrap/Nav';
import { fichaIrs, fichaSensTcs, fichaSensWSS, manual } from '../../Archivos/sensorHielo';
import './SensorHielo.css';

export function SensorHielo() {

    const [fotoSeleccionada, setFotoSeleccionada] = useState(null);
    const [subtituloModal, setSubtituloModal] = useState("");

    const modalFoto = (foto, subtitulo) => {
        setFotoSeleccionada(foto);
        setSubtituloModal(subtitulo)
    };

    const logoPdf = <FontAwesomeIcon icon={faFilePdf} size="xl" style={{ color: "#006c87", }} />

    const abrirPdf = (archivo) => {
        window.open(archivo, '_blank');
    };

    const archivosPDF = {
        fichaIrs: fichaIrs,
        fichaTcs: fichaSensTcs,
        fichaWss: fichaSensWSS,
        manual: manual

    };

    const clicEnlacePdf = (tipoArchivo) => {
        abrirPdf(archivosPDF[tipoArchivo]);
    };

    return (
        <>
            <div className="mainContainer">
                <MenuLateral />

                <div className='contenedorProducto'>
                    <div className='row'>
                        <div className='col-xl-3 col-lg-3 col-md-12 col-sm-12'>
                            <div className='imagenesSensorHielo'>

                                <Row>
                                    <div className='contenedorFoto' >
                                        <img className="imagenProductoSensorHielo"
                                            src={require("../Img/hielo.webp")}
                                            
                                            onClick={() => modalFoto(require("../Img/hielo.webp"), 'Sensor de hielo en calzada')}
                                            alt='Sensor de hielo en calzada' />
                                    </div>
                                </Row>

                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                            <div className='descripcionSensorHielo'>
                                <h2 className='nombreProductoSensorHielo'>Sensor de hielo en calzada</h2>
                                <h5 className='tituloProductoSensorHielo'>El sensor informa con precisión de la temperatura de superficie de la
                                    carretera y de su estado, así como del espesor de la película de agua sobre el pavimento.</h5>
                                <p className='textoProductoSensorHielo'>
                                    Además, se detectan la temperatura del punto de congelación y el factor de química (dependiendo de la marca y 
                                    modelo elegido). Estos parámetros permiten un monitoreo estricto de las condiciones del camino para las 
                                    operaciones de servicio de invierno o de Sistemas Inteligentes de Transporte (ITS).
                                    
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12'>

                            <div className='contenedorNavSensorHielo'>
                                <Nav as="ul" className="flex-column">
                                    <Nav.Item className='linksArchivosSensorHielo' as="li" onClick={() => clicEnlacePdf('fichaIrs')}>
                                        {logoPdf}
                                        Ficha Irs 31
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosSensorHielo' as="li" onClick={() => clicEnlacePdf('fichaTcs')}>
                                        {logoPdf}
                                        Ficha It-Sens TCS
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosSensorHielo' as="li" onClick={() => clicEnlacePdf('fichaWSS')}>
                                        {logoPdf}
                                        Ficha It-Sens WSS
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosSensorHielo' as="li" onClick={() => clicEnlacePdf('manual')}>
                                        {logoPdf}
                                        Manual Irs 31
                                    </Nav.Item>


                                </Nav>
                            </div>
                        </div>
                    </div>

                </div>

            </div >

            <Modal className='imgModalContainer' show={fotoSeleccionada !== null} onHide={() => setFotoSeleccionada(null)}>
                <Modal.Header closeButton>
                    {subtituloModal && <Modal.Title>{subtituloModal}</Modal.Title>}
                </Modal.Header>
                <Modal.Body>
                    {fotoSeleccionada && (<img src={fotoSeleccionada} alt="Foto Sensor de Hielo en Calzada" className="imgModal" />)}
                </Modal.Body>
            </Modal>

        </>
    );

}

