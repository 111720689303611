import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Row from 'react-bootstrap/Row'; import { MenuLateral } from '../MenuLateral/MenuLateral'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'; // Importa el icono faBars
import Nav from 'react-bootstrap/Nav';
import { fichaLcdExp, folletoLcdExp, manualLcdExp, presentacionLcdExp } from '../../Archivos/standgasProLcdExp';
import './StandgasProLcdExp.css';

export function StandgasProLcdExp() {

    const [fotoSeleccionada, setFotoSeleccionada] = useState(null);
    const [subtituloModal, setSubtituloModal] = useState("");

    const modalFoto = (foto, subtitulo) => {
        setFotoSeleccionada(foto);
        setSubtituloModal(subtitulo)
    };

    const logoPdf = <FontAwesomeIcon icon={faFilePdf} size="xl" style={{ color: "#006c87", }} />

    const abrirPdf = (archivo) => {
        window.open(archivo, '_blank');
    };

    const archivosPDF = {
        ficha: fichaLcdExp,
        manual: manualLcdExp,
        presentacion: presentacionLcdExp,
        folleto: folletoLcdExp
    };


    const clicEnlacePdf = (tipoArchivo) => {
        abrirPdf(archivosPDF[tipoArchivo]);
    };

    return (
        <>
            <div className="mainContainer">
                <MenuLateral />

                <div className='contenedorProducto'>
                    <div className='row'>
                        <div className='col-xl-3 col-lg-3 col-md-12 col-sm-12'>
                            <div className='imagenesStandgasProLcdExp'>

                                <Row>
                                    <div className='contenedorFoto' >
                                        <img className="imagenProductoStandgasProLcdExp"
                                            src={require("../Img/standgasProLcdExp.webp")}
                                            
                                            onClick={() => modalFoto(require("../Img/standgasProLcdExp.webp"), 'Standgas Pro LCD EXP')}
                                            alt='Standgas Pro LCD EXP' />
                                    </div>
                                </Row>

                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                            <div className='descripcionStandgasProLcdExp'>
                                <h2 className='nombreProductoStandgasProLcdExp'>Standgas Pro LCD EXP</h2>
                                <h5 className='tituloProductoStandgasProLcdExp'>STANDGAS PRO LCD EXP es un detector autónomo de tecnología catalítica
                                    para la detección de gases explosivos.</h5>
                                <p className='textoProductoStandgasProLcdExp'>
                                    Funcionamiento autónomo, no requiere central.
                                    <br />
                                    Existe una nueva versión autónoma con comunicación RS485 direccionable compatible con las centrales DURGAS
                                    (a partir de la versión v04 de DURGAS).
                                    <br />
                                    Gases disponibles: metano-gas natural, butano-propano, hidrógeno, heptano, hexano, pentano, metanol, estireno, etano,
                                    etanol, etileno, propileno, acetona, amoniaco, ciclohexano, ciclopentano, dioxano, acetato de butilo, acetato de etilo,
                                    ácido acético, alcohol isobutílico, alcohol isopropílico, decano, benceno, octano, metil etil cetona, -butanona-, nonano,
                                    propanol, tolueno, xileno, queroseno. Para otros gases consultar disponibilidad.
                                    <br />
                                    También se fabrica una versión especial para detección de ACETILENO.
                                    Provisto de un display de 16 X 2 líneas, retroiluminado y tres teclas.
                                    <br />
                                    Permite programar el tipo de gas, nivel de prealarma, nivel de alarma, estado inicial de la salida de maniobra, tipo de
                                    rearme manual o automático, temporización del rearme, activación o no de la acústica interna y posibilidad de inhibir el
                                    sensor para evitar maniobras inoportunas en caso de avería.
                                    <br />
                                    Calibrados en fábrica con gas patrón. No necesitan ser recalibrados en su puesta en marcha inicial.
                                    Resistente a los vapores de silicona (HDMS).
                                    <br />
                                    Versión autónoma RS485: Especialmente indicada para su uso en laboratorios o zonas compartimentadas que además de uso 
                                    local necesiten de un control y efectuar maniobras e informar de su estado remotamente. Pueden conectarse hasta 8 unidades 
                                    por zona, manteniéndose su compatibilidad con el resto de gases.
                                    
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12'>

                            <div className='contenedorNavStandgasProLcdExp'>
                                <Nav as="ul" className="flex-column">
                                    <Nav.Item className='linksArchivosStandgasProLcdExp' as="li" onClick={() => clicEnlacePdf('ficha')}>
                                        {logoPdf}
                                        Ficha Standgas  Pro Lcd Exp
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosStandgasProLcdExp' as="li" onClick={() => clicEnlacePdf('manual')}>
                                        {logoPdf}
                                        Manual Standgas Pro Lcd Exp
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosStandgasProLcdExp' as="li" onClick={() => clicEnlacePdf('presentacion')}>
                                        {logoPdf}
                                        Presentación Standgas Pro Lcd Exp
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosStandgasProLcdExp' as="li" onClick={() => clicEnlacePdf('folleto')}>
                                        {logoPdf}
                                        Folleto Standgas Pro Lcd Exp
                                    </Nav.Item>

                                </Nav>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <Modal className='imgModalContainer' show={fotoSeleccionada !== null} onHide={() => setFotoSeleccionada(null)}>
                <Modal.Header closeButton>
                    {subtituloModal && <Modal.Title>{subtituloModal}</Modal.Title>}
                </Modal.Header>
                <Modal.Body>
                    {fotoSeleccionada && (<img src={fotoSeleccionada} alt="Foto Standgas Pro Lcd Exp" className="imgModal" />)}
                </Modal.Body>
            </Modal>

        </>
    );

}

