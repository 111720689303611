import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Row from 'react-bootstrap/Row'; import { MenuLateral } from '../MenuLateral/MenuLateral'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'; // Importa el icono faBars
import Nav from 'react-bootstrap/Nav';
import { fichaDurtox, fichaDurtoxX, fichaCajaDerivacionAtex, fichaDurtoxGranjas, fichaComercialDurtoxGranjas, fichaDurtoxH2, manual, manualDurtoxX } from '../../Archivos/durtoxToxicos';
import './DetectorDurtox.css';

export function DetectorDurtox() {

    const [fotoSeleccionada, setFotoSeleccionada] = useState(null);
    const [subtituloModal, setSubtituloModal] = useState("");

    const modalFoto = (foto, subtitulo) => {
        setFotoSeleccionada(foto);
        setSubtituloModal(subtitulo)
    };

    const logoPdf = <FontAwesomeIcon icon={faFilePdf} size="xl" style={{ color: "#006c87", }} />

    const abrirPdf = (archivo) => {
        window.open(archivo, '_blank');
    };

    const archivosPDF = {
        ficha: fichaDurtox,
        fichaX: fichaDurtoxX,
        fichaCaja: fichaCajaDerivacionAtex,
        fichaGranjas: fichaDurtoxGranjas,
        fichaComercialGranjas: fichaComercialDurtoxGranjas,
        fichaH2: fichaDurtoxH2,
        manual: manualDurtoxX
    };

    const clicEnlacePdf = (tipoArchivo) => {
        abrirPdf(archivosPDF[tipoArchivo]);
    };

    return (
        <>
            <div className="mainContainer">
                <MenuLateral />

                <div className='contenedorProducto'>
                    <div className='row'>
                        <div className='col-xl-3 col-lg-3 col-md-12 col-sm-12'>
                            <div className='imagenesDurtox'>

                                <Row>
                                    <div className='contenedorFoto' >
                                        <img className="imagenProductoDurtox"
                                            src={require("../Img/durtox1.webp")}
                                            
                                            onClick={() => modalFoto(require("../Img/durtox1.webp"), 'Detector Durtox')}
                                            alt='Detector Durtox' />
                                    </div>
                                </Row>
                                <Row>
                                    <div className='contenedorFoto' >
                                        <img className="imagenProductoDurtox"
                                            src={require("../Img/durtox2.webp")}
                                            
                                            onClick={() => modalFoto(require("../Img/durtox2.webp"), 'Detector Durtox X')}
                                            alt='Detector Durtox X' />
                                    </div>
                                </Row>
                               
                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                            <div className='descripcionDurtox'>
                                <h2 className='nombreProductoDurtox'>Detector Durtox 4-20mA</h2>
                                <h5 className='tituloProductoDurtox'>Detectores 4-20mA por tecnología electroquímica para la detección de gases tóxicos y
                                    oxígeno.
                                </h5>
                                <p className='textoProductoDurtox'>
                                    En formato 4-20mA estándar con conexión a 3 hilos, compatible con cualquier sistema que disponga de entradas de
                                    este tipo. (Disponibles en versión RS485. VER SISTEMAS CENTRALIZADOS).
                                    <br />
                                    Existen dos tipos de envolvente:</p>
                                <h5 className='tituloProductoDurtox'>DURTOX – envolvente plástica IP65.</h5>
                                <p className='textoProductoDurtox'>Disponibles para los siguientes gases: CO, NH3, O2, NO2, H2S, NO, Cl2 y SO2.</p>
                                <h5 className='tituloProductoDurtox'>DURTOX-X – envolvente antideflagrante ATEX (Certif. LOM 10ATEX2076).</h5>
                                <p className='textoProductoDurtox'>Disponibles para los siguientes gases: CO, NH3, O2, NO2, H2S, NO, Cl2 y SO2. Para uso en atmósferas de gas
                                    explosivas y con un alto nivel de protección. Existe una versión del detector, también certificada ATEX,
                                    con un alto nivel de protección y destinada a usarse, además, en atmósferas de polvo explosivas.
                                    <br />
                                    DURTOX-X está provisto de un microprocesador de 12bit que permite el control total y preciso sobre el estado
                                    del sensor y su electrónica. También ofrece compensación térmica que consigue una respuesta correcta de cada
                                    uno de los sensores electroquímicos ante variaciones de temperatura, excepto para O2 ya que debido a su
                                    diferente funcionamiento no necesitan esta función. Incluye también: Testeo de hardware, Filtro digital
                                    basado en muestreos variables de los promedios de los valores del sensor y ajuste automático exacto del cero.

                                </p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12'>

                            <div className='contenedorNavDurtox'>
                                <Nav as="ul" className="flex-column">
                                    <Nav.Item className='linksArchivosDurtox' as="li" onClick={() => clicEnlacePdf('ficha')}>
                                        {logoPdf}
                                        Ficha Detector Durtox
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosDurtox' as="li" onClick={() => clicEnlacePdf('fichaX')}>
                                        {logoPdf}
                                        Ficha Detector Durtox X
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosDurtox' as="li" onClick={() => clicEnlacePdf('fichaCaja')}>
                                        {logoPdf}
                                        Ficha Caja de Derivación Atex
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosDurtox' as="li" onClick={() => clicEnlacePdf('fichaGranjas')}>
                                        {logoPdf}
                                        Ficha Durtox NH3 4-20mA IP65 Granjas
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosDurtox' as="li" onClick={() => clicEnlacePdf('fichaComercialGranjas')}>
                                        {logoPdf}
                                        Ficha Comercial Durtox NH3 4-20mA IP65 Granjas
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosDurtox' as="li" onClick={() => clicEnlacePdf('fichaH2')}>
                                        {logoPdf}
                                        Ficha Durtox H2 4-20mA
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosDurtox' as="li" onClick={() => clicEnlacePdf('manual')}>
                                        {logoPdf}
                                        Manual Durtox X
                                    </Nav.Item>

                                </Nav>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <Modal className='imgModalContainer' show={fotoSeleccionada !== null} onHide={() => setFotoSeleccionada(null)}>
                <Modal.Header closeButton>
                    {subtituloModal && <Modal.Title>{subtituloModal}</Modal.Title>}
                </Modal.Header>
                <Modal.Body>
                    {fotoSeleccionada && (<img src={fotoSeleccionada} alt="Foto Durtox" className="imgModal" />)}
                </Modal.Body>
            </Modal>

        </>
    );

}

