import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Row from 'react-bootstrap/Row'; import { MenuLateral } from '../MenuLateral/MenuLateral'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'; // Importa el icono faBars
import Nav from 'react-bootstrap/Nav';
import {
    fichaMaximetGMX100, fichaMaximetGMX101, fichaMaximetGMX200, fichaMaximetGMX300, fichaMaximetGMX301,
    fichaMaximetGMX400, fichaMaximetGMX500, fichaMaximetGMX501, fichaMaximetGMX531, fichaMaximetGMX541,
    fichaMaximetGMX550, fichaMaximetGMX551, fichaMaximetGMX600, fichaMaximetGMX700, fichaLufftWS600,
    manualMaximet, catalogoMaximet
} from '../../Archivos/estacionMeteorologica';
import './EstacionMeteorologica.css';

export function EstacionMeteorologica() {

    const [fotoSeleccionada, setFotoSeleccionada] = useState(null);
    const [subtituloModal, setSubtituloModal] = useState("");

    const modalFoto = (foto, subtitulo) => {
        setFotoSeleccionada(foto);
        setSubtituloModal(subtitulo)
    };

    const logoPdf = <FontAwesomeIcon icon={faFilePdf} size="xl" style={{ color: "#006c87", }} />

    const abrirPdf = (archivo) => {
        window.open(archivo, '_blank');
    };

    const archivosPDF = {
        ficha100: fichaMaximetGMX100,
        ficha101: fichaMaximetGMX101,
        ficha200: fichaMaximetGMX200,
        ficha300: fichaMaximetGMX300,
        ficha301: fichaMaximetGMX301,
        ficha400: fichaMaximetGMX400,
        ficha500: fichaMaximetGMX500,
        ficha501: fichaMaximetGMX501,
        ficha531: fichaMaximetGMX531,
        ficha541: fichaMaximetGMX541,
        ficha550: fichaMaximetGMX550,
        ficha551: fichaMaximetGMX551,
        ficha600: fichaMaximetGMX600,
        ficha700: fichaMaximetGMX700,
        fichaLufft: fichaLufftWS600,
        manual: manualMaximet,
        catalogo: catalogoMaximet
    };

    const clicEnlacePdf = (tipoArchivo) => {
        abrirPdf(archivosPDF[tipoArchivo]);
    };

    return (
        <>
            <div className="mainContainer">
                <MenuLateral />

                <div className='contenedorProducto'>
                    <div className='row'>
                        <div className='col-xl-3 col-lg-3 col-md-12 col-sm-12'>
                            <div className='imagenesEstacionMeteorologica'>

                                <Row>
                                    <div className='contenedorFoto' >
                                        <img className="imagenProductoEstacionMeteorologica"
                                            src={require("../Img/estacionMeteorologica.webp")}
                                            onClick={() => modalFoto(require("../Img/estacionMeteorologica.webp"), 'MaxiMet')}
                                            alt='MaxiMet' />
                                    </div>
                                </Row>

                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                            <div className='descripcionEstacionMeteorologica'>
                                <h2 className='nombreProductoEstacionMeteorologica'>Estaciones Meteorológicas Compactas</h2>
                                <h5 className='tituloProductoEstacionMeteorologica'>MaxiMet es una estación meteorológica compacta avanzada,
                                    diseñada y fabricada por Gill Instrumentos usando tecnología probada para medir parámetros
                                    meteorológicos y ambientales de acuerdo a las normas internacionales.</h5>
                                <p className='textoProductoEstacionMeteorologica'>
                                    MaxiMet incorpora todos los parámetros de medición que cumplen con las necesidades de los usuarios
                                    en aplicaciones exigentes donde el coste, la calidad y el rendimiento son esenciales.
                                    <br />
                                    Con características tales como el viento, precipitación, radiación solar, temperatura, humedad,
                                    presión, “Modo Eco”, GPS, brújula, Bluetooth y muchos más, MaxiMet es único en su capacidad de
                                    proporcionar el mayor número posible de parámetros a medir y de opciones de protocolo de salida.
                                    Además es fácil de instalar, fácil de usar y no requiere mantenimiento.
                                    <br />
                                    MaxiMet es la estación meteorológica adecuada para cualquier aplicación para aquellos clientes
                                    que buscan una estación meteorológica compacta, eficaz y fiable.
                                    <br />
                                    <br />
                                    Características (dependiendo del modelo a elegir):
                                    <br />
                                    <br />
                                    - Radiación solar (w/ m2).
                                    <br />
                                    - Horas de sol (hrs).
                                    <br />
                                    - Temperatura (° C / ° F / ° K).
                                    <br />
                                    - Humedad relativa (% de HR, g/m3, g/kg).
                                    <br />
                                    - Presión barométrica (hPa, bar, mm Hg).
                                    <br />
                                    - Temperatura de bulbo húmedo (° C / ° F / ° K).
                                    <br />
                                    - Humedad absoluta (g/m3).
                                    <br />
                                    - Densidad de aire (kg/m3).
                                    <br />
                                    - Precipitación (mm/hora, mm/total, mm/24 h, in/h, in/total, in/ 24 h).
                                    <br />
                                    - Velocidad del viento (m/s, km/h, mph, nudos, ft/min).
                                    <br />
                                    - Dirección del viento (°).
                                    <br />
                                    - Sensación térmica (° C / ° F / ° K).
                                    <br /><br />
                                    Con GPS (opcional):
                                    <br /><br />
                                    - Viento real /aparente.
                                    <br />
                                    - Altura sobre el nivel del mar (m).
                                    <br />
                                    - Latitud/Longitud.
                                    <br />
                                    - Salida / puesta del sol.
                                    <br />
                                    - Posición del sol.
                                    <br />
                                    - Crepúsculo.
                                    <br />
                                    - Presión MSL.
                                    <br /><br />
                                    Salidas: RS232, 422, 485 (ASCII), SDI-12, NMEA, MODBUS, analógica (opcional).



                                </p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12'>

                            <div className='contenedorNavEstacionMeteorologica'>
                                <Nav as="ul" className="flex-column">
                                    <Nav.Item className='linksArchivosEstacionMeteorologica' as="li" onClick={() => clicEnlacePdf('ficha100')}>
                                        {logoPdf}
                                        Ficha MaxiMet-GMX100
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosEstacionMeteorologica' as="li" onClick={() => clicEnlacePdf('ficha101')}>
                                        {logoPdf}
                                        Ficha MaxiMet-GMX101
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosEstacionMeteorologica' as="li" onClick={() => clicEnlacePdf('ficha200')}>
                                        {logoPdf}
                                        Ficha MaxiMet-GMX200
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosEstacionMeteorologica' as="li" onClick={() => clicEnlacePdf('ficha300')}>
                                        {logoPdf}
                                        Ficha MaxiMet-GMX300
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosEstacionMeteorologica' as="li" onClick={() => clicEnlacePdf('ficha301')}>
                                        {logoPdf}
                                        Ficha MaxiMet-GMX301
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosEstacionMeteorologica' as="li" onClick={() => clicEnlacePdf('ficha400')}>
                                        {logoPdf}
                                        Ficha MaxiMet-GMX400
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosEstacionMeteorologica' as="li" onClick={() => clicEnlacePdf('ficha500')}>
                                        {logoPdf}
                                        Ficha MaxiMet-GMX500
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosEstacionMeteorologica' as="li" onClick={() => clicEnlacePdf('ficha501')}>
                                        {logoPdf}
                                        Ficha MaxiMet-GMX501
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosEstacionMeteorologica' as="li" onClick={() => clicEnlacePdf('ficha550')}>
                                        {logoPdf}
                                        Ficha MaxiMet-GMX550
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosEstacionMeteorologica' as="li" onClick={() => clicEnlacePdf('ficha551')}>
                                        {logoPdf}
                                        Ficha MaxiMet-GMX551
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosEstacionMeteorologica' as="li" onClick={() => clicEnlacePdf('ficha600')}>
                                        {logoPdf}
                                        Ficha MaxiMet-GMX600
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosEstacionMeteorologica' as="li" onClick={() => clicEnlacePdf('ficha700')}>
                                        {logoPdf}
                                        Ficha MaxiMet-GMX700
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosEstacionMeteorologica' as="li" onClick={() => clicEnlacePdf('fichaLufft')}>
                                        {logoPdf}
                                        Ficha Lufft WS600
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosEstacionMeteorologica' as="li" onClick={() => clicEnlacePdf('manual')}>
                                        {logoPdf}
                                        Manual MaxiMet
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosEstacionMeteorologica' as="li" onClick={() => clicEnlacePdf('catalogo')}>
                                        {logoPdf}
                                        Catálogo MaxiMet
                                    </Nav.Item>
                                    


                                </Nav>
                            </div>
                        </div>
                    </div>

                </div>

            </div >

            <Modal className='imgModalContainer' show={fotoSeleccionada !== null} onHide={() => setFotoSeleccionada(null)}>
                <Modal.Header closeButton>
                    {subtituloModal && <Modal.Title>{subtituloModal}</Modal.Title>}
                </Modal.Header>
                <Modal.Body>
                    {fotoSeleccionada && (<img src={fotoSeleccionada} alt="Foto MaxiMet" className="imgModal" />)}
                </Modal.Body>
            </Modal>

        </>
    );

}

