import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Row from 'react-bootstrap/Row'; import { MenuLateral } from '../MenuLateral/MenuLateral'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'; // Importa el icono faBars
import Nav from 'react-bootstrap/Nav';
import { fichaCentralDurpark, fichaDetectorDurpark, folletoDurpark, manualDurpark, presentacionDurpark, homologacionDurpark, homologacionDurparkMini } from '../../Archivos/centralDurpark';
import './CentralDurpark.css';

export function CentralDurpark() {

    const [fotoSeleccionada, setFotoSeleccionada] = useState(null);
    const [subtituloModal, setSubtituloModal] = useState("");

    const modalFoto = (foto, subtitulo) => {
        setFotoSeleccionada(foto);
        setSubtituloModal(subtitulo)
    };

    const logoPdf = <FontAwesomeIcon icon={faFilePdf} size="xl" style={{ color: "#006c87", }} />
    // const urlArchivos = '../../Archivos/centralDurgas/fichaTecnicaCentralDurgas.pdf'

    const abrirPdf = (archivo) => {
        window.open(archivo, '_blank');
    };

    const archivosPDF = {
        ficha: fichaCentralDurpark,
        fichaDetector: fichaDetectorDurpark,
        manual: manualDurpark,
        folleto: folletoDurpark,
        presentacion: presentacionDurpark,
        homologacion: homologacionDurpark,
        homologacionMini: homologacionDurparkMini
    };

    const clicEnlacePdf = (tipoArchivo) => {
        abrirPdf(archivosPDF[tipoArchivo]);
    };

    return (
        <>
            <div className="mainContainer">
                <MenuLateral />

                <div className='contenedorProducto'>
                    <div className='row'>
                        <div className='col-xl-3 col-lg-3 col-md-12 col-sm-12'>
                            <div className='imagenesDurpark'>

                                <Row>
                                    <div className='contenedorFoto' >
                                        <img className="imagenProductoDurpark"
                                            src={require("../Img/CentralDurpark.webp")}
                                            
                                            onClick={() => modalFoto(require("../Img/CentralDurpark.webp"), 'Central Durpark')}
                                            alt='Central Durpark' />
                                    </div>
                                </Row>
                                <Row>
                                    <div className='contenedorFoto' >
                                        <img className="imagenProductoDurpark"
                                            src={require("../Img/CentralDurpark2.webp")}
                                            
                                            onClick={() => modalFoto(require("../Img/CentralDurpark2.webp"), 'Central Durpark Mini')}
                                            alt='Central Durpark Mini' />
                                    </div>
                                </Row>
                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                            <div className='descripcionDurpark'>
                                <h2 className='nombreProductoDurpark'>Central Durpark</h2>
                                <h5 className='tituloProductoDurpark'>Nuevo sistema de detección de CO y NO2 (en el mismo lazo) por sensor electroquímico de bajo coste,
                                    vida útil de hasta 5 años (CO) y 3 años (NO2), y amplias prestaciones.</h5>
                                <p className='textoProductoDurpark'>
                                    Especialmente diseñado para proteger aparcamientos subterráneos, talleres, garajes y pequeñas instalaciones. Comunicación a tres hilos
                                    y direccionable.
                                    <br />
                                    Cada detector se monitoriza individualmente, mostrando su estado en tiempo real en el display. Gran fiabilidad y precisión
                                    de ±1ppm (CO) y ±0,5ppm (NO2). Ofrece calibración automática del cero y ganancia mediante algoritmos de software,
                                    permitiendo incluso verificar la sensibilidad del sensor de CO sin necesidad de aplicar gas.
                                    <br />
                                    Incorpora una función especial que permite dejar detectores en prueba.
                                    Incluye la normativa española y la portuguesa en tres idiomas.
                                    Cada zona incorpora un display LCD que funciona de manera independiente.
                                    <br />
                                    Incorpora tres salidas conmutadas de relé libres de potencial y por lo tanto tres niveles independientes de actuación programables
                                    por zona, más una salida de avería general. Configuración de doble velocidad de ventilación.
                                    <br />
                                    Disponible en versión MINI de 16 detectores y en versión ampliable de 1 a 4 zonas y hasta 64 detectores.
                                    Conforme a la NORMA UNE 23.300:1984 según Certificado Nº LOM 14MOGA3084.
                                    <br />
                                    Certificación de conformidad (Homologación) del Ministerio de Industria, Energía y Turismo con las contraseñas CDM-0080010 (DURPARK)
                                    y CDM-0090010 (DURPARK Mini).
                                </p>
                            </div>

                        </div>
                        <div className='col-lg-3 col-md-12'>

                            <div className='contenedorNavDurpark'>
                                <Nav as="ul" className="flex-column">
                                    <Nav.Item className='linksArchivosDurpark' as="li" onClick={() => clicEnlacePdf('ficha')}>
                                        {logoPdf}
                                        Ficha central
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosDurpark' as="li" onClick={() => clicEnlacePdf('fichaDetector')}>
                                        {logoPdf}
                                        Ficha Detector
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosDurpark' as="li" onClick={() => clicEnlacePdf('manual')}>
                                        {logoPdf}
                                        Manual
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosDurpark' as="li" onClick={() => clicEnlacePdf('folleto')}>
                                        {logoPdf}
                                        Folleto
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosDurpark' as="li" onClick={() => clicEnlacePdf('presentacion')}>
                                        {logoPdf}
                                        Presentación
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosDurpark' as="li" onClick={() => clicEnlacePdf('homologacion')}>
                                        {logoPdf}
                                        Homologación Durpark
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosDurpark' as="li" onClick={() => clicEnlacePdf('homologacionMini')}>
                                        {logoPdf}
                                        Homologación Durpark Mini
                                    </Nav.Item>
                                </Nav>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <Modal className='imgModalContainer' show={fotoSeleccionada !== null} onHide={() => setFotoSeleccionada(null)}>
                <Modal.Header closeButton>
                    {subtituloModal && <Modal.Title>{subtituloModal}</Modal.Title>}
                </Modal.Header>
                <Modal.Body>
                    {fotoSeleccionada && (<img src={fotoSeleccionada} alt="Foto Central Durpark" className="imgModal" />)}
                </Modal.Body>
            </Modal>

        </>
    );

}

