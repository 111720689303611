import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Row from 'react-bootstrap/Row'; import { MenuLateral } from '../MenuLateral/MenuLateral'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'; // Importa el icono faBars
import Nav from 'react-bootstrap/Nav';
import { fichaSimeControl, manualSimeControl, graficoMultisite } from '../../Archivos/simeControl';
import './SimeControl.css';

export function SimeControl() {

    const [fotoSeleccionada, setFotoSeleccionada] = useState(null);
    const [subtituloModal, setSubtituloModal] = useState("");

    const modalFoto = (foto, subtitulo) => {
        setFotoSeleccionada(foto);
        setSubtituloModal(subtitulo)
    };

    const logoPdf = <FontAwesomeIcon icon={faFilePdf} size="xl" style={{ color: "#006c87", }} />

    const abrirPdf = (archivo) => {
        window.open(archivo, '_blank');
    };

    const archivosPDF = {
        ficha: fichaSimeControl,
        manual: manualSimeControl,
        grafico: graficoMultisite

    };

    const clicEnlacePdf = (tipoArchivo) => {
        abrirPdf(archivosPDF[tipoArchivo]);
    };

    return (
        <>
            <div className="mainContainer">
                <MenuLateral />

                <div className='contenedorProducto'>
                    <div className='row'>
                        <div className='col-xl-3 col-lg-3 col-md-12 col-sm-12'>
                            <div className='imagenesSimeControl'>

                                <Row>
                                    <div className='contenedorFoto' >
                                        <img className="imagenProductoSimeControl"
                                            src={require("../Img/simeControl.webp")}
                                            
                                            onClick={() => modalFoto(require("../Img/simeControl.webp"), 'SIME Control')}
                                            alt='SIME Control' />
                                    </div>
                                </Row>

                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                            <div className='descripcionSimeControl'>
                                <h2 className='nombreProductoSimeControl'>SIME Control</h2>
                                <h5 className='tituloProductoSimeControl'>El sistema SIME Control es la plataforma software de control, configuración
                                    y gestión para sistemas de megafonia y alarma por voz.

                                </h5>
                                <p className='textoProductoSimeControl'>
                                    SIME es una aplicación basada servicios web, por lo que no requiere una instalación de licencia en el cliente.
                                    <br /><br />
                                    Permite un control centralizado del sistema, control de volumen, el rutado de fuentes de audio o mensajes pregrabados 
                                    a las zonas o conjunto de zonas deseadas, la gestión de las funciones de emergencia y los perfiles del sistema.
                                    <br /><br />
                                    Características Generales:
                                    <br />
                                    - Control intuitivo y simple de todo el sistema.
                                    <br />
                                    - Distribución de contenidos de audio en la red.
                                    <br />
                                    - Acceso multiplataforma desde cualquier lugar.
                                    <br />
                                    - Control de acceso y seguridad.
                                    <br />
                                    - Integración con las tecnologías de terceros a través de protocolos estándar.

                                </p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12'>

                            <div className='contenedorNavSimeControl'>
                                <Nav as="ul" className="flex-column">
                                    <Nav.Item className='linksArchivosSimeControl' as="li" onClick={() => clicEnlacePdf('ficha')}>
                                        {logoPdf}
                                        Ficha SIME Control
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosSimeControl' as="li" onClick={() => clicEnlacePdf('manual')}>
                                        {logoPdf}
                                        Manual SIME Control
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosSimeControl' as="li" onClick={() => clicEnlacePdf('grafico')}>
                                        {logoPdf}
                                        Gráfico Multisite
                                    </Nav.Item>

                                </Nav>
                            </div>
                        </div>
                    </div>

                </div>

            </div >

            <Modal className='imgModalContainer' show={fotoSeleccionada !== null} onHide={() => setFotoSeleccionada(null)}>
                <Modal.Header closeButton>
                    {subtituloModal && <Modal.Title>{subtituloModal}</Modal.Title>}
                </Modal.Header>
                <Modal.Body>
                    {fotoSeleccionada && (<img src={fotoSeleccionada} alt="Foto SIME Control" className="imgModal" />)}
                </Modal.Body>
            </Modal>

        </>
    );

}

