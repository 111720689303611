import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Row from 'react-bootstrap/Row'; import { MenuLateral } from '../MenuLateral/MenuLateral'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'; // Importa el icono faBars
import Nav from 'react-bootstrap/Nav';
import { fichaSensor, carcasaAntiradiacion, manualSensor } from '../../Archivos/sensorHumedad';
import './SensorHumedad.css';

export function SensorHumedad() {

    const [fotoSeleccionada, setFotoSeleccionada] = useState(null);
    const [subtituloModal, setSubtituloModal] = useState("");

    const modalFoto = (foto, subtitulo) => {
        setFotoSeleccionada(foto);
        setSubtituloModal(subtitulo)
    };

    const logoPdf = <FontAwesomeIcon icon={faFilePdf} size="xl" style={{ color: "#006c87", }} />

    const abrirPdf = (archivo) => {
        window.open(archivo, '_blank');
    };

    const archivosPDF = {

        ficha: fichaSensor,
        carcasa: carcasaAntiradiacion,
        manual: manualSensor
    };

    const clicEnlacePdf = (tipoArchivo) => {
        abrirPdf(archivosPDF[tipoArchivo]);
    };

    return (
        <>
            <div className="mainContainer">
                <MenuLateral />

                <div className='contenedorProducto'>
                    <div className='row'>
                        <div className='col-xl-3 col-lg-3 col-md-12 col-sm-12'>
                            <div className='imagenesSensorHumedad'>

                                <Row>
                                    <div className='contenedorFoto' >
                                        <img className="imagenesProductoSensorHumedad"
                                            src={require("../Img/humedad.webp")}
                                            
                                            onClick={() => modalFoto(require("../Img/humedad.webp"), 'Sensor de humedad y temperatura')}
                                            alt='Sensor de humedad y temperatura' />
                                    </div>
                                </Row>

                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                            <div className='descripcionSensorHumedad'>
                                <h2 className='nombreProductoSensorHumedad'>Sensor de humedad y temperatura</h2>
                                <h5 className='tituloProductoSensorHumedad'>El transmisor EE210 por E + E Elektronik cumple con
                                    los requisitos más altos en las exigentes aplicaciones de control climático.</h5>
                                <p className='textoProductoSensorHumedad'>
                                    Además de alta precisión, medición de la humedad relativa y la temperatura, EE210 calcula la
                                    temperatura del punto de rocío, humedad absoluta y la proporción de mezcla.
                                    <br />
                                    Cuando este sensor se monta en el exterior, se debe instalar dentro de una carcasa anti-radiación
                                    para proteger los sensores de los rayos solares.

                                </p>
                            </div>
                        </div>


                        <div className='col-lg-3 col-md-12'>

                            <div className='contenedorNavSensorHumedad'>
                                <Nav as="ul" className="flex-column">
                                    <Nav.Item className='linksArchivosSensorHumedad' as="li" onClick={() => clicEnlacePdf('ficha')}>
                                        {logoPdf}
                                        Ficha Sensor T+G
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosSensorHumedad' as="li" onClick={() => clicEnlacePdf('carcasa')}>
                                        {logoPdf}
                                        Carcasa Antiradiación Sensor T+H
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosSensorHumedad' as="li" onClick={() => clicEnlacePdf('manual')}>
                                        {logoPdf}
                                        Manual Sensor T+H
                                    </Nav.Item>

                                </Nav>
                            </div>
                        </div>
                    </div>

                </div>

            </div >

            <Modal className='imgModalContainer' show={fotoSeleccionada !== null} onHide={() => setFotoSeleccionada(null)}>
                <Modal.Header closeButton>
                    {subtituloModal && <Modal.Title>{subtituloModal}</Modal.Title>}
                </Modal.Header>
                <Modal.Body>
                    {fotoSeleccionada && (<img src={fotoSeleccionada} alt="Foto Sensor de Humedad" className="imgModal" />)}
                </Modal.Body>
            </Modal>

        </>
    );

}

