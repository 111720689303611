import React from 'react';
import { MenuLateral } from '../MenuLateral/MenuLateral'
import { useNavigate } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import './GasesExplosivos.css';

export function GasesExplosivos() {

    const navigate = useNavigate();

    const irACentralDurgas = () => {
        navigate(`/centralDurgas`);
    };

    const irADetectores4_20maE = () => {
        navigate(`/detectores4_20maE`);
    };

    const irADetectoresAutonomosE = () => {
        navigate(`/detectoresAutonomosE`);
    };

    const irACable = () => {
        navigate(`/cableDeteccionLineal`);
    };

    return (
        <>
            <div className="mainContainer">
                <MenuLateral />
                <div className='contenedor'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='tituloGasesExplosivos'>
                                <h1>Gases explosivos</h1>
                            </div>

                        </div>

                    </div>

                    <div className='row'>
                        <div className='contenedorCardsExplosivos'>
                            <div className='row'>
                                <div className='col-xl-6 col-lg-6 col-md-12'>
                                    <Card>
                                        <Card.Img variant="durgasE" src={require('../Img/CentralDurgas.webp')} onClick={irACentralDurgas} style={{ cursor: 'pointer' }} alt='Central Durgas' fetchpriority='high' />
                                        <Card.Body>
                                            <Card.Title>Central Durgas</Card.Title>

                                        </Card.Body>

                                    </Card>
                                </div>
                                <div className='col-xl-6 col-lg-6 col-md-12'>
                                    <Card>
                                        <Card.Img variant="4_20E" src={require('../Img/durtex2.webp')} onClick={irADetectores4_20maE} style={{ cursor: 'pointer' }} alt='Detectores 4_20mA' fetchpriority='high' />
                                        <Card.Body>
                                            <Card.Title>Detectores 4_20mA</Card.Title>

                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-xl-6 col-lg-6 col-md-12'>
                                    <Card>
                                        <Card.Img variant="autonomosE" src={require('../Img/autonomos.webp')} onClick={irADetectoresAutonomosE} style={{ cursor: 'pointer' }} alt='Detectores autónomos' fetchpriority='high' />
                                        <Card.Body>
                                            <Card.Title>Detectores autónomos</Card.Title>

                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className='col-xl-6 col-lg-6 col-md-12'>
                                    <Card>
                                        <Card.Img variant="cable" src={require('../Img/cable2.webp')} onClick={irACable} style={{ cursor: 'pointer' }} alt='Cable de detección lineal' fetchpriority='high' />
                                        <Card.Body>
                                            <Card.Title>Cable de detección lineal</Card.Title>

                                        </Card.Body>
                                    </Card>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

