import React from 'react';
import { MenuLateral } from '../MenuLateral/MenuLateral'
import { useNavigate } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import './DeteccionDeGases.css';

export function DeteccionDeGases() {

    const navigate = useNavigate();

    const irAToxicos = () => {
        navigate(`/gasesToxicos`);
    };

    const irAExplosivos = () => {
        navigate(`/gasesExplosivos`);
    };

    return (
        <>

            <div className="mainContainer">
                <MenuLateral />

                <div className='contenedor'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='tituloGases'>
                                <h1>Detección de gases</h1>
                            </div>  

                        </div>

                    </div>

                    <div className='row'>

                        <div className='contenedorCardsDeteccionGases'>
                            <div className='col-xl-6 col-lg-6'>
                                <Card>
                                    <Card.Img variant="toxicos" src={require('../Img/gasesToxicos.webp')} onClick={irAToxicos} style={{ cursor: 'pointer' }} alt='Gases tóxicos' fetchpriority='high' />
                                    <Card.Body>
                                        <Card.Title>Gases tóxicos</Card.Title>

                                    </Card.Body>

                                </Card>
                            </div>
                            <div className='col-xl-6 col-lg-6'>
                                <Card>
                                    <Card.Img variant="explosivos" src={require('../Img/gasesExplosivos.webp')} onClick={irAExplosivos} style={{ cursor: 'pointer' }} alt='Gases explosivos' fetchpriority='high' />
                                    <Card.Body>
                                        <Card.Title>Gases explosivos</Card.Title>

                                    </Card.Body>
                                </Card>
                            </div>
                        </div>

                    </div>
                </div>
            </div >
        </>
    );

}

