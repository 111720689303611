// import React from 'react';
// import { NavLink } from 'react-router-dom';
// import { MenuLateralState } from './MenuLateralState';

// export function MenuLateralItems() {
//     const { subMenuAbierto, subSubMenuAbierto, subSubSubMenuAbierto, handleSubMenuChange, handleSubSubMenuChange, handleSubSubSubMenuChange } = MenuLateralState();

//     const items = [
//         {
//             texto: 'Productos',
//             subMenu: [
//                 {
//                     texto: 'Detección de gases',
//                     ruta: '/deteccionDeGases',
//                     subSubMenu: [
//                         {
//                             texto: 'Gases tóxicos',
//                             ruta: '/gasesToxicos',
//                             subSubSubMenu: [
//                                 {
//                                     texto:'Sistemas centralizados',
//                                     ruta: '/sistemasCentralizados'
//                                 },
//                                 {
//                                     texto:'Detectores 4_20mA',
//                                     ruta: '/detectores4_20mA'
//                                 },
//                                 {
//                                     texto:'Detectores autónomos',
//                                     ruta: '/detectoresAutonomos'
//                                 }
//                             ]
//                         },
//                         {
//                             texto: 'Gases explosivos',
//                             ruta: '/gasesExplosivos',
//                             subSubSubMenu: [
//                                 {
//                                     texto:'Central Durgas',
//                                     ruta: '/sistemasCentralizados'
//                                 },
//                                 {
//                                     texto:'Detectores 4_20mA',
//                                     ruta: '/detectores4_20maE'
//                                 },
//                                 {
//                                     texto:'Detectores autónomos',
//                                     ruta: '/detectoresAutonomosE'
//                                 },
//                                 {
//                                     texto:'Cable de detección lineal',
//                                     ruta: '/cableDeteccionLineal'
//                                 }
//                             ]
//                         }
//                     ]
//                 },
//                 {
//                     texto: 'Control ambiental y túneles',
//                     ruta: '/tuneles',
//                     subSubMenu: [
//                         {
//                             texto:'Detección de gases',
//                             ruta:'/deteccionGasesTunel'
//                         },
//                         {
//                             texto:'Opacímetro Sentry',
//                             ruta:'/opacimetroSentry'
//                         },
//                         {
//                             texto:'Sensores luminosos',
//                             ruta:'/sensoresLuminosos'
//                         },
//                         {
//                             texto:'Anemómetros',
//                             ruta:'/anemometros'
//                         },
//                         {
//                             texto:'Sensores atmosféricos',
//                             ruta:'/sensoresAtmosfericos'
//                         },
//                     ]
//                 },
//                 {
//                     texto: 'Estaciones meteorológicas compactas',
//                     ruta: '/estacionMeteorologica',
//                 },
//                 {
//                     texto: 'Megafonía',
//                     ruta: '/megafonia',
//                     subSubMenu: [
//                         {
//                             texto: 'Sistemas Certificados en -54',
//                             ruta: '/sistemasCertificadosEn-54',
//                             subSubSubMenu: [
//                                 {
//                                     texto:'NEO',
//                                     ruta: '/neo'
//                                 },
//                                 {
//                                     texto:'ONE 500',
//                                     ruta: '/one500'
//                                 },
//                                 {
//                                     texto:'Accesorios NEO y ONE',
//                                     ruta: '/accesoriosNeoYOne'
//                                 }
//                             ]
//                         },
//                         {
//                             texto: 'Altavoces',
//                             ruta: '/altavoces',
//                             subSubSubMenu: [
//                                 {
//                                     texto:'Certificados en -54',
//                                     ruta: '/altavocesCertificadosen54'
//                                 },
//                                 {
//                                     texto:'No Certificados',
//                                     ruta: '/altavocesNoCertificados'
//                                 }
//                             ]                            
//                         },
//                     ]
//                 },
//             ],
//         },
//         {
//             texto: 'Servicios',
//             subMenu: [
//                 {
//                     texto: 'Mantenimientos',
//                     ruta: '/mantenimientos',
//                 },
//                 {
//                     texto: 'Test de pruebas',
//                     ruta: '/testPruebas',
//                 },
//                 {
//                     texto: 'Puesta en marcha',
//                     ruta: '/puestaEnMarcha',
//                 },
//             ],
//         },
//         {
//             texto: 'Empresa',
//             subMenu: [
//                 {
//                     texto: '¿Quiénes somos?',
//                     ruta: '/empresa',
//                 }
//             ]
//         }
//     ];

//     return (
//         <ul className="menuLateralItems">
//             {items.map((item) => (
//                 <li key={item.texto}>
//                     <a
//                         href="#"
//                         onClick={() => handleSubMenuChange(item.texto)}
//                         className={subMenuAbierto === item.texto ? 'activo' : ''}
//                     >
//                         {item.texto}
//                     </a>
//                     {subMenuAbierto === item.texto && item.subMenu && (
//                         <ul className="subMenu">
//                             {item.subMenu.map((subMenuItem) => (
//                                 <li key={subMenuItem.texto}>
//                                     <a
//                                         href="#"
//                                         onClick={() => handleSubSubMenuChange(subMenuItem.texto)}
//                                         className={subSubMenuAbierto === subMenuItem.texto ? 'activo' : ''}
//                                     >
//                                         {subMenuItem.texto}
//                                     </a>
//                                     {subSubMenuAbierto === subMenuItem.texto && subMenuItem.subSubMenu && (
//                                         <ul className="subSubMenu">
//                                             {subMenuItem.subSubMenu.map((subSubMenuItem) => (
//                                                 <li key={subSubMenuItem.texto}>
//                                                     <a
//                                                         href="#"
//                                                         onClick={() => handleSubSubSubMenuChange(subSubMenuItem.texto)}
//                                                         className={subSubSubMenuAbierto === subSubMenuItem.texto ? 'activo' : ''}
//                                                     >
//                                                         {subSubMenuItem.texto}
//                                                     </a>
//                                                     {subSubSubMenuAbierto === subSubMenuItem.texto && subSubMenuItem.subSubSubMenu && (
//                                                         <ul className="subSubSubMenu">
//                                                             {subSubMenuItem.subSubSubMenu.map((subSubSubMenuItem) => (
//                                                                 <li key={subSubSubMenuItem.texto}>
//                                                                     <NavLink to={subSubSubMenuItem.ruta}>{subSubSubMenuItem.texto}</NavLink>
//                                                                 </li>
//                                                             ))}
//                                                         </ul>
//                                                     )}
//                                                 </li>
//                                             ))}
//                                         </ul>
//                                     )}
//                                 </li>
//                             ))}
//                         </ul>
//                     )}
//                 </li>
//             ))}
//         </ul>
//     );
// }

import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { MenuLateralState } from './MenuLateralState';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'; // Importa el icono de flecha hacia abajo


export function MenuLateralItems() {
    const { subMenuAbierto, subSubMenuAbierto, subSubSubMenuAbierto, productosMenuAbierto, handleSubMenuChange, handleSubSubMenuChange, handleSubSubSubMenuChange, handleProductosChange } = MenuLateralState();

    const items = [
        {
            texto: 'Productos',
            ruta: '', // Ruta para el elemento principal
            subMenu: [
                {
                    texto: 'Detección de gases',
                    ruta: '/deteccionDeGases',
                    subSubMenu: [
                        {
                            texto: 'Gases tóxicos',
                            ruta: '/gasesToxicos',
                            subSubSubMenu: [
                                {
                                    texto: 'Sistemas centralizados',
                                    ruta: '/sistemasCentralizados',
                                    productos: [
                                        {
                                            texto: 'Central Durgas',
                                            ruta: '/centralDurgas'
                                        },
                                        {
                                            texto: 'Central Durpark',
                                            ruta: '/centralDurpark'
                                        }
                                    ]
                                },
                                {
                                    texto: 'Detectores 4_20mA',
                                    ruta: '/detectores4_20mA',
                                    productos: [
                                        {
                                            texto: 'Sondeltox',
                                            ruta: '/sondeltox'
                                        },
                                        {
                                            texto: 'Detector Durtox 4-20mA',
                                            ruta: '/detectorDurtox'
                                        },
                                        {
                                            texto: 'Detector Direx 4-20mA',
                                            ruta: '/detectorDirex'
                                        },
                                    ]
                                },
                                {
                                    texto: 'Detectores autónomos',
                                    ruta: '/detectoresAutonomos',
                                    productos: [
                                        {
                                            texto: 'Standgas Pro',
                                            ruta: '/standgasPro'
                                        },
                                        {
                                            texto: 'Standgas Pro LCD TOX',
                                            ruta: '/standgasProLcdTox'
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            texto: 'Gases explosivos',
                            ruta: '/gasesExplosivos',
                            subSubSubMenu: [
                                {
                                    texto: 'Central Durgas',
                                    ruta: '/centralDurgas'
                                },
                                {
                                    texto: 'Detectores 4_20mA',
                                    ruta: '/detectores4_20maE',
                                    productos: [
                                        {
                                            texto: 'Detector Durtex',
                                            ruta: '/detectorDurtex'
                                        }
                                    ]
                                },
                                {
                                    texto: 'Detectores autónomos',
                                    ruta: '/detectoresAutonomosE',
                                    productos: [
                                        {
                                            texto: 'Standgas HC',
                                            ruta: '/standgasHc'
                                        },
                                        {
                                            texto: 'Standgas HC PRO',
                                            ruta: '/standgasHcPro'
                                        },
                                        {
                                            texto: 'Standgas Pro LCD EXP',
                                            ruta: '/standgasProLcdExp'
                                        }
                                    ]
                                },
                                {
                                    texto: 'Cable de detección lineal',
                                    ruta: '/cableDeteccionLineal'
                                }
                            ]
                        }
                    ]
                },
                {
                    texto: 'Control ambiental y túneles',
                    ruta: '/tuneles',
                    subSubMenu: [
                        {
                            texto: 'Detección de gases',
                            ruta: '/deteccionGasesTunel',
                            subSubSubMenuAbierto: [
                                {
                                    texto: 'Central Durgas',
                                    ruta: '/centralDurgas'
                                },
                                {
                                    texto: 'Sondeltox',
                                    ruta: '/sondeltox'
                                },
                                {
                                    texto: 'Detector Durtox 4-20mA',
                                    ruta: '/detectorDurtox'
                                }
                            ]
                        },
                        {
                            texto: 'Opacímetro Sentry',
                            ruta: '/opacimetroSentry'
                        },
                        {
                            texto: 'Sensores luminosos',
                            ruta: '/sensoresLuminosos',
                            subSubSubMenuAbierto: [
                                {
                                    texto: 'Luminancímetro',
                                    ruta: '/luminancimetro'
                                },
                                {
                                    texto: 'Luxómetro',
                                    ruta: '/luxometro'
                                }
                            ]
                        },
                        {
                            texto: 'Anemómetros',
                            ruta: '/anemometros',
                            subSubSubMenuAbierto: [
                                {
                                    texto: 'Hélices y conversor',
                                    ruta: '/helicesYConversor'
                                },
                                {
                                    texto: 'Ultrasónico',
                                    ruta: '/ultrasonico'
                                }
                            ]
                        },
                        {
                            texto: 'Sensores atmosféricos',
                            ruta: '/sensoresAtmosfericos',
                            subSubSubMenuAbierto: [
                                {
                                    texto: 'Sensor de visibilidad y tiempo presente',
                                    ruta: '/sensorVisibilidad'
                                },
                                {
                                    texto: 'Pluviómetro',
                                    ruta: '/pluviometro'
                                },
                                {
                                    texto: 'Sensor de humedad y temperatura',
                                    ruta: '/sensorHumedad'
                                },
                                {
                                    texto: 'Barómetro',
                                    ruta: '/barometro'
                                },
                                {
                                    texto: 'Piranómetro',
                                    ruta: '/piranometro'
                                },
                                {
                                    texto: 'Sensor de hielo en calzada',
                                    ruta: '/sensorHielo'
                                },
                            ]
                        },
                    ]
                },
                {
                    texto: 'Estaciones meteorológicas compactas',
                    ruta: '/estacionMeteorologica',
                },
                {
                    texto: 'Megafonía',
                    ruta: '/megafonia',
                    subSubMenu: [
                        {
                            texto: 'Sistemas Certificados en -54',
                            ruta: '/sistemasCertificadosEn-54',
                            subSubSubMenu: [
                                {
                                    texto: 'NEO',
                                    ruta: '/neo',
                                    productos: [
                                        {
                                            texto: 'NEO Unidad Principal',
                                            ruta: '/neoUnidadPrincipal'
                                        },
                                        {
                                            texto: 'NEO Extensión',
                                            ruta: '/neoExtension'
                                        },
                                        {
                                            texto: 'Accesorios NEO',
                                            ruta: '/accesoriosNeo'
                                        },
                                        {
                                            texto: 'Software de control',
                                            ruta: '/softwareControl'
                                        },
                                    ]
                                },
                                {
                                    texto: 'ONE 500',
                                    ruta: '/one500'
                                },
                                {
                                    texto: 'Accesorios NEO y ONE',
                                    ruta: '/accesoriosNeoYOne',
                                    productos: [
                                        {
                                            texto: 'MPS-8Z y MPS-8K',
                                            ruta: '/mps'
                                        },
                                        {
                                            texto: 'Selector de canal y volúmen VCC-64',
                                            ruta: '/selectorCanalYVolumen'
                                        },
                                        {
                                            texto: 'Panel de alarma VAP-1 y Extensión VAP 8K',
                                            ruta: '/panelDeAlarma'
                                        },
                                        {
                                            texto: 'Otros Accesorios',
                                            ruta: '/otrosAccesorios'
                                        },
                                    ]
                                }
                            ]
                        },
                        {
                            texto: 'Altavoces',
                            ruta: '/altavoces',
                            subSubSubMenu: [
                                {
                                    texto: 'Certificados en -54',
                                    ruta: '/altavocesCertificadosen54',
                                    productos: [
                                        {
                                            texto: 'Altavoces de techo o superficie',
                                            ruta: '/altavocesDeTechoOSuperficie'
                                        },
                                        {
                                            texto: 'Proyectores de sonido',
                                            ruta: '/proyectoresDeSonido'
                                        },
                                        {
                                            texto: 'Columnas acústicas',
                                            ruta: '/columnasAcusticas'
                                        },
                                    ]
                                },
                                {
                                    texto: 'No Certificados',
                                    ruta: '/altavocesNoCertificados',
                                    productos: [
                                        {
                                            texto: 'Altavoces de techo o superficie',
                                            ruta: '/altavocesDeTechoOSuperficieNoCertificados'
                                        },
                                        {
                                            texto: 'Cajas acústicas',
                                            ruta: '/cajasAcusticas'
                                        },
                                        {
                                            texto: 'Proyectores de sonido',
                                            ruta: '/proyectoresDeSonidoNoCertificados'
                                        },
                                        {
                                            texto: 'Columnas',
                                            ruta: '/columnasNoCertificadas'
                                        },
                                        {
                                            texto: 'Otros',
                                            ruta: '/otrosNoCertificados'
                                        },
                                    ]
                                }
                            ]
                        },
                    ]
                },
            ],
        },
        {
            texto: 'Servicios',
            ruta: '', // Ruta para el elemento principal
            subMenu: [
                {
                    texto: 'Mantenimientos',
                    ruta: '/mantenimientos',
                },
                {
                    texto: 'Test de pruebas',
                    ruta: '/testPruebas',
                },
                {
                    texto: 'Puesta en marcha',
                    ruta: '/puestaEnMarcha',
                },
            ],
        },
        {
            texto: 'Empresa',
            ruta: '/empresa', // Ruta para el elemento principal
            subMenu: [
                {
                    texto: '¿Quiénes somos?',
                    ruta: '/empresa',
                }
            ]
        }
    ];

    return (
        <ul className="menuLateralItems">
            {items.map((item) => (
                <li key={item.texto}>
                    <div onClick={() => handleSubMenuChange(item.texto)} className="menu-item">
                        <NavLink to={item.ruta} className="menu-text">{item.texto}</NavLink>
                        {item.subMenu && (
                            <span className={`arrow ${subMenuAbierto === item.texto ? 'active' : ''}`}>
                                <FontAwesomeIcon icon={faCaretDown} /> {/* Usa el icono de FontAwesome */}
                            </span>
                        )}
                    </div>
                    {subMenuAbierto === item.texto && item.subMenu && (
                        <ul className="subMenu">
                            {item.subMenu.map((subMenuItem) => (
                                <li key={subMenuItem.texto}>
                                    <div onClick={() => handleSubSubMenuChange(subMenuItem.texto)} className="menu-item">
                                        <NavLink to={subMenuItem.ruta} className="menu-text">{subMenuItem.texto}</NavLink>
                                        {subMenuItem.subSubMenu && (
                                            <span className={`arrow ${subSubMenuAbierto === subMenuItem.texto ? 'active' : ''}`}>
                                                <FontAwesomeIcon icon={faCaretDown} />
                                            </span>
                                        )}
                                    </div>
                                    {subSubMenuAbierto === subMenuItem.texto && subMenuItem.subSubMenu && (
                                        <ul className="subSubMenu">
                                            {subMenuItem.subSubMenu.map((subSubMenuItem) => (
                                                <li key={subSubMenuItem.texto}>
                                                    <div onClick={() => handleSubSubSubMenuChange(subSubMenuItem.texto)} className="menu-item">
                                                        <NavLink to={subSubMenuItem.ruta} className="menu-text">{subSubMenuItem.texto}</NavLink>
                                                        {subSubMenuItem.subSubSubMenu && (
                                                            <span className={`arrow ${subSubSubMenuAbierto === subSubMenuItem.texto ? 'active' : ''}`}>
                                                                <FontAwesomeIcon icon={faCaretDown} />
                                                            </span>
                                                        )}
                                                    </div>
                                                    {subSubSubMenuAbierto === subSubMenuItem.texto && subSubMenuItem.subSubSubMenu && (
                                                        <ul className="subSubSubMenu">
                                                            {subSubMenuItem.subSubSubMenu.map((subSubSubMenuItem) => (
                                                                <li key={subSubSubMenuItem.texto}>
                                                                    <div onClick={() => handleProductosChange(subSubSubMenuItem.texto)} className="menu-item">
                                                                        <NavLink to={subSubSubMenuItem.ruta} className="menu-text">{subSubSubMenuItem.texto}</NavLink>
                                                                        {subSubSubMenuItem.productos && (
                                                                            <span className={`arrow ${productosMenuAbierto === subSubSubMenuItem.texto ? 'active' : ''}`}>
                                                                                <FontAwesomeIcon icon={faCaretDown} />
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                    {productosMenuAbierto === subSubSubMenuItem.texto && subSubSubMenuItem.productos && (
                                                                        <ul className="productosMenu">
                                                                            {subSubSubMenuItem.productos.map((productosMenuItem) => (
                                                                                <li key={productosMenuItem.texto}>
                                                                                    <NavLink to={productosMenuItem.ruta}>{productosMenuItem.texto}</NavLink>
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    )}


                                                                    {/* <NavLink to={subSubSubMenuItem.ruta}>{subSubSubMenuItem.texto}</NavLink> */}

                                                                </li>
                                                            ))}
                                                        </ul>
                                                    )}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </li>
                            ))}
                        </ul>
                    )}
                </li>
            ))}
        </ul>
    );
}

