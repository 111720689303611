import React from 'react';
import { MenuLateral } from '../MenuLateral/MenuLateral'
import { useNavigate } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import './SistemasCentralizados.css';

export function SistemasCentralizados() {

    const navigate = useNavigate();

    const irACentralDurgas = () => {
        navigate(`/centralDurgas`);
    };

    const irACentralDurpark = () => {
        navigate(`/centralDurpark`);
    };

    return (
        <>
            <div className="mainContainer">
                <MenuLateral />

                <div className='contenedor'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='tituloSistCentralizados'>
                                <h1>Sistemas centralizados</h1>
                            </div>

                        </div>

                    </div>

                    <div className='row'>

                        <div className='contenedorCardsDur'>
                            <div className='row'>
                                <div className='col-xl-6 col-lg-6 col-md-12'>
                                    <Card>
                                        <Card.Img variant="durgas" src={require('../Img/CentralDurgas.webp')} onClick={irACentralDurgas} style={{ cursor: 'pointer' }} alt='Central Durgas' fetchpriority='high' />
                                        <Card.Body>
                                            <Card.Title className='tituloDurgas'>Central Durgas</Card.Title>

                                        </Card.Body>

                                    </Card>
                                </div>
                                <div className='col-xl-6 col-lg-6 col-md-12'>
                                    <Card>
                                        <Card.Img variant="durpark" src={require('../Img/CentralDurpark4.webp')} onClick={irACentralDurpark} style={{ cursor: 'pointer' }} alt='Central Durpark' fetchpriority='high' />
                                        <Card.Body>
                                            <Card.Title className='tituloDurpark'>Central Durpark</Card.Title>

                                        </Card.Body>
                                    </Card>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

