import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Row from 'react-bootstrap/Row'; import { MenuLateral } from '../MenuLateral/MenuLateral'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'; // Importa el icono faBars
import Nav from 'react-bootstrap/Nav';
import { fichaCI207t, fichaLS51t, fichaLM200 } from '../../Archivos/cajasAcusticas';
import './CajasAcusticas.css';

export function CajasAcusticas() {

    const [fotoSeleccionada, setFotoSeleccionada] = useState(null);
    const [subtituloModal, setSubtituloModal] = useState("");

    const modalFoto = (foto, subtitulo) => {
        setFotoSeleccionada(foto);
        setSubtituloModal(subtitulo)
    };

    const logoPdf = <FontAwesomeIcon icon={faFilePdf} size="xl" style={{ color: "#006c87", }} />

    const abrirPdf = (archivo) => {
        window.open(archivo, '_blank');
    };

    const archivosPDF = {
        fichaCI207t: fichaCI207t,
        fichaLS51t: fichaLS51t,
        fichaLM200: fichaLM200
    };

    const clicEnlacePdf = (tipoArchivo) => {
        abrirPdf(archivosPDF[tipoArchivo]);
    };

    return (
        <>
            <div className="mainContainer">
                <MenuLateral />

                <div className='contenedorProducto'>
                    <div className='row'>
                        <div className='col-xl-3 col-lg-3 col-md-12 col-sm-12'>
                            <div className='imagenesCajasAcusticas'>

                                <Row>
                                    <div className='contenedorFoto' >
                                        <img className="imagenProductoCajasAcusticas"
                                            src={require("../Img/ci-207t.webp")}
                                            
                                            onClick={() => modalFoto(require("../Img/ci-207t.webp"), 'CI-207T')}
                                            alt='CI-207T' />
                                    </div>
                                </Row>
                                <Row>
                                    <div className='contenedorFoto' >
                                        <img className="imagenProductoCajasAcusticas"
                                            src={require("../Img/ls-51t.webp")}
                                            
                                            onClick={() => modalFoto(require("../Img/ls-51t.webp"), 'LS-51T')}
                                            alt='LS-51T' />
                                    </div>
                                </Row>
                                <Row>
                                    <div className='contenedorFoto' >
                                        <img className="imagenProductoCajasAcusticas"
                                            src={require("../Img/lm-200.webp")}
                                            
                                            onClick={() => modalFoto(require("../Img/lm-200.webp"), 'LM-200')}
                                            alt='LM-200' />
                                    </div>
                                </Row>

                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                            <div className='descripcionCajasAcusticas'>
                                <h2 className='nombreProductoCajasAcusticas'>Cajas acústicas</h2>
                                <h5 className='tituloProductoCajasAcusticas'>
                                </h5>
                                <p className='textoProductoCajasAcusticas'>
                                    El CI-207T de LDA es un altavoz de altas prestaciones para instalaciones profesionales. Se compone de 2 woofers de 2” y 1
                                    tweeter de 0,5. Puede ser instalado tanto en superficie como en techo de chapa o escayola, por lo que incluye un marco
                                    metálico (extraíble) con de garras de sujeción para una rápida instalación.
                                    <br /><br />
                                    La LS-51T es una caja acústica de dos vías y alto rendimiento compuesta por un woofer de 5” y un tweeter de 1”, ajustados 
                                    mediante filtro paso alto de alta calidad que permiten conseguir una elevada sensibilidad o presión sonora, sin necesidad 
                                    de mucha potencia.
                                    <br /><br />
                                    Su diseño de líneas suaves, permite su instalación en cualquier arquitectura. Tiene protección IP 54 por lo que puede ser 
                                    instalada en exteriores.
                                    <br /><br />
                                    Permite una fácil instalación, gracias al diseño de fijación de soportes, sin necesidad de herramientas específicas. El 
                                    soporte incluido, permite ser instalada horizontal o verticalmente, y su orientación en 120º respecto a su eje vertical.
                                    <br /><br />
                                    La LM-200 es una caja acústica de 2 vías con doble bass réflex: woofer de 10″ y tweeter de 1.75” con filtro de cruce 
                                    de 6-12 dB/oct.
                                </p>

                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12'>

                            <div className='contenedorNavCajasAcusticas'>
                                <Nav as="ul" className="flex-column">
                                    <Nav.Item className='linksArchivosCajasAcusticas' as="li" onClick={() => clicEnlacePdf('fichaCI207t')}>
                                        {logoPdf}
                                        Ficha CI-207T
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosCajasAcusticas' as="li" onClick={() => clicEnlacePdf('fichaLS51t')}>
                                        {logoPdf}
                                        Ficha LS-51T
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosCajasAcusticas' as="li" onClick={() => clicEnlacePdf('fichaLM200')}>
                                        {logoPdf}
                                        Ficha LM-200
                                    </Nav.Item>

                                </Nav>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <Modal className='imgModalContainer' show={fotoSeleccionada !== null} onHide={() => setFotoSeleccionada(null)}>
                <Modal.Header closeButton>
                    {subtituloModal && <Modal.Title>{subtituloModal}</Modal.Title>}
                </Modal.Header>
                <Modal.Body>
                    {fotoSeleccionada && (<img src={fotoSeleccionada} alt="Foto Cajas acústicas" className="imgModal" />)}
                </Modal.Body>
            </Modal>

        </>
    );

}

