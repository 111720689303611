import React from 'react';
import { MenuLateral } from '../MenuLateral/MenuLateral'
import { useNavigate } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import './Megafonia.css';

export function Megafonia() {

    const navigate = useNavigate();

    const irSistemasCertificados54 = () => {
        navigate(`/sistemasCertificadosEn-54`);
    };

    const irAAltavoces = () => {
        navigate(`/altavoces`);
    };

    return (
        <>
            <div className="mainContainer">
                <MenuLateral />

                <div className='contenedor'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='tituloMegafonia'>
                                <h1>Megafonía</h1>
                            </div>

                        </div>

                    </div>

                    <div className='row'>

                        <div className='contenedorCardsMegafonia'>
                            <div className='row'>
                                <div className='col-xl-6 col-lg-6 col-md-12'>
                                    <Card>
                                        <Card.Img variant="sistCertificados" src={require('../Img/certificados-54.webp')} onClick={irSistemasCertificados54} style={{ cursor: 'pointer' }} alt='Sistemas certificados en -54' fetchpriority='high' />
                                        <Card.Body>
                                            <Card.Title className='tituloSistCertificados'>Sistemas Certificados en -54</Card.Title>

                                        </Card.Body>

                                    </Card>
                                </div>
                                <div className='col-xl-6 col-lg-6 col-md-12'>
                                    <Card>
                                        <Card.Img variant="altavoces1" src={require('../Img/altavoces.webp')} onClick={irAAltavoces} style={{ cursor: 'pointer' }} alt='Altavoces' fetchpriority='high' />
                                        <Card.Body>
                                            <Card.Title className='tituloAltavoces1'>Altavoces</Card.Title>

                                        </Card.Body>
                                    </Card>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

