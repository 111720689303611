import React from 'react';
import { MenuLateral } from '../MenuLateral/MenuLateral'
import { useNavigate } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import './DetectoresAutonomos.css';

export function DetectoresAutonomos() {

    const navigate = useNavigate();

    const irAStandgasPro = () => {
        navigate(`/standgasPro`);
    };

    const irAStandgasProLcdTox = () => {
        navigate(`/standgasProLcdTox`);
    };

    return (
        <>
            <div className="mainContainer">
                <MenuLateral />

                <div className='contenedor'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='tituloDetAutonomos'>
                                <h1>Detectores autónomos</h1>
                            </div>

                        </div>

                    </div>

                    <div className='row'>

                        <div className='contenedorCardsAutonomos'>
                            <div className='row'>
                                <div className='col-xl-6 col-lg-6 col-md-12'>
                                    <Card>
                                        <Card.Img variant="standgasPro" src={require('../Img/standgasPro.webp')} onClick={irAStandgasPro} style={{ cursor: 'pointer' }} alt='Standgas Pro' fetchpriority='high' />
                                        <Card.Body>
                                            <Card.Title className='tituloStandgasPro'>Standgas Pro</Card.Title>

                                        </Card.Body>

                                    </Card>
                                </div>
                                <div className='col-xl-6 col-lg-6 col-md-12'>
                                    <Card>
                                        <Card.Img variant="standgasProLcdTox" src={require('../Img/standgasProLcdTox.webp')} onClick={irAStandgasProLcdTox} style={{ cursor: 'pointer' }} alt='Standgas Pro LCD TOX' fetchpriority='high' />
                                        <Card.Body>
                                            <Card.Title className='tituloStandgasProLcdTox'>Standgas Pro LCD TOX</Card.Title>

                                        </Card.Body>
                                    </Card>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

