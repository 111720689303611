import React from 'react';
import { MenuLateral } from '../MenuLateral/MenuLateral'
import { useNavigate } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import './CertificadosEn54.css';

export function CertificadosEn54() {

    const navigate = useNavigate();

    const irANeo = () => {
        navigate(`/neo`);
    };

    const irAOne500 = () => {
        navigate(`/one500`);
    };

    const irAAccesoriosNeoYOne = () => {
        navigate(`/accesoriosNeoYOne`);
    };

    return (
        <>
            <div className="mainContainer">
                <MenuLateral />
                <div className='contenedor'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='tituloCertificados54'>
                                <h1>Sistemas certificados en -54</h1>
                            </div>

                        </div>

                    </div>

                    <div className='row'>
                        <div className='contenedorCertificados54'>
                            <div className='row'>
                                <div className='col-xl-4 col-lg-4 col-md-12'>
                                    <Card>
                                        <Card.Img variant="neo" src={require('../Img/neo.webp')} onClick={irANeo} style={{ cursor: 'pointer' }} alt='NEO' fetchpriority='high' />
                                        <Card.Body>
                                            <Card.Title className='tituloNeo'>NEO</Card.Title>

                                        </Card.Body>

                                    </Card>
                                </div>
                                <div className='col-xl-4 col-lg-4 col-md-12'>
                                    <Card>
                                        <Card.Img variant="one500" src={require('../Img/one500.webp')} onClick={irAOne500} style={{ cursor: 'pointer' }} alt='ONE 500' fetchpriority='high' />
                                        <Card.Body>
                                            <Card.Title className='tituloOne500'>ONE 500</Card.Title>

                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className='col-xl-4 col-lg-4 col-md-12'>
                                    <Card>
                                        <Card.Img variant="accesorios" src={require('../Img/accesorios.webp')} onClick={irAAccesoriosNeoYOne} style={{ cursor: 'pointer' }} alt='Accesorios NEO y ONE' fetchpriority='high' />
                                        <Card.Body>
                                            <Card.Title className='tituloAccesorios'>Accesorios NEO y ONE</Card.Title>

                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

