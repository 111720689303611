import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Row from 'react-bootstrap/Row'; import { MenuLateral } from '../MenuLateral/MenuLateral'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'; // Importa el icono faBars
import Nav from 'react-bootstrap/Nav';
import { fichaHelices, fichaConversor, conexionHelicesYConversor } from '../../Archivos/helicesYConversor';
import './HelicesYConversor.css';

export function HelicesYConversor() {

    const [fotoSeleccionada, setFotoSeleccionada] = useState(null);
    const [subtituloModal, setSubtituloModal] = useState("");

    const modalFoto = (foto, subtitulo) => {
        setFotoSeleccionada(foto);
        setSubtituloModal(subtitulo)
    };

    const logoPdf = <FontAwesomeIcon icon={faFilePdf} size="xl" style={{ color: "#006c87", }} />

    const abrirPdf = (archivo) => {
        window.open(archivo, '_blank');
    };

    const archivosPDF = {
        fichaHelices: fichaHelices,
        fichaConversor: fichaConversor,
        conexion: conexionHelicesYConversor
    };

    const clicEnlacePdf = (tipoArchivo) => {
        abrirPdf(archivosPDF[tipoArchivo]);
    };

    return (
        <>
            <div className="mainContainer">
                <MenuLateral />

                <div className='contenedorProducto'>
                    <div className='row'>
                        <div className='col-xl-3 col-lg-3 col-md-12 col-sm-12'>
                            <div className='imagenesHelicesYConversor'>

                                <Row>
                                    <div className='contenedorFoto' >
                                        <img className="imagenProductoHelicesYConversor"
                                            src={require("../Img/helices.webp")}
                                            
                                            onClick={() => modalFoto(require("../Img/helices.webp"), 'Hélices y conversor')}
                                            alt='Hélices y conversor' />
                                    </div>
                                </Row>

                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                            <div className='descripcionHelicesYConversor'>
                                <h2 className='nombreProductoHelicesYConversor'>Hélices y conversor</h2>
                                <h5 className='tituloProductoHelicesYConversor'>El anemómetro de hélice bidireccional (1468) transmite una señal de
                                    salida activa y analógica.</h5>
                                <p className='textoProductoHelicesYConversor'>
                                    No requiere alimentación auxiliar y tiene robustas palas de aluminio.
                                    <br />
                                    Como principales ventajas de todas las variantes, se puede señalar su alta resolución, sus valores de arranque
                                    especialmente bajos y un amplio rango de temperaturas de trabajo.
                                    
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12'>

                            <div className='contenedorNavHelicesYConversor'>
                                <Nav as="ul" className="flex-column">
                                    <Nav.Item className='linksArchivosHelicesYConversor' as="li" onClick={() => clicEnlacePdf('fichaHelices')}>
                                        {logoPdf}
                                        Ficha Helices
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosHelicesYConversor' as="li" onClick={() => clicEnlacePdf('fichaConversor')}>
                                        {logoPdf}
                                        Ficha Conversor
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosHelicesYConversor' as="li" onClick={() => clicEnlacePdf('conexion')}>
                                        {logoPdf}
                                        Esq. Conexión A. Helice y Conversor 4104
                                    </Nav.Item>

                                </Nav>
                            </div>
                        </div>
                    </div>

                </div>

            </div >

            <Modal className='imgModalContainer' show={fotoSeleccionada !== null} onHide={() => setFotoSeleccionada(null)}>
                <Modal.Header closeButton>
                    {subtituloModal && <Modal.Title>{subtituloModal}</Modal.Title>}
                </Modal.Header>
                <Modal.Body>
                    {fotoSeleccionada && (<img src={fotoSeleccionada} alt="Foto Helices y Conversor" className="imgModal" />)}
                </Modal.Body>
            </Modal>

        </>
    );

}

