import React from 'react';
import { MenuLateral } from '../MenuLateral/MenuLateral'
import { useNavigate } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import './SensoresLuminosos.css';

export function SensoresLuminosos() {

    const navigate = useNavigate();

    const irALuminancimetro = () => {
        navigate(`/luminancimetro`);
    };

    const irALuxometro = () => {
        navigate(`/luxometro`);
    };

    return (
        <>
            <div className="mainContainer">
                <MenuLateral />

                <div className='contenedor'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='tituloSensoresLuminosos'>
                                <h1>Sensores luminosos</h1>
                            </div>

                        </div>

                    </div>

                    <div className='row'>

                        <div className='contenedorCardsLuminosos'>
                            <div className='row'>
                                <div className='col-xl-6 col-lg-6 col-md-12'>
                                    <Card>
                                        <Card.Img variant="luminancimetro" src={require('../Img/luminancimetro.webp')} onClick={irALuminancimetro} style={{ cursor: 'pointer' }} alt='Luminancímetro' fetchpriority='high' />
                                        <Card.Body>
                                            <Card.Title className='tituloLuminancimetro'>Luminancímetro</Card.Title>

                                        </Card.Body>

                                    </Card>
                                </div>
                                <div className='col-xl-6 col-lg-6 col-md-12'>
                                    <Card>
                                        <Card.Img variant="luxometro" src={require('../Img/luxometro.webp')} onClick={irALuxometro} style={{ cursor: 'pointer' }} alt='Luxómetro' fetchpriority='high' />
                                        <Card.Body>
                                            <Card.Title className='tituloLuxometro'>Luxómetro</Card.Title>

                                        </Card.Body>
                                    </Card>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

