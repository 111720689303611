import React from 'react';
import { MenuLateral } from '../MenuLateral/MenuLateral'
import { useNavigate } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import './Tuneles.css';

export function Tuneles() {

    const navigate = useNavigate();

    const irAGasesTunel = () => {
        navigate(`/deteccionGasesTunel`);
    };

    const irAOpacimetro = () => {
        navigate(`/opacimetroSentry`);
    };

    const irALuminosos = () => {
        navigate(`/sensoresLuminosos`);
    };

    const irAAnemometros = () => {
        navigate(`/anemometros`);
    };

    const irAAtmosfericos = () => {
        navigate(`/sensoresAtmosfericos`);
    };

    return (
        <>
            <div className="mainContainer">
                <MenuLateral />

                <div className='contenedor'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='tituloTunel'>
                                <h1>Control ambiental y túneles</h1>
                            </div>

                        </div>

                    </div>

                    <div className='row'>

                        <div className='contenedorCardsTuneles'>
                            <div className='row'>
                                <div className='col-xl-4 col-lg-6 col-md-12'>
                                    <Card>
                                        <Card.Img variant="gasesTunel" src={require('../Img/gasesTunel.webp')} onClick={irAGasesTunel} style={{ cursor: 'pointer' }} alt='Detección de gases' fetchpriority='high' />
                                        <Card.Body>
                                            <Card.Title className='tituloTuneles'>Detección de gases</Card.Title>

                                        </Card.Body>

                                    </Card>
                                </div>
                                <div className='col-xl-4 col-lg-6 col-md-12'>
                                    <Card>
                                        <Card.Img variant="opacimetro" src={require('../Img/opacimetro.webp')} onClick={irAOpacimetro} style={{ cursor: 'pointer' }} alt='Opacímetro Sentry' fetchpriority='high' />
                                        <Card.Body>
                                            <Card.Title className='tituloTuneles'>Opacímetro Sentry</Card.Title>

                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className='col-xl-4 col-lg-6 col-md-12'>
                                    <Card>
                                        <Card.Img variant="luminosos" src={require('../Img/luminosos.webp')} onClick={irALuminosos} style={{ cursor: 'pointer' }} alt='Sensores luminosos' fetchpriority='high' />
                                        <Card.Body>
                                            <Card.Title className='tituloTuneles'>Sensores luminosos</Card.Title>

                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className='col-xl-4 col-lg-6 col-md-12'>
                                    <Card>
                                        <Card.Img variant="anemometros" src={require('../Img/anemometros.webp')} onClick={irAAnemometros} style={{ cursor: 'pointer' }} alt='Anemómetros' fetchpriority='high' />
                                        <Card.Body>
                                            <Card.Title className='tituloTuneles'>Anemómetros</Card.Title>

                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className='col-xl-4 col-lg-6 col-md-12'>
                                    <Card>
                                        <Card.Img variant="atmosfericos" src={require('../Img/atmosfericos.webp')} onClick={irAAtmosfericos} style={{ cursor: 'pointer' }} alt='Sensores atmosféricos' fetchpriority='high' />
                                        <Card.Body>
                                            <Card.Title className='tituloTuneles'>Sensores atmosféricos</Card.Title>

                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

