import { MenuLateral } from '../MenuLateral/MenuLateral'
import './TestPruebas.css';

export function TestPruebas() {

    const tab = <>&nbsp;&nbsp;&nbsp;&nbsp;</>;

    return (
        <>
            <div className="contenedorGeneral">
                <MenuLateral />
                <div className="container">
                    <div className="testPruebas">
                        <div >
                            <div className='row'>
                                <div className='col-12'>
                                    <h1 className='nombreTestPruebas'>Test de Pruebas</h1>
                                </div>
                            </div>
                        </div>

                        <div >

                            <p className='textoTestPruebas' fetchpriority='high' >{tab} En DTGAS, nos especializamos en la realización de pruebas a detectores de gases con un enfoque dedicado a
                                garantizar la seguridad y el rendimiento óptimo de sus sistemas. Con un equipo de profesionales altamente capacitados y tecnología
                                de vanguardia, nos comprometemos a ofrecer servicios de prueba de la más alta calidad.</p>
                            <p className='textoTestPruebas'>{tab} Realizamos pruebas rigurosas para evaluar la funcionalidad, precisión y capacidad de respuesta de sus
                                detectores de gases. Nuestra metodología garantiza resultados precisos y confiables.</p>
                            <p className='textoTestPruebas'>{tab} Proporcionamos informes detallados de las pruebas realizadas, ofreciendo transparencia y documentación
                                completa sobre el estado de sus equipos. Estos informes son herramientas valiosas para la toma de decisiones informadas.</p>
                            
                        </div>
                    </div>

                </div>
            </div>
        </>
    );

}