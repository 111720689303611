import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Row from 'react-bootstrap/Row'; import { MenuLateral } from '../MenuLateral/MenuLateral'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'; // Importa el icono faBars
import Nav from 'react-bootstrap/Nav';
import { fichaStandgasHcPro, manualStandgasHcPro, presentacionStandgasHcPro, folletoStandgasHcPro } from '../../Archivos/standgasHcPro';
import './StandgasHcPro.css';

export function StandgasHcPro() {

    const [fotoSeleccionada, setFotoSeleccionada] = useState(null);
    const [subtituloModal, setSubtituloModal] = useState("");

    const modalFoto = (foto, subtitulo) => {
        setFotoSeleccionada(foto);
        setSubtituloModal(subtitulo)
    };

    const logoPdf = <FontAwesomeIcon icon={faFilePdf} size="xl" style={{ color: "#006c87", }} />

    const abrirPdf = (archivo) => {
        window.open(archivo, '_blank');
    };

    const archivosPDF = {
        ficha: fichaStandgasHcPro,
        manual: manualStandgasHcPro,
        presentacion: presentacionStandgasHcPro,
        folleto: folletoStandgasHcPro
    };

    const clicEnlacePdf = (tipoArchivo) => {
        abrirPdf(archivosPDF[tipoArchivo]);
    };

    return (
        <>
            <div className="mainContainer">
                <MenuLateral />

                <div className='contenedorProducto'>
                    <div className='row'>
                        <div className='col-xl-3 col-lg-3 col-md-12 col-sm-12'>
                            <div className='imagenesStandgasHcPro'>

                                <Row>
                                    <div className='contenedorFoto' >
                                        <img className="imagenProductoStandgasHcPro"
                                            src={require("../Img/standgasHcPro.webp")}
                                            
                                            onClick={() => modalFoto(require("../Img/standgasHcPro.webp"), 'Standgas HC PRO')}
                                            alt='Standgas HC PRO' />
                                    </div>
                                </Row>

                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                            <div className='descripcionStandgasHcPro'>
                                <h2 className='nombreProductoStandgasHcPro'>Standgas HC PRO</h2>
                                <h5 className='tituloProductoStandgasHcPro'>STANDGAS HC PRO es un detector autónomo por
                                    tecnología catalítica para la detección de gases explosivos provisto de relé.</h5>
                                <p className='textoProductoStandgasHcPro'>
                                    Funcionamiento autónomo: no requiere central.
                                    <br />
                                    Gases disponibles: metano-gas natural, butano-propano, hidrógeno, heptano, hexano, 
                                    pentano, metanol, estireno, etano, etanol, etileno, propileno, acetona, amoniaco, 
                                    ciclohexano, ciclopentano, dioxano, acetato de butilo, acetato de etilo, ácido acético, 
                                    alcohol isobutílico, alcohol isopropílico, decano, benceno, octano, metil etil cetona, 
                                    -butanona-, nonano, propanol, tolueno, xileno, queroseno. Para otros gases consultar 
                                    disponibilidad.
                                    <br />
                                    Permite la selección automática del gas a detectar, de entre cualquiera de los gases 
                                    disponibles, mediante protocolo establecido con un jumper.
                                    <br />
                                    Rango de detección de 0 a 100% L.I.E.
                                    <br />
                                    Indicación óptica de estados y avería de sensor.
                                    <br />
                                    1 salida de relé de alarma programable entre 2 niveles (20% LIE / 50% LIE), desconexión 
                                    instantánea/retardada, programación retardo de desconexión y estado inicial del relé en 
                                    reposo.
                                    <br />
                                    Alimentación 10V a 30V DC.
                                    <br />
                                    Sensor y compensador en encapsulados independientes que aseguran una mayor linealidad y 
                                    estabilidad.
                                    <br />
                                    Detector con filtro sinterizado.
                                    <br />
                                    Calibrados en fábrica con gas patrón. No necesitan ser re-calibrados en la instalación.
                                    <br />
                                    Sencillas operaciones de verificación y mantenimiento por un sólo operario.
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12'>

                            <div className='contenedorNavStandgasHcPro'>
                                <Nav as="ul" className="flex-column">
                                    <Nav.Item className='linksArchivosStandgasHcPro' as="li" onClick={() => clicEnlacePdf('ficha')}>
                                        {logoPdf}
                                        Ficha Standgas Hc Pro
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosStandgasHcPro' as="li" onClick={() => clicEnlacePdf('manual')}>
                                        {logoPdf}
                                        Manual Standgas Hc Pro
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosStandgasHcPro' as="li" onClick={() => clicEnlacePdf('presentacion')}>
                                        {logoPdf}
                                        Presentación Standgas Hc Pro
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosStandgasHcPro' as="li" onClick={() => clicEnlacePdf('folleto')}>
                                        {logoPdf}
                                        Folleto Standgas Hc Pro
                                    </Nav.Item>

                                </Nav>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <Modal className='imgModalContainer' show={fotoSeleccionada !== null} onHide={() => setFotoSeleccionada(null)}>
                <Modal.Header closeButton>
                    {subtituloModal && <Modal.Title>{subtituloModal}</Modal.Title>}
                </Modal.Header>
                <Modal.Body>
                    {fotoSeleccionada && (<img src={fotoSeleccionada} alt="Foto Standgas Hc Pro" className="imgModal" />)}
                </Modal.Body>
            </Modal>

        </>
    );

}

