import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Row from 'react-bootstrap/Row'; import { MenuLateral } from '../MenuLateral/MenuLateral'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'; // Importa el icono faBars
import Nav from 'react-bootstrap/Nav';
import { fichaLam, fichaBar, fichaMpa, manual } from '../../Archivos/barometro';
import './Barometro.css';

export function Barometro() {

    const [fotoSeleccionada, setFotoSeleccionada] = useState(null);
    const [subtituloModal, setSubtituloModal] = useState("");

    const modalFoto = (foto, subtitulo) => {
        setFotoSeleccionada(foto);
        setSubtituloModal(subtitulo)
    };

    const logoPdf = <FontAwesomeIcon icon={faFilePdf} size="xl" style={{ color: "#006c87", }} />

    const abrirPdf = (archivo) => {
        window.open(archivo, '_blank');
    };

    const archivosPDF = {

        fichaLam: fichaLam,
        fichaBar: fichaBar,
        fichaMpa: fichaMpa,
        manual: manual
    };

    const clicEnlacePdf = (tipoArchivo) => {
        abrirPdf(archivosPDF[tipoArchivo]);
    };

    return (
        <>
            <div className="mainContainer">
                <MenuLateral />

                <div className='contenedorProducto'>
                    <div className='row'>
                        <div className='col-xl-3 col-lg-3 col-md-12 col-sm-12'>
                            <div className='imagenesBarometro'>

                                <Row>
                                    <div className='contenedorFoto' >
                                        <img className="imagenesProductoBarometro"
                                            src={require("../Img/barometro.webp")}
                                            
                                            onClick={() => modalFoto(require("../Img/barometro.webp"), 'Barómetro')}
                                            alt='Barómetro' />
                                    </div>
                                </Row>

                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                            <div className='descripcionBarometro'>
                                <h2 className='nombreProductoBarometro'>Barómetro</h2>
                                <h5 className='tituloProductoBarometro'>Los barómetros electrónicos con salida analógica usan un
                                    sensor piezorresistivo que brinda una medida extremadamente precisa y estable de la presión
                                    atmosférica, con excelente repetibilidad, baja histéresis y buen comportamiento frente a la
                                    temperatura.</h5>
                                <p className='textoProductoBarometro'>
                                    La señal de salida del sensor está condicionada para proveer un voltaje o corriente de salida
                                    linealmente proporcional a la presión atmosférica.
                                    <br />
                                    Los transmisores están listos para su uso, pues han sido calibrados en fábrica.

                                </p>
                            </div>
                        </div>


                        <div className='col-lg-3 col-md-12'>

                            <div className='contenedorNavBarometro'>
                                <Nav as="ul" className="flex-column">
                                    <Nav.Item className='linksArchivosBarometro' as="li" onClick={() => clicEnlacePdf('fichaLam')}>
                                        {logoPdf}
                                        Ficha Barometro Lambrecht
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosBarometro' as="li" onClick={() => clicEnlacePdf('fichaBar')}>
                                        {logoPdf}
                                        Ficha Barometro Bar
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosBarometro' as="li" onClick={() => clicEnlacePdf('fichaMpa')}>
                                        {logoPdf}
                                        Ficha Barometro Mpa
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosBarometro' as="li" onClick={() => clicEnlacePdf('manual')}>
                                        {logoPdf}
                                        Manual Barometro Lambrecht
                                    </Nav.Item>

                                </Nav>
                            </div>
                        </div>
                    </div>

                </div>

            </div >

            <Modal className='imgModalContainer' show={fotoSeleccionada !== null} onHide={() => setFotoSeleccionada(null)}>
                <Modal.Header closeButton>
                    {subtituloModal && <Modal.Title>{subtituloModal}</Modal.Title>}
                </Modal.Header>
                <Modal.Body>
                    {fotoSeleccionada && (<img src={fotoSeleccionada} alt="Foto Barometro" className="imgModal" />)}
                </Modal.Body>
            </Modal>

        </>
    );

}

