import React, { useState } from 'react'; // Asegúrate de importar useState
import { Link } from 'react-router-dom';
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import './Header.css';

export function Header() {

    const [menuOpen, setMenuOpen] = useState(false); // Estado para controlar si el menú está abierto

    const toggleMenu = () => {
        setMenuOpen(!menuOpen); // Cambia el estado del menú
    };

    const closeMenu = () => {
        setMenuOpen(false);
    };

    return (

        <>
            <header>
                <div className='contenedorHeader'>
                    <div className='row'>
                        <div className='col-lg-3 col-md-3 col-sm-10'>
                            <div className="logo">
                                <Link to="/" onClick={closeMenu}>
                                    <img src={require('../Img/final3.png')} alt="logo_empresa" fetchpriority='high' />
                                </Link>
                            </div>
                        </div>
                        <div className='col-lg-9 col-md-9 col-sm-2'>

                            <div className='menu'>
                                <Navbar collapseOnSelect expand="lg">

                                    <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={toggleMenu} />
                                    <Navbar.Collapse id="responsive-navbar-nav" className={`justify-content-md-end ${menuOpen ? 'menu-open' : ''}`} >
                                        <Nav className="mr-auto">
                                            <Nav.Link href="/" onClick={closeMenu}>Inicio</Nav.Link>

                                            <NavDropdown title="Productos" id="collasible-nav-dropdown">
                                                <NavDropdown.Item href="/deteccionDeGases" onClick={closeMenu}>Detección de gases</NavDropdown.Item>
                                                <NavDropdown.Item href="/tuneles" onClick={closeMenu}>Túneles</NavDropdown.Item>
                                                <NavDropdown.Item href="/estacionMeteorologica" onClick={closeMenu}>Estaciones Meteorológicas Compactas</NavDropdown.Item>
                                                <NavDropdown.Item href="/megafonia" onClick={closeMenu}>
                                                    Megafonía
                                                </NavDropdown.Item>

                                            </NavDropdown>

                                            <NavDropdown title="Servicios" id="collasible-nav-dropdown">
                                                <NavDropdown.Item href="/mantenimientos" onClick={closeMenu}>Mantenimientos</NavDropdown.Item>
                                                <NavDropdown.Item href="/testPruebas" onClick={closeMenu}>Test de pruebas</NavDropdown.Item>
                                                <NavDropdown.Item href="/puestaEnMarcha" onClick={closeMenu}>Puesta en marcha</NavDropdown.Item>

                                            </NavDropdown>

                                            <Nav.Link href="/empresa" onClick={closeMenu}>Empresa</Nav.Link>
                                            <Nav.Link href="/contacto" onClick={closeMenu}>Contacto</Nav.Link>
                                        </Nav>
                                        
                                    </Navbar.Collapse>
                                </Navbar>
                            </div>
                        </div>
                    </div>
                </div>


            </header>
        </>

    );
}