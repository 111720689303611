import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Row from 'react-bootstrap/Row'; import { MenuLateral } from '../MenuLateral/MenuLateral'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'; // Importa el icono faBars
import Nav from 'react-bootstrap/Nav';
import { fichaNeoPrincipal, manualNeoPrincipal, presentacionNeo, certificadoEn54 } from '../../Archivos/neoUnidadPrincipal';
import './NeoUnidadPrincipal.css';

export function NeoUnidadPrincipal() {

    const [fotoSeleccionada, setFotoSeleccionada] = useState(null);
    const [subtituloModal, setSubtituloModal] = useState("");

    const modalFoto = (foto, subtitulo) => {
        setFotoSeleccionada(foto);
        setSubtituloModal(subtitulo)
    };

    const logoPdf = <FontAwesomeIcon icon={faFilePdf} size="xl" style={{ color: "#006c87", }} />

    const abrirPdf = (archivo) => {
        window.open(archivo, '_blank');
    };

    const archivosPDF = {
        ficha: fichaNeoPrincipal,
        manual: manualNeoPrincipal,
        presentacion: presentacionNeo,
        certificado: certificadoEn54

    };

    const clicEnlacePdf = (tipoArchivo) => {
        abrirPdf(archivosPDF[tipoArchivo]);
    };

    return (
        <>
            <div className="mainContainer">
                <MenuLateral />

                <div className='contenedorProducto'>
                    <div className='row'>
                        <div className='col-xl-3 col-lg-3 col-md-12 col-sm-12'>
                            <div className='imagenesNeoUnidadPrincipal'>

                                <Row>
                                    <div className='contenedorFoto' >
                                        <img className="imagenProductoNeoUnidadPrincipal"
                                            src={require("../Img/neoUnidadPrincipal.webp")}
                                            
                                            onClick={() => modalFoto(require("../Img/neoUnidadPrincipal.webp"), 'NEO Unidad Principal')}
                                            alt='NEO Unidad Principal' />
                                    </div>
                                </Row>

                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                            <div className='descripcionNeoUnidadPrincipal'>
                                <h2 className='nombreProductoNeoUnidadPrincipal'>NEO Unidad Principal</h2>
                                <h5 className='tituloProductoNeoUnidadPrincipal'>NEO es un sistema de megafonia y evacuación por voz
                                    certificado EN 54-16 diseñado para instalaciones medianas y grandes.</h5>
                                <p className='textoProductoNeoUnidadPrincipal'>
                                    Se trata de un sistema compacto todo-en-uno con conexiones plug-and-play que reduce costes y
                                    tiempo facilitando todos los procedimientos de instalacion, mantenimiento y operativa en cualquier 
                                    edificio.
                                    <br />
                                    Una sola unidad cubre hasta 8 zonas de amplificacion independientes, con eventos configurables y el 
                                    uso de protocolos Ethernet, Cobranet y Flexnet para enviar y recibir audio desde la red de datos sin 
                                    necesidad de cableado especial.
                                    <br /><br />
                                    Dispone de:
                                    <br />
                                    - Entrada de Alimentación de Reserva 24V.
                                    <br />
                                    - 8 Amplificadores clase D 120W.
                                    <br />
                                    - Interfaz por cierres de contacto y por RS-485 con panel de detección de incendios.
                                    <br />
                                    - Supervisión de línea y amplificador de reserva.
                                    <br />
                                    - 8 Salidas 24V para control de atenuadores.
                                    <br />
                                    - 8 Entradas para terminador de Línea.
                                    <br />
                                    - Bus de Micrófono y panel remoto.
                                    <br />
                                    - 14 cierres GPIO para integraciones.
                                    <br />
                                    - 8 Salidas de Audio preamplificadas.
                                    <br />
                                    - 5 Entradas de Audio analógico balanceado.
                                    <br />
                                    - Triple interfaz Ethernet.
                                    <br />
                                    - Puerto RS-485 para integraciones.
                                    <br />
                                    - Reproductor Dual de mensajes Pregrabados.
                                    <br />
                                    - Pantalla Táctil de 4,3” a color.
                                    <br />
                                    - Matriz Digital de Audio.
                                    <br />
                                    - Micrófono de Emergencias supervisado.
                                    <br />
                                    - Altavoz Monitor.
                                

                                </p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12'>

                            <div className='contenedorNavNeoUnidadPrincipal'>
                                <Nav as="ul" className="flex-column">
                                    <Nav.Item className='linksArchivosNeoUnidadPrincipal' as="li" onClick={() => clicEnlacePdf('ficha')}>
                                        {logoPdf}
                                        Ficha NEO Unidad Principal
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosNeoUnidadPrincipal' as="li" onClick={() => clicEnlacePdf('manual')}>
                                        {logoPdf}
                                        Manual Neo Unidad Principal
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosNeoUnidadPrincipal' as="li" onClick={() => clicEnlacePdf('presentacion')}>
                                        {logoPdf}
                                        Presentación NEO Unidad Principal
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosNeoUnidadPrincipal' as="li" onClick={() => clicEnlacePdf('certificado')}>
                                        {logoPdf}
                                        Certificado en 54-16
                                    </Nav.Item>


                                </Nav>
                            </div>
                        </div>
                    </div>

                </div>

            </div >

            <Modal className='imgModalContainer' show={fotoSeleccionada !== null} onHide={() => setFotoSeleccionada(null)}>
                <Modal.Header closeButton>
                    {subtituloModal && <Modal.Title>{subtituloModal}</Modal.Title>}
                </Modal.Header>
                <Modal.Body>
                    {fotoSeleccionada && (<img src={fotoSeleccionada} alt="Foto Neo Unidad Principal" className="imgModal" />)}
                </Modal.Body>
            </Modal>

        </>
    );

}

