import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Row from 'react-bootstrap/Row'; import { MenuLateral } from '../MenuLateral/MenuLateral'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'; // Importa el icono faBars
import Nav from 'react-bootstrap/Nav';
import { fichaZes22, fichaTfl1, fichaA1, manualZes22, manualA1 } from '../../Archivos/otrosAccesorios';
import './OtrosAccesorios.css';

export function OtrosAccesorios() {

    const [fotoSeleccionada, setFotoSeleccionada] = useState(null);
    const [subtituloModal, setSubtituloModal] = useState("");

    const modalFoto = (foto, subtitulo) => {
        setFotoSeleccionada(foto);
        setSubtituloModal(subtitulo)
    };

    const logoPdf = <FontAwesomeIcon icon={faFilePdf} size="xl" style={{ color: "#006c87", }} />

    const abrirPdf = (archivo) => {
        window.open(archivo, '_blank');
    };

    const archivosPDF = {
        fichaZes22: fichaZes22,
        fichaTfl1: fichaTfl1,
        fichaA1: fichaA1,
        manualZes22: manualZes22,
        manualA1: manualA1

    };

    const clicEnlacePdf = (tipoArchivo) => {
        abrirPdf(archivosPDF[tipoArchivo]);
    };

    return (
        <>
            <div className="mainContainer">
                <MenuLateral />

                <div className='contenedorProducto'>
                    <div className='row'>
                        <div className='col-xl-3 col-lg-3 col-md-12 col-sm-12'>
                            <div className='imagenesOtrosAccesorios'>

                                <Row>
                                    <div className='contenedorFoto' >
                                        <img className="imagenProductoOtrosAccesorios"
                                            src={require("../Img/zes22.webp")}
                                            
                                            onClick={() => modalFoto(require("../Img/zes22.webp"), 'ZES-22')}
                                            alt='ZES-22' />
                                    </div>
                                </Row>
                                <Row>
                                    <div className='contenedorFoto' >
                                        <img className="imagenProductoOtrosAccesorios"
                                            src={require("../Img/tfl1.webp")}
                                            
                                            onClick={() => modalFoto(require("../Img/tfl1.webp"), 'TFL-1')}
                                            alt='TFL-1' />
                                    </div>
                                </Row>
                                <Row>
                                    <div className='contenedorFoto' >
                                        <img className="imagenProductoOtrosAccesorios"
                                            src={require("../Img/a1.webp")}
                                            
                                            onClick={() => modalFoto(require("../Img/a1.webp"), 'A1')}
                                            alt='A1' />
                                    </div>
                                </Row>

                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                            <div className='descripcionOtrosAccesorios'>
                                <h2 className='nombreProductoOtrosAccesorios'>Otros accesorios</h2>
                                <h5 className='tituloProductoOtrosAccesorios'>Matriz Digital ZES-22
                                </h5>
                                <p className='textoProductoOtrosAccesorios'>
                                    El procesador digital de audio ZES-22 dispone de 4 entradas o salidas de audio totalmente configurables que ofrecen
                                    excelentes prestaciones mediante el uso de conversores A/D y D/A de alta calidad. La sensibilidad de entrada es
                                    ajustable y los niveles de salida estandarizados para señal balanceada 1Vp.
                                    <br /><br />
                                    Ofrece un rango dinámico ampliado y control de ganancia.
                                    <br />
                                    El audio procedente de cualquiera de las entradas o salidas puede tratarse internamente mediante procesado DSP que
                                    incluye controles de nivel, ecualización paramétrica, compresor/limitador.
                                </p>

                                <h5 className='tituloProductoOtrosAccesorios'> Terminador de línea TFL
                                </h5>
                                <p className='textoProductoOtrosAccesorios'>
                                    El dispositivo de final de línea de LDA proporciona una medición precisa de la impedancia de la línea de altavoces,
                                    con el fin de probar la integridad de la conexión entre los altavoces y el sistema PA / VA.
                                    <br /><br />
                                    Es un dispositivo pequeño, muy fácil de instalar en líneas de 100V o 70V, tiene un consumo de energía bajo y permite
                                    que varios dispositivos se puedan conectar a la misma línea de altavoces.

                                </p>
                                <h5 className='tituloProductoOtrosAccesorios'> Pupitre microfónico A1
                                </h5>
                                <p className='textoProductoOtrosAccesorios'>
                                    Pupitre microfónico de 1 zona con altas prestaciones que cuenta con indicadores luminosos para palabra concedida/línea
                                    ocupada y cápsula activa mediante aro luminoso.
                                    <br /><br />
                                    Gracias al microcontrolador que incorpora, es capaz de trabajar en diferentes sistemas y con diferentes modos de 
                                    funcionamiento: pulsador en modo on/off, o pulsar para hablar, comunicación RS-485 ó TTL mediante 2 hilos, tono de 
                                    pre-aviso para iniciar/cortar comunicación y selección de niveles TTL de control.

                                </p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12'>

                            <div className='contenedorNavOtrosAccesorios'>
                                <Nav as="ul" className="flex-column">
                                    <Nav.Item className='linksArchivosOtrosAccesorios' as="li" onClick={() => clicEnlacePdf('fichaZes22')}>
                                        {logoPdf}
                                        Ficha VAP-1
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosOtrosAccesorios' as="li" onClick={() => clicEnlacePdf('fichaTfl1')}>
                                        {logoPdf}
                                        Ficha VAP-8K
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosOtrosAccesorios' as="li" onClick={() => clicEnlacePdf('fichaA1')}>
                                        {logoPdf}
                                        Ficha Rack 4
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosOtrosAccesorios' as="li" onClick={() => clicEnlacePdf('manualZes22')}>
                                        {logoPdf}
                                        Ficha Rack 6
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosOtrosAccesorios' as="li" onClick={() => clicEnlacePdf('manualA1')}>
                                        {logoPdf}
                                        Manual VAP-1
                                    </Nav.Item>

                                </Nav>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <Modal className='imgModalContainer' show={fotoSeleccionada !== null} onHide={() => setFotoSeleccionada(null)}>
                <Modal.Header closeButton>
                    {subtituloModal && <Modal.Title>{subtituloModal}</Modal.Title>}
                </Modal.Header>
                <Modal.Body>
                    {fotoSeleccionada && (<img src={fotoSeleccionada} alt="Foto Otros Accesorios" className="imgModal" />)}
                </Modal.Body>
            </Modal>

        </>
    );

}

