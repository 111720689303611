import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Row from 'react-bootstrap/Row'; import { MenuLateral } from '../MenuLateral/MenuLateral'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'; // Importa el icono faBars
import Nav from 'react-bootstrap/Nav';
import { fichaSimeCalendar, manualSimeCalendar, graficoMultisite } from '../../Archivos/simeCalendar';
import './SimeCalendar.css';

export function SimeCalendar() {

    const [fotoSeleccionada, setFotoSeleccionada] = useState(null);
    const [subtituloModal, setSubtituloModal] = useState("");

    const modalFoto = (foto, subtitulo) => {
        setFotoSeleccionada(foto);
        setSubtituloModal(subtitulo)
    };

    const logoPdf = <FontAwesomeIcon icon={faFilePdf} size="xl" style={{ color: "#006c87", }} />

    const abrirPdf = (archivo) => {
        window.open(archivo, '_blank');
    };

    const archivosPDF = {
        ficha: fichaSimeCalendar,
        manual: manualSimeCalendar,
        grafico: graficoMultisite

    };

    const clicEnlacePdf = (tipoArchivo) => {
        abrirPdf(archivosPDF[tipoArchivo]);
    };

    return (
        <>
            <div className="mainContainer">
                <MenuLateral />

                <div className='contenedorProducto'>
                    <div className='row'>
                        <div className='col-xl-3 col-lg-3 col-md-12 col-sm-12'>
                            <div className='imagenesSimeCalendar'>

                                <Row>
                                    <div className='contenedorFoto' >
                                        <img className="imagenProductoSimeCalendar"
                                            src={require("../Img/simeCalendar.webp")}
                                            
                                            onClick={() => modalFoto(require("../Img/simeCalendar.webp"), 'SIME Calendar')}
                                            alt='SIME Calendar' />
                                    </div>
                                </Row>

                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                            <div className='descripcionSimeCalendar'>
                                <h2 className='nombreProductoSimeCalendar'>SIME Calendar</h2>
                                <h5 className='tituloProductoSimeCalendar'>El software SIME Calendar es un módulo de SIME que permite la programación de
                                    eventos por fecha y hora (ya sean listas musicales en MP3, mensajes pregrabados, sirenas, etc.) dentro de las zonas
                                    elegidas del sistema local.

                                </h5>
                                <p className='textoProductoSimeCalendar'>
                                    En sistemas multisite, permite gestionar la programación de los diferentes nodos, creando un sistema de distribución de
                                    contenidos de audio a través de redes IP.
                                    <br /><br />
                                    Características Generales:
                                    <br />
                                    - Automatización de eventos por fecha y hora.
                                    <br />
                                    - Distribución de contenidos de audio.
                                    <br />
                                    - Visor de eventos por calendario.
                                    <br />
                                    - Creación de listas de reproducción.
                                    <br />
                                    - Integración con SIME Control.

                                </p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12'>

                            <div className='contenedorNavSimeCalendar'>
                                <Nav as="ul" className="flex-column">
                                    <Nav.Item className='linksArchivosSimeCalendar' as="li" onClick={() => clicEnlacePdf('ficha')}>
                                        {logoPdf}
                                        Ficha SIME Calendar
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosSimeCalendar' as="li" onClick={() => clicEnlacePdf('manual')}>
                                        {logoPdf}
                                        Manual SIME Calendar
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosSimeCalendar' as="li" onClick={() => clicEnlacePdf('grafico')}>
                                        {logoPdf}
                                        Gráfico Multisite
                                    </Nav.Item>

                                </Nav>
                            </div>
                        </div>
                    </div>

                </div>

            </div >

            <Modal className='imgModalContainer' show={fotoSeleccionada !== null} onHide={() => setFotoSeleccionada(null)}>
                <Modal.Header closeButton>
                    {subtituloModal && <Modal.Title>{subtituloModal}</Modal.Title>}
                </Modal.Header>
                <Modal.Body>
                    {fotoSeleccionada && (<img src={fotoSeleccionada} alt="Foto SIME Calendar" className="imgModal" />)}
                </Modal.Body>
            </Modal>

        </>
    );

}

