import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Row from 'react-bootstrap/Row'; import { MenuLateral } from '../MenuLateral/MenuLateral'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'; // Importa el icono faBars
import Nav from 'react-bootstrap/Nav';
import { fichaMPS8Z, fichaMPS8K, manualMPS8Z } from '../../Archivos/MPS';
import './MPS.css';

export function MPS() {

    const [fotoSeleccionada, setFotoSeleccionada] = useState(null);
    const [subtituloModal, setSubtituloModal] = useState("");

    const modalFoto = (foto, subtitulo) => {
        setFotoSeleccionada(foto);
        setSubtituloModal(subtitulo)
    };

    const logoPdf = <FontAwesomeIcon icon={faFilePdf} size="xl" style={{ color: "#006c87", }} />

    const abrirPdf = (archivo) => {
        window.open(archivo, '_blank');
    };

    const archivosPDF = {
        ficha8Z: fichaMPS8Z,
        ficha8K: fichaMPS8K,
        manual: manualMPS8Z

    };

    const clicEnlacePdf = (tipoArchivo) => {
        abrirPdf(archivosPDF[tipoArchivo]);
    };

    return (
        <>
            <div className="mainContainer">
                <MenuLateral />

                <div className='contenedorProducto'>
                    <div className='row'>
                        <div className='col-xl-3 col-lg-3 col-md-12 col-sm-12'>
                            <div className='imagenesMPS'>

                                <Row>
                                    <div className='contenedorFoto' >
                                        <img className="imagenProductoMPS"
                                            src={require("../Img/MPS-8Z.webp")}
                                            
                                            onClick={() => modalFoto(require("../Img/MPS-8Z.webp"), 'MPS-8Z')}
                                            alt='MPS-8Z' />
                                    </div>
                                </Row>
                                <Row>
                                    <div className='contenedorFoto' >
                                        <img className="imagenProductoMPS"
                                            src={require("../Img/MPS-8K.webp")}
                                            
                                            onClick={() => modalFoto(require("../Img/MPS-8K.webp"), 'MPS-8K')}
                                            alt='MPS-8K' />
                                    </div>
                                </Row>

                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                            <div className='descripcionMPS'>
                                <h2 className='nombreProductoMPS'>MPS-8Z y MPS-8K</h2>
                                <h5 className='tituloProductoMPS'>El MPS-8Z es un micrófono multizona de altas prestaciones para el sistema
                                    de evacuación por voz y megafonía NEO.

                                </h5>
                                <p className='textoProductoMPS'>
                                    Hasta 8 Micrófonos MPS-8Z se pueden conectar en una configuración de bus através del ASCI bus del sistema de NEO, utilizando
                                    un cable CAT5. El ASCI bus permite una distancia máxima de 1000m y ofrece niveles de prioridad entre los dispositivos conectados
                                    en bus. El MPS puede ser monitorizado y configurado de forma remota.
                                    <br />
                                    Cuenta con 8 botones para un máximo de 8 zonas, pudiendo ser expandido con el teclado MPS-8K. Dispone de funciones como memoria
                                    de selección, autobloqueo y grabación de voz para re-llamada. Además, incluye un botón de Eventos que permite una amplia variedad
                                    de funciones del sistema tales como el lanzamiento de mensajes pregrabados o carga de presets.
                                    <br />
                                    El procesamiento de sonido se ha configurado para obtener resultados de alta calidad en la paginacion de la voz, en términos de
                                    distorsión, sensibilidad, el ancho de banda y la relación de señal/ ruido. Tiene un chasis de hierro para proporcionar una
                                    estabilidad y protección contra daños superior. Todos los botones están diseñados para un uso intensivo.
                                </p>

                                <h5 className='tituloProductoMPS'>El MPS-8K es un módulo de expansión para el micro MPS-8Z.

                                </h5>

                                <p className='textoProductoMPS'>
                                    Añade 8 botones programables desde el software de configuración NEO Configurator que pueden ser asignados a diferentes zonas.
                                    <br />
                                    Dispone de tapa transparente para nombres de zonas. Se pueden conectar hasta 5 unidades juntas.
                                </p>
                            </div>

                        </div>
                        <div className='col-lg-3 col-md-12'>

                            <div className='contenedorNavMPS'>
                                <Nav as="ul" className="flex-column">
                                    <Nav.Item className='linksArchivosMPS' as="li" onClick={() => clicEnlacePdf('ficha8Z')}>
                                        {logoPdf}
                                        Ficha MPS-8Z
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosMPS' as="li" onClick={() => clicEnlacePdf('ficha8K')}>
                                        {logoPdf}
                                        Ficha MPS-8K
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosMPS' as="li" onClick={() => clicEnlacePdf('manual')}>
                                        {logoPdf}
                                        Manual MPS-8Z
                                    </Nav.Item>

                                </Nav>
                            </div>
                        </div>
                    </div>

                </div>

            </div >

            <Modal className='imgModalContainer' show={fotoSeleccionada !== null} onHide={() => setFotoSeleccionada(null)}>
                <Modal.Header closeButton>
                    {subtituloModal && <Modal.Title>{subtituloModal}</Modal.Title>}
                </Modal.Header>
                <Modal.Body>
                    {fotoSeleccionada && (<img src={fotoSeleccionada} alt="Foto MPS" className="imgModal" />)}
                </Modal.Body>
            </Modal>

        </>
    );

}

