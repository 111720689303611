import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Row from 'react-bootstrap/Row'; import { MenuLateral } from '../MenuLateral/MenuLateral'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'; // Importa el icono faBars
import Nav from 'react-bootstrap/Nav';
import { fichaSC32t, fichaCH42t, fichaCS42t, fichaSC85t, fichaSC95t, fichaDS60t } from '../../Archivos/altavocesTechoNoCert';
import './AltavocesTechoNoCert.css';

export function AltavocesTechoNoCert() {

    const [fotoSeleccionada, setFotoSeleccionada] = useState(null);
    const [subtituloModal, setSubtituloModal] = useState("");

    const modalFoto = (foto, subtitulo) => {
        setFotoSeleccionada(foto);
        setSubtituloModal(subtitulo)
    };

    const logoPdf = <FontAwesomeIcon icon={faFilePdf} size="xl" style={{ color: "#006c87", }} />

    const abrirPdf = (archivo) => {
        window.open(archivo, '_blank');
    };

    const archivosPDF = {
        fichaSC32t: fichaSC32t,
        fichaCH42t: fichaCH42t,
        fichaCS42t: fichaCS42t,
        fichaSC85t: fichaSC85t,
        fichaSC95t: fichaSC95t,
        fichaDS60t: fichaDS60t

    };

    const clicEnlacePdf = (tipoArchivo) => {
        abrirPdf(archivosPDF[tipoArchivo]);
    };

    return (
        <>
            <div className="mainContainer">
                <MenuLateral />

                <div className='contenedorProducto'>
                    <div className='row'>
                        <div className='col-xl-3 col-lg-3 col-md-12 col-sm-12'>
                            <div className='imagenesAltavocesTechoNoCert'>

                                <Row>
                                    <div className='contenedorFoto' >
                                        <img className="imagenProductoAltavocesTechoNoCert"
                                            src={require("../Img/sc-32t.webp")}
                                            
                                            onClick={() => modalFoto(require("../Img/sc-32t.webp"), 'SC-32T')}
                                            alt='SC-32t' />
                                    </div>
                                </Row>
                                <Row>
                                    <div className='contenedorFoto' >
                                        <img className="imagenProductoAltavocesTechoNoCert"
                                            src={require("../Img/sc-85t.webp")}
                                            
                                            onClick={() => modalFoto(require("../Img/sc-85t.webp"), 'SC-85T')}
                                            alt='SC-85t' />
                                    </div>
                                </Row>
                                <Row>
                                    <div className='contenedorFoto' >
                                        <img className="imagenProductoAltavocesTechoNoCert"
                                            src={require("../Img/ds-60t.webp")}
                                            
                                            onClick={() => modalFoto(require("../Img/ds-60t.webp"), 'DS-60T')}
                                            alt='DS-60t' />
                                    </div>
                                </Row>

                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                            <div className='descripcionAltavocesTechoNoCert'>
                                <h2 className='nombreProductoAltavocesTechoNoCert'>Altavoces de techo o superficie</h2>
                                <h5 className='tituloProductoAltavocesTechoNoCert'>Altavoz de techo CH-42TN
                                </h5>
                                <p className='textoProductoAltavocesTechoNoCert'>
                                    El altavoz de techo EN 54-24 de 5″ para líneas de 70/100V que proporciona la protección necesaria para que en caso de
                                    fuego, los daños en el altavoz no causen fallos en el circuito de altavoces al que estén conectados.
                                    <br /><br />
                                    Manteniendo de esta forma la integridad del sistema al asegurar que los altavoces estan siguen conectados al mismo
                                    circuito y así poder seguir emitiendo los mensajes de alerta.

                                </p>

                                <h5 className='tituloProductoAltavocesTechoNoCert'> Altavoz coaxial de techo SC-95TN
                                </h5>
                                <p className='textoProductoAltavocesTechoNoCert'>
                                    El SC-95TN es un altavoz coaxial de techo de 24W de alto rendimiento. Su amplio rango de frecuencia, baja distorsión
                                    y alto nivel de presión sonora proporcionan un sonido e Inteligibilidad de voz excelentes. Su moderno perfil bajo y
                                    diseño en rojo y blanco hacen que combine fácilmente en la mayoría de las instalaciones, como hoteles, centros
                                    comerciales, salas de conferencia o centros de exhibiciones.
                                    <br /><br />
                                    Este altavoz cumple con la normativa para alarma por voz EN54-24 que asegura que, en caso de incendio, los daños en
                                    el altavoz no provocan fallos en el circuito al que está conectado.

                                </p>
                                <h5 className='tituloProductoAltavocesTechoNoCert'> Altavoz de superficie DS-60TN
                                </h5>
                                <p className='textoProductoAltavocesTechoNoCert'>
                                    Altavoz de 4” de superficie de 6W fabricado en metal. Su diseño en blanco permite mimetizarse con la mayoría de 
                                    espacios y es un altavoz ideal para distribución de sonido en diversos tipos de instalaciones como centros comerciales, 
                                    hoteles, salas de conferencias, cines, fábricas, salas de exposiciones, etc.
                                    
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12'>

                            <div className='contenedorNavAltavocesTechoNoCert'>
                                <Nav as="ul" className="flex-column">
                                    <Nav.Item className='linksArchivosAltavocesTechoNoCert' as="li" onClick={() => clicEnlacePdf('fichaSC32t')}>
                                        {logoPdf}
                                        Ficha SC-32T
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosAltavocesTechoNoCert' as="li" onClick={() => clicEnlacePdf('fichaCH42t')}>
                                        {logoPdf}
                                        Ficha CH-42T
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosAltavocesTechoNoCert' as="li" onClick={() => clicEnlacePdf('fichaCS42t')}>
                                        {logoPdf}
                                        Ficha CS42T
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosAltavocesTechoNoCert' as="li" onClick={() => clicEnlacePdf('fichaSC85t')}>
                                        {logoPdf}
                                        Ficha SC-85T
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosAltavocesTechoNoCert' as="li" onClick={() => clicEnlacePdf('fichaSC95t')}>
                                        {logoPdf}
                                        Manual SC-95T
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosAltavocesTechoNoCert' as="li" onClick={() => clicEnlacePdf('fichaDS60t')}>
                                        {logoPdf}
                                        Ficha DS-60T
                                    </Nav.Item>

                                </Nav>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <Modal className='imgModalContainer' show={fotoSeleccionada !== null} onHide={() => setFotoSeleccionada(null)}>
                <Modal.Header closeButton>
                    {subtituloModal && <Modal.Title>{subtituloModal}</Modal.Title>}
                </Modal.Header>
                <Modal.Body>
                    {fotoSeleccionada && (<img src={fotoSeleccionada} alt="Foto Altavoces de techo o superficie no certificados" className="imgModal" />)}
                </Modal.Body>
            </Modal>

        </>
    );

}

