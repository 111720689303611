import React from 'react';
import { MenuLateral } from '../MenuLateral/MenuLateral'
import { useNavigate } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import './Detectores4_20maE.css';

export function Detectores4_20maE() {

    const navigate = useNavigate();

    const irADetectorDurtex = () => {
        navigate(`/detectorDurtex`);
    };

    return (
        <>
            <div className="mainContainer">
                <MenuLateral />

                <div className='contenedor'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='tituloDet4_20maE'>
                                <h1>Detectores 4_20mA</h1>
                            </div>

                        </div>

                    </div>

                    <div className='row'>


                        <div className='contenedorCards4_20E'>
                            <div className='row'>
                                <div className='col-xl-12'>
                                    <Card>
                                        <Card.Img variant="durtex" src={require('../Img/durtex3.webp')} onClick={irADetectorDurtex} style={{ cursor: 'pointer' }} alt='Detector Durtex' fetchpriority='high' />
                                        <Card.Body>
                                            <Card.Title className='tituloDurtex'>Detector Durtex</Card.Title>

                                        </Card.Body>

                                    </Card>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

