import React from 'react';
import { MenuLateralItems } from './MenuLateralItems';
import { MenuLateralState } from './MenuLateralState';
import './MenuLateral.css';

export function MenuLateral() {
    const { menuState, subMenuAbierto, handleToggleMenu, handleSubMenuChange } = MenuLateralState();

    return (
        <div className={`menuLateral${menuState.estaAbierto ? ' abierto' : ''}`}>
            {menuState.estaAbierto && (
                <div className="botonMenu" onClick={handleToggleMenu}>
                    <i className="fas fa-bars"></i>
                </div>
            )}
            {menuState.estaAbierto && <MenuLateralItems subMenuAbierto={subMenuAbierto} handleSubMenuChange={handleSubMenuChange} />}
        </div>
    );
}