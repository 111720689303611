import React from 'react';
import { MenuLateral } from '../MenuLateral/MenuLateral'
import { useNavigate } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import './AltCertificadosEn54.css';

export function AltCertificadosEn54() {

    const navigate = useNavigate();

    const irATecho = () => {
        navigate(`/altavocesDeTechoOSuperficie`);
    };

    const irAProyectores = () => {
        navigate(`/proyectoresDeSonido`);
    };

    const irAColumnas = () => {
        navigate(`/columnasAcusticas`);
    };

    return (
        <>
            <div className="mainContainer">
                <MenuLateral />
                <div className='contenedor'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='tituloCertificados54'>
                                <h1>Sistemas certificados en -54</h1>
                            </div>

                        </div>

                    </div>

                    <div className='row'>
                        <div className='contenedorCertificados54'>
                            <div className='row'>
                                <div className='col-xl-4 col-lg-4 col-md-12'>
                                    <Card>
                                        <Card.Img variant="techo" src={require('../Img/ch-42tn.webp')} onClick={irATecho} style={{ cursor: 'pointer' }} alt='Altavoces de techo o superficie' fetchpriority='high' />
                                        <Card.Body>
                                            <Card.Title className='tituloTecho'>Altavoces de techo o superficie</Card.Title>

                                        </Card.Body>

                                    </Card>
                                </div>
                                <div className='col-xl-4 col-lg-4 col-md-12'>
                                    <Card>
                                        <Card.Img variant="proyectores" src={require('../Img/pcm-20tn.webp')} onClick={irAProyectores} style={{ cursor: 'pointer' }} alt='Proyectores de sonido' fetchpriority='high' />
                                        <Card.Body>
                                            <Card.Title className='tituloProyectores'>Proyectores de sonido</Card.Title>

                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className='col-xl-4 col-lg-4 col-md-12'>
                                    <Card>
                                        <Card.Img variant="columnas" src={require('../Img/ci-825tn.webp')} onClick={irAColumnas} style={{ cursor: 'pointer' }} alt='Columnas acústicas' fetchpriority='high' />
                                        <Card.Body>
                                            <Card.Title className='tituloColumnas'>Columnas acústicas</Card.Title>

                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

