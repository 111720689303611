import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Row from 'react-bootstrap/Row'; import { MenuLateral } from '../MenuLateral/MenuLateral'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'; // Importa el icono faBars
import Nav from 'react-bootstrap/Nav';
import { fichaDurlux, fichaDurluxT } from '../../Archivos/luxometro';
import './Luxometro.css';

export function Luxometro() {

    const [fotoSeleccionada, setFotoSeleccionada] = useState(null);
    const [subtituloModal, setSubtituloModal] = useState("");

    const modalFoto = (foto, subtitulo) => {
        setFotoSeleccionada(foto);
        setSubtituloModal(subtitulo)
    };

    const logoPdf = <FontAwesomeIcon icon={faFilePdf} size="xl" style={{ color: "#006c87", }} />

    const abrirPdf = (archivo) => {
        window.open(archivo, '_blank');
    };

    const archivosPDF = {
        fichaDurlux: fichaDurlux,
        fichaDurluxT: fichaDurluxT
    };

    const clicEnlacePdf = (tipoArchivo) => {
        abrirPdf(archivosPDF[tipoArchivo]);
    };

    return (
        <>
            <div className="mainContainer">
                <MenuLateral />

                <div className='contenedorProducto'>
                    <div className='row'>
                        <div className='col-xl-3 col-lg-3 col-md-12 col-sm-12'>
                            <div className='imagenesLuxometro'>

                                <Row>
                                    <div className='contenedorFoto' >
                                        <img className="imagenProductoLuxometro"
                                            src={require("../Img/luxometro.webp")}
                                            
                                            onClick={() => modalFoto(require("../Img/luxometro.webp"), 'Luxómetro')}
                                            alt='Luxómetro' />
                                    </div>
                                </Row>

                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                            <div className='descripcionLuxometro'>
                                <h2 className='nombreProductoLuxometro'>Luxómetro</h2>
                                <h5 className='tituloProductoLuxometro'>El sensor analógico DURLUX 4-20mA mide la iluminancia o nivel de iluminación
                                    -lux- de la luz ambiental, y la convierte en una señal en lazo de corriente.</h5>
                                <p className='textoProductoLuxometro'>
                                    La señal de salida de 4-20mA es proporcionada por el mismo cable de alimentación, ofreciendo un cableado fácil
                                    en la instalación.
                                    <br />
                                    Está basado en un foto-sensor de Si, que ofrece una sensibilidad próxima a la luz visible, y un comportamiento
                                    estable.
                                    <br />
                                    Un alto grado de protección para exteriores y una construcción sólida en acero inoxidable.

                                </p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12'>

                            <div className='contenedorNavLuxometro'>
                                <Nav as="ul" className="flex-column">
                                    <Nav.Item className='linksArchivosLuxometro' as="li" onClick={() => clicEnlacePdf('fichaDurlux')}>
                                        {logoPdf}
                                        Ficha Durlux
                                    </Nav.Item>

                                </Nav>
                                <Nav as="ul" className="flex-column">
                                    <Nav.Item className='linksArchivosLuxometro' as="li" onClick={() => clicEnlacePdf('fichaDurluxT')}>
                                        {logoPdf}
                                        Ficha Durlux-T
                                    </Nav.Item>

                                </Nav>
                            </div>
                        </div>
                    </div>

                </div>

            </div >

            <Modal className='imgModalContainer' show={fotoSeleccionada !== null} onHide={() => setFotoSeleccionada(null)}>
                <Modal.Header closeButton>
                    {subtituloModal && <Modal.Title>{subtituloModal}</Modal.Title>}
                </Modal.Header>
                <Modal.Body>
                    {fotoSeleccionada && (<img src={fotoSeleccionada} alt="Foto Luxometro" className="imgModal" />)}
                </Modal.Body>
            </Modal>

        </>
    );

}

