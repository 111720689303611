import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Row from 'react-bootstrap/Row'; import { MenuLateral } from '../MenuLateral/MenuLateral'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'; // Importa el icono faBars
import Nav from 'react-bootstrap/Nav';
import { fichaCI625t, fichaCI825t, fichaDC12 } from '../../Archivos/columnasNoCert';
import './ColumnasNoCert.css';

export function ColumnasNoCert() {

    const [fotoSeleccionada, setFotoSeleccionada] = useState(null);
    const [subtituloModal, setSubtituloModal] = useState("");

    const modalFoto = (foto, subtitulo) => {
        setFotoSeleccionada(foto);
        setSubtituloModal(subtitulo)
    };

    const logoPdf = <FontAwesomeIcon icon={faFilePdf} size="xl" style={{ color: "#006c87", }} />

    const abrirPdf = (archivo) => {
        window.open(archivo, '_blank');
    };

    const archivosPDF = {
        fichaCI625t: fichaCI625t,
        fichaCI825t: fichaCI825t,
        fichaDC12: fichaDC12

    };

    const clicEnlacePdf = (tipoArchivo) => {
        abrirPdf(archivosPDF[tipoArchivo]);
    };

    return (
        <>
            <div className="mainContainer">
                <MenuLateral />

                <div className='contenedorProducto'>
                    <div className='row'>
                        <div className='col-xl-3 col-lg-3 col-md-12 col-sm-12'>
                            <div className='imagenesColumnasNoCert'>

                                <Row>
                                    <div className='contenedorFoto' >
                                        <img className="imagenProductoColumnasNoCert"
                                            src={require("../Img/ci-625t.webp")}
                                            
                                            onClick={() => modalFoto(require("../Img/ci-625t.webp"), 'CI-625T')}
                                            alt='CI-625T' />
                                    </div>
                                </Row>
                                <Row>
                                    <div className='contenedorFoto' >
                                        <img className="imagenProductoColumnasNoCert"
                                            src={require("../Img/ci-825t.webp")}
                                            
                                            onClick={() => modalFoto(require("../Img/ci-825t.webp"), 'CI-825T')}
                                            alt='CI-825T' />
                                    </div>
                                </Row>
                                <Row>
                                    <div className='contenedorFoto' >
                                        <img className="imagenProductoColumnasNoCert"
                                            src={require("../Img/dc-12-2.webp")}
                                            
                                            onClick={() => modalFoto(require("../Img/dc-12-2.webp"), 'DC 12/2')}
                                            alt='DC 12/2' />
                                    </div>
                                </Row>

                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                            <div className='descripcionColumnasNoCert'>
                                <h2 className='nombreProductoColumnasNoCert'>Columnas</h2>
                                <h5 className='tituloProductoColumnasNoCert'>CI-625T y CI825T
                                </h5>
                                <p className='textoProductoColumnasNoCert'>
                                    Altavoces de columna de altas prestaciones para instalaciones profesionales. Su diseño estilizado se adapta a todos
                                    los ambientes y entornos gracias a su protección IP54. Diseñadas para instalación sobre paredes o columnas, disponen
                                    de un soporte que les permite posicionarlos tanto vertical como horizontalmente y una rotación de 180º.
                                    <br /><br />
                                    La CI-625T incorpora 6 drivers de 2,5”.
                                    <br />
                                    La CI-825T incorpora 8 drivers de 2,5”.
                                </p>

                                <h5 className='tituloProductoColumnasNoCert'> DC 12/2
                                </h5>
                                <p className='textoProductoColumnasNoCert'>
                                    DC 12/2 Columna Directiva Activa.
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-12'>

                            <div className='contenedorNavColumnasNoCert'>
                                <Nav as="ul" className="flex-column">
                                    <Nav.Item className='linksArchivosColumnasNoCert' as="li" onClick={() => clicEnlacePdf('fichaVap1')}>
                                        {logoPdf}
                                        Ficha VAP-1
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosColumnasNoCert' as="li" onClick={() => clicEnlacePdf('fichaVap8k')}>
                                        {logoPdf}
                                        Ficha VAP-8K
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosColumnasNoCert' as="li" onClick={() => clicEnlacePdf('fichaRack4')}>
                                        {logoPdf}
                                        Ficha Rack 4
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosColumnasNoCert' as="li" onClick={() => clicEnlacePdf('fichaRack6')}>
                                        {logoPdf}
                                        Ficha Rack 6
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosColumnasNoCert' as="li" onClick={() => clicEnlacePdf('manual')}>
                                        {logoPdf}
                                        Manual VAP-1
                                    </Nav.Item>

                                </Nav>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <Modal className='imgModalContainer' show={fotoSeleccionada !== null} onHide={() => setFotoSeleccionada(null)}>
                <Modal.Header closeButton>
                    {subtituloModal && <Modal.Title>{subtituloModal}</Modal.Title>}
                </Modal.Header>
                <Modal.Body>
                    {fotoSeleccionada && (<img src={fotoSeleccionada} alt="Foto columnas acústicas no certificadas" className="imgModal" />)}
                </Modal.Body>
            </Modal>

        </>
    );

}

