import './Footer.css';
import '@fortawesome/fontawesome-free/css/all.min.css';


export function Footer() {

    return (
        <footer fetchpriority='low'>
            <div className='row'>
                <div className='col-lg-12'>
                    <p>Av. Las Condes 12.523, Depto 1011 - T1
                        Las Condes, Santiago - Chile. <br />
                        Teléfono: +569 5866 5386 <br />
                        Correo electrónico: dtgascl@gmail.com / info@dtgas.cl</p>
                </div>
                

            </div>
            <div className='row'>
                <h3>© DTGAS 2023. Zolutions. All rights reserved.</h3>
            </div>
        </footer>
    );

}