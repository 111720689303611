import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Row from 'react-bootstrap/Row'; import { MenuLateral } from '../MenuLateral/MenuLateral'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'; // Importa el icono faBars
import Nav from 'react-bootstrap/Nav';
import { fichaDirex, fichaDirexX, fichaCajaDerivacionAtex, manualDirex, presentacionDirex } from '../../Archivos/direx';
import './DetectorDirex.css';

export function DetectorDirex() {

    const [fotoSeleccionada, setFotoSeleccionada] = useState(null);
    const [subtituloModal, setSubtituloModal] = useState("");

    const modalFoto = (foto, subtitulo) => {
        setFotoSeleccionada(foto);
        setSubtituloModal(subtitulo)
    };

    const logoPdf = <FontAwesomeIcon icon={faFilePdf} size="xl" style={{ color: "#006c87", }} />

    const abrirPdf = (archivo) => {
        window.open(archivo, '_blank');
    };

    const archivosPDF = {
        ficha: fichaDirex,
        fichaX: fichaDirexX,
        fichaCaja: fichaCajaDerivacionAtex,
        manual: manualDirex,
        presentacion: presentacionDirex
    };

    const clicEnlacePdf = (tipoArchivo) => {
        abrirPdf(archivosPDF[tipoArchivo]);
    };

    return (
        <>
            <div className="mainContainer">
                <MenuLateral />

                <div className='contenedorProducto'>
                    <div className='row'>
                        <div className='col-xl-3 col-lg-3 col-md-12 col-sm-12'>
                            <div className='imagenesDirex'>

                                <Row>
                                    <div className='contenedorFoto' >
                                        <img className="imagenProductoDirex"
                                            src={require("../Img/direx1.webp")}
                                            
                                            onClick={() => modalFoto(require("../Img/direx1.webp"), 'Direx')}
                                            alt='Detector Direx' />
                                    </div>
                                </Row>
                                <Row>
                                    <div className='contenedorFoto' >
                                        <img className="imagenProductoDirex"
                                            src={require("../Img/direx2.webp")}
                                            
                                            onClick={() => modalFoto(require("../Img/direx2.webp"), 'Direx X')}
                                            alt='Detector Direx' />
                                    </div>
                                </Row>

                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                            <div className='descripcionDirex'>
                                <h2 className='nombreProductoDirex'>Detector Direx 4-20mA</h2>
                                <h5 className='tituloProductoDirex'>Detectores 4-20mA por tecnología infrarroja para la detección de gases explosivos y
                                    CO2 compatibles con cualquier sistema con entradas estándar 4-20mA y con las centrales DURGAS y EUROSONDELCO mediante
                                    Interface.
                                </h5>
                                <p className='textoProductoDirex'>
                                    DIREX ha sido especialmente diseñado para:
                                </p>
                                <ul className='listadoDirex'>
                                    <li>Instalaciones con presencia de gases inhibidores o venenosos de detectores catalíticos –pellistores-.
                                        Estos gases no afectan a DIREX.</li>
                                    <li>En atmósferas donde no haya presencia de oxígeno. DIREX, al contrario que los pellistores, no necesita oxigeno para
                                        su funcionamiento.</li>
                                    <li>Instalaciones en las que las operaciones de mantenimiento se deban reducir al mínimo. DIREX solo requiere una
                                        revisión cada 3 años.</li>
                                </ul>
                                <p className='textoProductoDirex'>
                                    En la detección  por infrarrojos, al contrario que otras tecnologías, la presencia del gas a detectar no reduce la vida útil
                                    del sensor. DIREX no se ve afectado por este fenómeno.
                                    <br />
                                    Gases disponibles: Metano, Gas Natural, Propano y CO2.

                                    Disponibles en versión plástica IP65 (DIREX)  y ATEX antideflagrante (DIREX-X) con certificado ATEX LOM08ATEX2059 X.
                                    <br />
                                    Rango de detección de 0 a 100% L.I.E. (explosivos) y de 0 a 2% vol (CO2).
                                    <br />
                                    Indicaciones ópticas de avería de lazo y de sensor.
                                    <br />
                                    Módulo de relé de alarma programable opcional: 1 nivel de alarma programable, desconexión instantánea/retardada, programación
                                    retardo de desconexión y estado inicial del relé en reposo.
                                    <br />
                                    Calibrados en fábrica con gas patrón: no necesitan ser recalibrados en la instalación.
                                    <br />
                                    También disponible en formato RS485C.
                                </p>
                            </div>

                        </div>
                        <div className='col-lg-3 col-md-12'>

                            <div className='contenedorNavDirex'>
                                <Nav as="ul" className="flex-column">
                                    <Nav.Item className='linksArchivosDirex' as="li" onClick={() => clicEnlacePdf('ficha')}>
                                        {logoPdf}
                                        Ficha Detector Direx
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosDirex' as="li" onClick={() => clicEnlacePdf('fichaX')}>
                                        {logoPdf}
                                        Ficha Detector Direx X
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosDirex' as="li" onClick={() => clicEnlacePdf('fichaCaja')}>
                                        {logoPdf}
                                        Ficha Caja de Derivación Atex
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosDirex' as="li" onClick={() => clicEnlacePdf('manual')}>
                                        {logoPdf}
                                        Manual
                                    </Nav.Item>
                                    <Nav.Item className='linksArchivosDirex' as="li" onClick={() => clicEnlacePdf('presentacion')}>
                                        {logoPdf}
                                        Presentación
                                    </Nav.Item>

                                </Nav>
                            </div>
                        </div>
                    </div>

                </div>

            </div >

            <Modal className='imgModalContainer' show={fotoSeleccionada !== null} onHide={() => setFotoSeleccionada(null)}>
                <Modal.Header closeButton>
                    {subtituloModal && <Modal.Title>{subtituloModal}</Modal.Title>}
                </Modal.Header>
                <Modal.Body>
                    {fotoSeleccionada && (<img src={fotoSeleccionada} alt="Foto Direx" className="imgModal" />)}
                </Modal.Body>
            </Modal>

        </>
    );

}

